
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { OrdersService } from '@/services/orders-service';
import { CustomerOrder } from '@/store/models/OrderlineModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import { Confirm } from '@/helpers/AuthHelper';
@Component({
  components: {}
})
export default class extends Vue {
  $refs!: {};
  /* Properties */
  @Prop() customerOrders!: CustomerOrder[];
  /* Store Actions */
  @Action('ClearCurrentOrder', { namespace: 'orderlines' })
  clearCurrentOrder!: any;
  @Action('RemoveOrdersByPO', { namespace: 'orderlines' })
  removeOrdersByPO: any;
  @Action('GetSavedOrders', { namespace: 'orderlines' }) getSavedOrders: any;
  /* Watchers */
  /* Data */
  dialog: boolean = false;
  selectedOrder: CustomerOrder = new CustomerOrder();
  /* Computed */
  get customerOrdersFiltered() {
    return this.customerOrders.filter(x => !x.IsCartOrder);
  }
  /* Utility Functions */
  openDialog() {
    this.dialog = true;
  }

  async goToOrderView(po: string) {
    if (this.$route.name === 'OrderView') {
      if (
        !window.confirm(
          'Are you sure you want to leave? Your Order has not yet been saved and you will lose your progress'
        )
      )
        return;
    }
    this.closeDialog();
    await this.clearCurrentOrder();
    const order = this.customerOrders.find(x => x.PO === po);
    this.$router.push({
      name: 'OrderView',
      params: {
        po: po
      },
      query: {
        isCartOrder: (order?.IsCartOrder).toString()
      }
    });
  }
  closeDialog() {
    this.dialog = false;
  }
  onConfimationResponse(response: boolean) {
    if (response) {
      this.deleteOrder(this.selectedOrder);
    } else {
      this.selectedOrder = new CustomerOrder();
    }
  }
  openCancelOrderConfirmationDialog(order: CustomerOrder) {
    this.selectedOrder = order;
    Confirm(
      () => {
        this.onConfimationResponse(true);
      },
      'Delete Order',
      'Are you sure you want to delete this order?'
    );
  }
  async deleteOrder(order: CustomerOrder) {
    // this.isCancellingPO = true;
    try {
      await OrdersService.CancelOrder(order.PO, order?.IsCartOrder);
      this.clearCurrentOrder();
      await this.removeOrdersByPO(order.PO);
      this.$emit('orderDeleted', order.PO);
      SetSnackBar('Order is Cancelled');
      this.selectedOrder = new CustomerOrder();
      this.getSavedOrders();
    } catch (err) {
      this.selectedOrder = new CustomerOrder();
      console.log(err);
    }
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
