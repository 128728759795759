
import { Vue, Component } from 'vue-property-decorator';
import { Mutation } from 'vuex-class';
import DefaultLayout from '@/components/DefaultLayout.vue';
import { Address } from '@/store/models/AddressModel';
import { AddressService } from '@/services/address-service';
import RollDirectionAlert from '@/components/RollDirectonAlert.vue';
import AddEditShippingAddress from '@/components/AddEditShippingAddress.vue';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { CopyToClipboard } from '@/helpers/WebHelper';
import { Confirm } from '@/helpers/AuthHelper';
@Component({
  components: {
    DefaultLayout,
    RollDirectionAlert,
    AddEditShippingAddress
  }
})
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
    addEditShippingAddress: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  @Mutation('setCurrentOrder', { namespace: 'orderlines' })
  setCurrentOrderInStore: any;
  /* Watchers */
  /* Data */
  shippingAddresses: Address[] = [];
  selectedAddress: Address = new Address();
  shouldOpenEditDialog: boolean = false;
  isGettingAddresses: boolean = false;
  isSavingAddress: boolean = false;
  addressOptions: any = [
    {
      Id: 0,
      text: 'Edit',
      isDisabled: false
    },
    {
      Id: 1,
      text: 'Set as Default',
      isDisabled: false
    },
    {
      Id: 2,
      text: 'Delete',
      isDisabled: false
    }
  ];
  /* Methods */
  async GetShippingAddresses() {
    try {
      console.log('Loading all addresses');
      this.isGettingAddresses = true;
      const response = await AddressService.GetShippingAddressesFull(true);
      this.shippingAddresses = response.data.map((a: any) => new Address(a));
    } catch (err) {
      SetSnackBar(
        'We had trouble loading your addresses. Please contact Technical Support at techsupport@just1label.com'
      );
    }
    this.isGettingAddresses = false;
  }
  async UpdateAddressDefault() {
    try {
      await AddressService.UpdateAddressDefault(this.selectedAddress.Id, false);
      this.GetShippingAddresses();
      SetSnackBar('Updated Address default successfully');
    } catch (err) {
      SetSnackBar('Something went wrong when updating the default address');
    }
  }
  handleUpdateDefaultResponse() {
    this.UpdateAddressDefault();
  }
  handleDeleteResponse() {
    this.removeAddress();
  }
  handleOptionSelection(optionId: number, addressId: string) {
    this.selectedAddress = this.shippingAddresses.find(a => a.Id == addressId)!;
    switch (optionId) {
      case 0: {
        this.openAddressDialog(!this.selectedAddress);
        return;
      }
      case 1: {
        Confirm(
          () => {
            this.handleUpdateDefaultResponse();
          },
          'Update address default',
          `Are you sure you want to change ${this.selectedAddress.CompanyName} to be your default?`
        );
        return;
      }
      case 2: {
        Confirm(
          () => {
            this.handleDeleteResponse();
          },
          `Delete ${this.selectedAddress.CompanyName}`,
          `Are you sure you want to delete ${this.selectedAddress.CompanyName}?`
        );
        return;
      }
    }
  }
  async updateAddress() {
    this.GetShippingAddresses();
  }
  async removeAddress() {
    await AddressService.RemoveAddress(this.selectedAddress.Id);
    this.GetShippingAddresses();
  }
  async openAddressDialog(shouldClearData: boolean = false) {
    if (shouldClearData) this.selectedAddress = new Address();
    this.$refs.addEditShippingAddress.openAddressDialog(shouldClearData);
  }
  disableChromeAutofill() {}
  copyAddressToClipboard(address: Address) {
    CopyToClipboard(`${address.CompanyName}\r${address.Attn}\r${address.FullAddress}`);
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    this.GetShippingAddresses();
  }
  /* Created */
}
