import { Item, Material, Finish } from '@/store/models/ItemModel';
import {
  Orderline,
  OrderlineGroup,
  SearchRequestModel
} from '@/store/models/OrderlineModel';
import { PaginationModel } from '@/store/models/PaginationModel';
import { KeyFrameDynamicValues } from '@/store/models/KeyFrameModel';

export function BuildPaginationModel(
  page: number,
  SearchModel: SearchRequestModel,
  ShouldLoad: boolean = true,
  OrderBy: number = 5,
  Take: number = 20,
  IsDescending: boolean = true,
  ShouldAutoReconcile: boolean = true,
  SearchValue: string = ''
) {
  const model: PaginationModel = {
    Take,
    Start: page * Take - Take,
    OrderBy,
    CurrentPage: page,
    IsDescending,
    SearchValue,
    ShouldLoad,
    ShouldAutoReconcile,
    SearchModel
  };
  return model;
}

export function BuildOrderlineArrayForDisplay(orderlines: Orderline[]) {
  let orderlineGroups: OrderlineGroup[] = [];
  let currentOrderlineGroup: OrderlineGroup = {
    Orderlines: [],
    GroupByValue: '',
    Page: 0
  };
  orderlines.forEach(ol => {
    if (currentOrderlineGroup.GroupByValue !== ol.PO) {
      if (currentOrderlineGroup.Page !== 0) {
        orderlineGroups.push(currentOrderlineGroup);
      }
      currentOrderlineGroup = {
        Orderlines: [ol],
        GroupByValue: ol.PO,
        Page: ol.Page
      };
    } else {
      currentOrderlineGroup.Orderlines.push(ol);
    }
  });
  if (
    !orderlineGroups.some(g => currentOrderlineGroup.GroupByValue === g.GroupByValue) &&
    currentOrderlineGroup.Orderlines.length > 0
  ) {
    orderlineGroups.push(currentOrderlineGroup);
  }
  return orderlineGroups.sort(g => g.Page);
}

export function BuildDieLineKeyFrames() {
  return '\
  @-webkit-keyframes moveRight {\
      100% {\
         transform: translateX(RIGHT_DYNAMIC_VALUE);\
      }\
  }\
  @-webkit-keyframes moveCenter {\
    100% {\
       transform: translateX(0);\
    }\
  }\
  @-webkit-keyframes moveUp {\
    100% {\
      transform: translateY(UP_DYNAMIC_VALUE);\
    }\
  }\
  @-webkit-keyframes moveLeft {\
    100% {\
      transform: translateX(LEFT_DYNAMIC_VALUE);\
    }\
  }\
  @-webkit-keyframes moveDown {\
    100% {\
      transform: translateY(DOWN_DYNAMIC_VALUE);\
    }\
  }\
  @-webkit-keyframes topLeft {\
    100% {\
      transform: translate(LEFT_DYNAMIC_VALUE, UP_DYNAMIC_VALUE);\
    }\
  }\
  @-webkit-keyframes bottomRight {\
    100% {\
      transform: translate(RIGHT_DYNAMIC_VALUE, DOWN_DYNAMIC_VALUE);\
    }\
  }\
  @-webkit-keyframes bottomLeft {\
    100% {\
      transform: translate(LEFT_DYNAMIC_VALUE, DOWN_DYNAMIC_VALUE);\
    }\
  }\
  @-webkit-keyframes topRight {\
    100% {\
       transform: translate(RIGHT_DYNAMIC_VALUE, UP_DYNAMIC_VALUE);\
    }\
  }\
  @-webkit-keyframes fullAnimate {\
    0% {\
      transform: translate(RIGHT_DYNAMIC_VALUE, UP_DYNAMIC_VALUE);\
    }\
    25% {\
      transform: translate(LEFT_DYNAMIC_VALUE, UP_DYNAMIC_VALUE);\
    }\
    50% {\
      transform: translate(LEFT_DYNAMIC_VALUE, DOWN_DYNAMIC_VALUE);\
    }\
    75% {\
      transform: translate(RIGHT_DYNAMIC_VALUE, DOWN_DYNAMIC_VALUE);\
    }\
    100% {\
      transform: translate(RIGHT_DYNAMIC_VALUE, UP_DYNAMIC_VALUE);\
    }\
  }';
}

export function BuildDieLineStyleSheet(
  params: KeyFrameDynamicValues,
  keyFrameString: string
) {
  const mapObj: any = {
    RIGHT_DYNAMIC_VALUE: `${params.DistanceX}px`,
    LEFT_DYNAMIC_VALUE: `${-params.DistanceX}px`,
    UP_DYNAMIC_VALUE: `${-params.DistanceY}px`,
    DOWN_DYNAMIC_VALUE: `${params.DistanceY}px`
  };
  return keyFrameString.replace(
    /RIGHT_DYNAMIC_VALUE|LEFT_DYNAMIC_VALUE|UP_DYNAMIC_VALUE|DOWN_DYNAMIC_VALUE/gi,
    function(matched) {
      return mapObj[matched];
    }
  );
}

export function BuildSVGMask(
  imgId: string = 'imgId',
  trimline: any = null,
  isSpecial = false,
  shouldShowMask: boolean = false,
  count: number = 0
) {
  if (!trimline) trimline = document.getElementById('trimLineId');
  else {
    let img = document.getElementById(imgId)!;
    if (img.clientWidth === 0 || img.clientHeight === 0 || !trimline) {
      if (count === 100) return;
      setTimeout(() => {
        BuildSVGMask(imgId, trimline, isSpecial, shouldShowMask, count + 1);
      }, 200);
    } else {
      var outerRect = document.getElementById('outerRectId');
      if (outerRect) return;
      let svg = trimline.closest('svg');
      trimline.removeAttribute('fill-opacity');
      svg!.setAttribute('height', `${img.clientHeight}px`);
      svg!.setAttribute('width', `${img.clientWidth}px`);
      svg!.style.maxHeight = '60vh';
      svg!.style.position = 'absolute';
      svg!.style.width = '100%';
      img.style.maxHeight = '60vh';
      if (shouldShowMask) {
        let mask = document.createElementNS('http://www.w3.org/2000/svg', 'mask');
        let maskBox = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        mask.setAttribute('id', 'maskId');
        maskBox.setAttribute('fill', 'white');
        maskBox.setAttribute('width', '100%');
        maskBox.setAttribute('height', '100%');
        maskBox.setAttribute('fill-opacity', '1');
        let outerRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        outerRect.setAttribute('id', 'outerRectId');
        outerRect.setAttribute('mask', 'url(#maskId)');
        outerRect.setAttribute('fill', 'black');
        outerRect.setAttribute('fill-opacity', '.5');
        outerRect.setAttribute('width', '100%');
        outerRect.setAttribute('height', '100%');
        let g = trimline.parentNode;
        g!.replaceChild(mask, trimline);
        mask.appendChild(trimline);
        trimline.parentNode!.insertBefore(maskBox, trimline);
        mask.parentNode!.appendChild(outerRect);

        let path = mask.nextElementSibling;
        if (path && path.localName == 'path') {
          path.setAttribute('style', 'opacity: 0;');
        }
      } else {
        let elements = document.getElementsByClassName('svg-trimline');
        for (let e of elements) {
          e.setAttribute('fill-opacity', '0');
          e.setAttribute('stroke', 'red');
        }
      }
    }
  }
}

export function ImgMaskBuilder(
  type: string,
  trimline: SVGAElement,
  safetyline: SVGAElement,
  isSpecial: boolean
) {
  var existingSafetyline = document.getElementById('safetyLineId');
  var outerRect = document.getElementById('outerRectId');

  switch (type) {
    case 'trimmedlabel':
      trimline.setAttribute('opacity', '1');
      if (existingSafetyline) {
        safetyline.setAttribute('opacity', '0');
      }
      outerRect
        ? outerRect.setAttribute('fill-opacity', '.5')
        : BuildSVGMask('imgId', trimline, isSpecial, false);

      break;
    case 'fullbleed':
      if (existingSafetyline) {
        existingSafetyline.setAttribute('opacity', '0');
      }
      trimline.setAttribute('opacity', '0');
      outerRect
        ? outerRect.setAttribute('fill-opacity', '0')
        : BuildSVGMask('imgId', trimline, isSpecial, false);

      break;
    case 'safezone':
      if (existingSafetyline) {
        existingSafetyline.removeAttribute('style');
        existingSafetyline.setAttribute('opacity', '1');
        existingSafetyline.setAttribute('stroke-opacity', '1');
        existingSafetyline.setAttribute('fill-opacity', '0');
        existingSafetyline.setAttribute('stroke', 'blue');
      }
      var svgList = Array.from(
        document.querySelectorAll<SVGSVGElement>('svg:not(#j1l-logo-svg):not(.lottie)')
      );
      svgList[0].appendChild(safetyline);
      break;

    default:
      break;
  }
}
