
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '@/components/DefaultLayout.vue';
import J1LMenu from '@/components/vuetifyOverrides/J1LMenu.vue';
import ImportProcessResultDialog from '@/components/ImportProcessResultDialog.vue';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { DataImporationService } from '@/services/importation-service';
import { CustomerService } from '@/services/customer-service';
import { Brand } from '@/store/models/ItemModel';
import { Address } from '@/store/models/AddressModel';
import { ImportReport } from '@/store/models/ImportReportModel';
import { Confirm } from '@/helpers/AuthHelper';
@Component({
  components: {
    DefaultLayout,
    J1LMenu,
    ImportProcessResultDialog
  }
})
export default class extends Vue {
  $refs!: {
    DefaultActions: HTMLFormElement;
    FileUpload: HTMLFormElement;
    ImportProcessResultDialog: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  @Watch('selectedCustomer')
  onSelectedCustomer(val: string) {
    this.LoadCustomerBrands(val);
    this.LoadShippingAddresses(val);
  }
  /* Watchers */
  /* Data */
  fileDescription: string = '';
  importReport: ImportReport = new ImportReport();
  itemOnlyHeader: string =
    'partnumber,description,SKU,ASIN,UPC,EAN8,EAN13,EAN14,Manufacturer,CountryOfManufacture,CityOfManufacture,StateOrProvinceOfManufacture,Fnsku';
  filename: string = '';
  hasErrorUploadingFile!: boolean;
  isUploadingItemFile!: boolean;
  isBringingUpFileDisplay: boolean = false;
  isImporting: boolean = false;
  file: any = null;
  customers: any[] = [];
  brandsList: Brand[] = [];
  customerAddresses: any[] = [];
  staticItems: any[] = [];
  importationOptions: any[] = [
    {
      label: 'Item Importation',
      value: 1
    },
    {
      label: 'Item and Order Importation',
      value: 2
    },
    {
      label: 'Order Only',
      value: 3
    }
  ];
  selectedOption: number = 1;
  selectedCustomer: string = '';
  selectedBrand: number = 0;
  selectedAddressId: number = 0;
  uploadPercentage: number = 0;
  selectedStaticItemId: number = 0;
  hasValidationError: boolean = false;
  /* Async Functions */

  handleImportSelection() {
    if (this.selectedOption == 1) {
      this.importItemConfirmationClick();
    } else if (this.selectedOption == 3) {
      this.importOrderConfirmationClick();
    }
  }

  async onValidateFile() {
    if (this.filename) {
      let model = {
        FileName: this.filename,
        StaticItemId: this.selectedStaticItemId,
        CustomerId: this.selectedCustomer,
        ShippingAddressId: 0,
        BrandId: this.selectedBrand
      };
      try {
        const response = await DataImporationService.ValidateStaticDataFile(model);
        this.importReport = new ImportReport(response.data);
        this.hasValidationError = this.importReport.HasAnError;
        this.$refs.ImportProcessResultDialog.openDialog();
      } catch (error) {
        SetSnackBar('Something went horribly wrong with your validation.');
      }
    }
  }
  async uploadFile(val: any) {
    let that = this;
    this.hasErrorUploadingFile = false;
    if (val && val.name) {
      this.file = val;
      if (!this.fileDescription) this.fileDescription = this.file.name;
      this.isUploadingItemFile = true;
      let formData = new FormData();
      formData.append('files[]', val, val.name);
      this.isUploadingItemFile = true;
      try {
        const response = await DataImporationService.UploadCodeFile(formData, {
          onUploadProgress: function(progressEvent: any) {
            that.uploadPercentage = Math.round(
              (parseInt(progressEvent.loaded) * 100) / parseInt(progressEvent.total)
            );
          }.bind(this)
        });
        if (response.data.result === 'error') {
          SetSnackBar('Something went wrong updating your file');
          this.hasErrorUploadingFile = true;
          this.isUploadingItemFile = false;
        }
        this.filename = response.data.fileName;
        this.isUploadingItemFile = false;
      } catch {
        this.hasErrorUploadingFile = true;
        this.isUploadingItemFile = false;
        SetSnackBar('Something went wrong updating your file');
        return;
      }
      this.isUploadingItemFile = false;
    }
  }
  async importOrder() {
    if (this.file && this.selectedCustomer && this.selectedAddressId) {
      this.isImporting = true;
      let model = {
        FileName: this.filename,
        StaticItemId: 0,
        CustomerId: this.selectedCustomer,
        ShippingAddressId: this.selectedAddressId,
        BrandId: 0
      };
      try {
        console.log(model);
        const returnValue = await DataImporationService.ImportStaticItemOrder(model);
        this.importReport = new ImportReport(returnValue.data);
        this.$refs.ImportProcessResultDialog.openDialog();
        if (!this.importReport.HasAnError) {
          this.file = null;
          this.filename = '';
        }
      } catch (error) {
        SetSnackBar(
          'Something went horribly wrong with your order.  You should just run.'
        );
      }
      this.isImporting = false;
    }
  }
  async importItems() {
    if (
      this.file &&
      this.selectedCustomer &&
      this.selectedBrand &&
      this.selectedStaticItemId
    ) {
      this.isImporting = true;
      let model = {
        FileName: this.filename,
        StaticItemId: this.selectedStaticItemId,
        CustomerId: this.selectedCustomer,
        ShippingAddressId: 0,
        BrandId: this.selectedBrand
      };
      try {
        console.log(model);
        const returnValue = await DataImporationService.ImportStaticItemData(model);
        this.importReport = new ImportReport(returnValue.data);
        this.$refs.ImportProcessResultDialog.openDialog();
        if (!this.importReport.HasAnError) {
          this.file = null;
          this.filename = '';
        }
      } catch (error) {
        SetSnackBar(
          'Something went horribly wrong.  You should see a dr. as soon as possible.'
        );
      }
      this.isImporting = false;
    }
  }

  /* Utility Functions */
  onFileUploadClick() {
    this.fileDescription = '';
    this.filename = '';
    this.$refs.FileUpload.$refs.input.click();
  }
  importItemConfirmationClick() {
    Confirm(
      () => {
        this.importItems();
      },
      'Import File',
      'Are you sure you want to import this file?'
    );
  }
  importOrderConfirmationClick() {
    Confirm(
      () => {
        this.importOrder();
      },
      'Import Order File',
      'Are you sure you want to import this file?'
    );
  }
  /* Loaders */
  async LoadCustomerBrands(customerId: string) {
    this.brandsList = [];
    const { data: brands } = await CustomerService.GetCustomerBrands(customerId);
    for (var item of brands) {
      var brand = new Brand(item);
      this.brandsList.push(brand);
    }
    if (this.brandsList.length > 0) {
      this.selectedBrand = this.brandsList[0].Id;
    }
  }
  async LoadShippingAddresses(customerId: string) {
    this.customerAddresses = [];
    const shippingAddressReturn = await CustomerService.GetCustomerShippingAddressesList(
      customerId
    );
    this.customerAddresses = shippingAddressReturn.data;
  }

  /* Mounted */
  async mounted() {
    // load data
    const customersResponse = await CustomerService.GetTransparencyCustomerList();
    this.customers = customersResponse.data;
    if (this.customers.length > 0) {
      this.selectedCustomer = this.customers[0].Id;
      //this.LoadCustomerBrands(this.customers[0].Id);
      //this.LoadShippingAddresses(this.customers[0].Id);
    }
    const staticItemsReturn = await DataImporationService.GetStaticItems();
    this.staticItems = staticItemsReturn.data;
  }
  /* Created */
  /* Emmited Functions */
}
