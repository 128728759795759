
import { Confirm } from '@/helpers/AuthHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { CustomerService } from '@/services/customer-service';
import { PaymentProfile } from '@/store/models/PaymentModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import PaymentSelectCard from '@/components/cardComponents/PaymentSelectCard.vue';
import { PaymentService } from '@/services/payment-service';
@Component({
  components: {
    PaymentSelectCard
  }
})
export default class PaymentSelect extends Vue {
  /* Properties */
  @Prop() paymentProfiles!: PaymentProfile[];
  @Prop() currentSelectedProfile!: PaymentProfile;
  /* Store Actions */
  /* Watchers */
  @Watch('paymentProfiles')
  onPaymentProfilesChange() {
    if (this.selectedProfile.Id) {
      this.selectedProfile = this.paymentProfiles.find(p => p.IsDefault);
    }
  }
  /* Data */
  selectedProfile: PaymentProfile = new PaymentProfile();
  paymentOptions: string[] = ['Set as Default', 'Delete'];
  /* Computed */
  /* Async Functions */
  async deletePaymentProfile(profile: PaymentProfile) {
    profile.IsLoading = true;
    try {
      const { data } = await PaymentService.CanDeletePaymentProfile(
        profile.PaymentProfileId
      );
      if (data && data.Value) {
        await CustomerService.DeletePaymentProfile(profile.PaymentProfileId);
        SetSnackBar('Deleted Payment Method successfully');
        this.$emit('refreshProfiles');
      }
      if (data && !data.Value) {
        SetSnackBar(data.Message);
      }
    } catch (err) {
      let e = err as any;
      SetSnackBar(e.response.data.Message);
    }
    profile.IsLoading = false;
  }
  async updatePaymentMethodDefault(profile: PaymentProfile) {
    profile.IsLoading = true;
    try {
      await CustomerService.UpdatePaymentMethodDefault(profile);
      this.$emit('refreshProfiles');
      SetSnackBar('Updated Payment Method successfully');
    } catch (err) {
      this.$emit(
        'snackbar',
        'There was an error updating your payment method. Please contact technical support'
      );
    }
    profile.IsLoading = false;
  }
  /* Utility Functions */
  onPaymentProfileChange(profile: PaymentProfile) {
    this.$emit('paymentSelected', profile);
  }
  handleOptionSelection(option: string, profile: PaymentProfile) {
    switch (option) {
      case 'Set as Default':
        if (this.selectedProfile !== profile) this.updatePaymentMethodDefault(profile);
        return;
      case 'Delete':
        Confirm(
          async () => {
            await this.deletePaymentProfile(profile);
          },
          'Delete Payment Method',
          'This will delete your payment method. Do you want to proceed?',
          "don't delete",
          'delete'
        );
        return;
    }
  }
  isTerms(CardType: number) {
    return CardType === 7;
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
