import axios, { AxiosError, AxiosResponse } from 'axios';
import { CompanyEmails, CompanyModel, ContactModel } from '@/store/models/CompanyModel';
import { FeedbackMsgModel, HelpDeskRequestModel } from '@/store/models/CustomerModel';
import { AnonCustomerContactModel } from '@/store/models/RequestModels';
import { Brand } from '@/store/models/ItemModel';
const customerUrl = `${process.env.VUE_APP_ROOT_API}/Customer`;
const brandUrl = `${process.env.VUE_APP_ROOT_API}/Brand`;

export const CustomerService = {
  AddNewBrand(BrandName: string | ContactModel | Brand): Promise<AxiosResponse> {
    let model;
    if ((BrandName as ContactModel).Brand !== undefined) {
      model = BrandName;
    } else if ((BrandName as Brand).Name !== undefined) {
      model = BrandName;
    } else {
      model = {
        BrandName
      };
    }
    return axios.post(`${brandUrl}/AddNewBrand`, model);
  },
  GetAllBrandsForCustomer(): Promise<AxiosResponse> {
    return axios.get(`${brandUrl}/GetAllBrandsForCustomer`);
  },
  GetAllSalesPeople(): Promise<AxiosResponse> {
    return axios.get(`${customerUrl}/GetAllSalesPeople`);
  },
  GetCustomerBrands(customerId: string): Promise<AxiosResponse> {
    return axios.get(`${brandUrl}/GetBrandsForCustomer?customerId=${customerId}`);
  },
  GetCustomerPaymentProfiles(): Promise<AxiosResponse> {
    return axios.get(`${customerUrl}/GetCustomerPaymentProfiles`);
  },
  GetDefaultCustomerPaymentProfileId(): Promise<AxiosResponse> {
    return axios.get(`${customerUrl}/GetDefaultPaymentProfileId`);
  },
  CopyFilesToVirtualDirectory(): Promise<AxiosResponse> {
    return axios.get(`${customerUrl}/CopyFilesToVirtualDirectory`);
  },
  DeleteBrand(brandId: number): Promise<AxiosResponse> {
    return axios.get(`${brandUrl}/DeleteBrand?brandId=${brandId}`);
  },
  DeletePaymentProfile(paymentProfileId: string): Promise<AxiosResponse> {
    return axios.get(`${customerUrl}/DeletePaymentProfile/${paymentProfileId}`);
  },
  UpdateBrand(model: Brand) {
    return axios.post(`${brandUrl}/UpdateBrand`, model);
  },
  UpdateCompanyProfileDetail(model: CompanyModel) {
    return axios.post(`${customerUrl}/UpdateCompanyProfileDetail`, model);
  },
  UpdatePaymentMethodDefault(profile: any): Promise<AxiosResponse> {
    return axios.post(`${customerUrl}/UpdatePaymentMethodDefault`, profile);
  },
  AddUser(model: any) {
    return axios.post(`${customerUrl}/AddUser`, model);
  },
  GetCustomerUsers() {
    return axios.get(`${customerUrl}/GetUsers`);
  },
  GetTransparencyCustomerList: function() {
    return axios.get(`${customerUrl}/GetTransparencyCustomerList`);
  },
  GetCustomerShippingAddressesList(customerId: string) {
    return axios.get(
      `${customerUrl}/GetCustomerShippingAddressesList?customerId=${customerId}`
    );
  },
  UpdateCustomerBasicInfo(model: any) {
    return axios.post(`${customerUrl}/UpdateCustomerBasicInfo`, model);
  },
  UpdateContactName(model: ContactModel) {
    return axios.post(`${customerUrl}/UpdateContactName`, model);
  },
  UpdateContactEmail(model: ContactModel) {
    return axios.post(`${customerUrl}/UpdateContactEmail`, model);
  },
  UpdatePrimaryPhone(model: ContactModel) {
    return axios.post(`${customerUrl}/UpdatePrimaryPhone`, model);
  },
  async UploadBrandLogo(model: FormData): Promise<AxiosResponse<any> | undefined> {
    try {
      const response = await axios.post(`${brandUrl}/UploadBrandLogo`, model);
      return response;
    } catch (err) {
      let error = err as AxiosError;
      if (error.response?.status === 415) {
        throw 'Unsupported File Type';
      }
    }
  },
  UpdateCustomerOptionRollDirection(rollDirectionId: number) {
    return axios.get(
      `${customerUrl}/UpdateCustomerOptionRollDirection?rollDirectionId=${rollDirectionId}`
    );
  },
  UpdateCustomerDefaultCCForTerms(profileId: string) {
    return axios.get(
      `${customerUrl}/UpdateCustomerDefaultCCForTerms?profileId=${profileId}`
    );
  },
  UpdateCustomerOD(od: number) {
    return axios.get(`${customerUrl}/UpdateCustomerOD?od=${od}`);
  },
  UpdateNotificationEmails(model: CompanyEmails) {
    return axios.post(`${customerUrl}/UpdateNotificationEmails`, model);
  },
  GetBrand(brandId: number): Promise<AxiosResponse> {
    return axios.get(`${brandUrl}/GetBrand?brandId=${brandId}`);
  },
  GetBrandLogo(brandId: number): Promise<AxiosResponse> {
    return axios.get(`${brandUrl}/GetBrandLogo?brandId=${brandId}`);
  },
  GetEmailList() {
    return axios.get(`${customerUrl}/GetEmailList`);
  },
  GetCurrentCustomer() {
    return axios.get(`${customerUrl}/GetCurrentCustomer`);
  },
  GetCustomerSummaryMetrics() {
    return axios.get(`${customerUrl}/GetCustomerSummaryMetrics`);
  },
  SendACustomerQuestionEmailToRequestedDepartment(model: HelpDeskRequestModel) {
    return axios.post(
      `${customerUrl}/SendACustomerQuestionEmailToRequestedDepartment`,
      model
    );
  },
  SendAnonCustomerContactEmail(model: AnonCustomerContactModel) {
    return axios.post(`${customerUrl}/SendAnonCustomerContactEmail`, model);
  },
  SendBugReport(model: FeedbackMsgModel) {
    return axios.post(`${customerUrl}/SubmitBugReport`, model);
  }
};
