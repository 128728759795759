import axios, { AxiosResponse } from 'axios';

export function handleResponse(response: AxiosResponse) {
  if (response.status === 401) {
    localStorage.removeItem('user');
    document.location.reload(true);
  }
}
export function handleErrorResponse(error: any) {
  if (error.response.status === 401) {
    localStorage.removeItem('user');
    document.location.reload(true);
  }
}

export function downloadFile(
  blobData: Blob | string,
  fileName: string,
  openInNewTab: boolean = false
) {
  const url = window.URL.createObjectURL(blobData);

  if (openInNewTab) {
    // Open the PDF file directly in a new tab
    const newTab = window.open(url, '_blank');
    if (!newTab) {
      console.error(
        'Failed to open the file in a new tab. Please check your browser settings.'
      );
    }
  } else {
    // Trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // Revoke the object URL after use to free memory
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 100);
}

export async function downloadImage(image: HTMLImageElement) {
  const data = await axios.get(image.src);
  // console.log('hello');
  // const imageBlob = await (await axios.get(image.src)).data.blob();
  // const imageURL = URL.createObjectURL(imageBlob);

  // const link = document.createElement('a');
  // link.href = image.src;
  // link.download = 'image.jpg';
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);
  return;
}
