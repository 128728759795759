
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import DialogLayout from './DialogLayout.vue';
import { QuickDialog } from '../store/models/DialogModel';
import { Item } from '../store/models/ItemModel';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { DieTemplate } from '@/store/models/DieModel';
import J1lSvg from '@/components/Svg.vue';

@Component({
  components: { DialogLayout, J1lSvg }
})
export default class extends Vue {
  @Prop({ required: true })
  dieTemplates!: any;
  @Prop() type!: any;

  @Watch('dialog')
  onDialogChange(val: any) {
    if (!val) {
      this.dialog = false;
    }
  }

  debounce: boolean = false;

  search: string = '';
  loading: boolean = false;
  searchValue: string = '';
  dialog: boolean = false;
  headers: any = [
    { text: 'Tag', value: 'TagName' },
    { text: 'Shape', value: 'Shape' },
    { text: 'Height', value: 'Height' },
    { text: 'Width', value: 'Width' },
    { text: 'Corner Radius', value: 'CornerRadius' },
    { text: 'Image', value: 'svgtrimlineraster', sortable: false, filterable: false }
  ];
  templates: any[] = [
    {
      Height: 0,
      Width: 0,
      TagName: '',
      CornerRadius: '',
      Image: '',
      Shape: '',
      svgtrimlineraster: ''
    }
  ];
  shouldSearchByHeightAndWidth: boolean = false;
  heightFilter: string = '';
  widthFilter: string = '';

  get filteredItems() {
    let templates = this.dieTemplates;
    if (!this.shouldSearchByHeightAndWidth) return templates;
    return templates.filter(item => {
      const heightMatch = this.heightFilter
        ? item.Height.toString().includes(this.heightFilter)
        : true;
      const widthMatch = this.widthFilter
        ? item.Width.toString().includes(this.widthFilter)
        : true;
      return heightMatch && widthMatch;
    });
  }

  filterObject(item: any, queryText: string) {
    if (!item) return false;
    let tag = this.removeLeadingZerosAfterChars(item.toLowerCase());
    let query = this.removeLeadingZerosAfterChars(queryText.toLowerCase());
    return tag.toLowerCase().startsWith(query);
  }
  removeLeadingZerosAfterChars(str) {
    return str.replace(/([A-Za-z]+)0+/g, '$1');
  }

  handleClick(item: any) {
    this.$emit('response', item);
    this.dialog = false;
  }
  openDialog() {
    this.dialog = true;
  }
  mounted() {}
}
