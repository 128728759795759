import { Orderline, OrderlineDetails } from '@/store/models/OrderlineModel';
import { QuickDialog } from '@/store/models/DialogModel';
import { isOrderlineDetails } from './ObjectHelper';
import { Item, ItemDetails, ItemInfoDisplay } from '@/store/models/ItemModel';
import { StringHelper } from './StringHelper';
import { DateHelper } from './DateHelper';
import { Address } from '@/store/models/AddressModel';
import { CompanyModel } from '@/store/models/CompanyModel';
import { AddressRequestModel } from '@/store/models/RequestModels';
import { ConstValues } from '@/ConstValues';

export const ConversionHelper = {
  getRushNameByDayValue(dayValue: number) {
    switch (dayValue) {
      case 1:
        return 'Rush Level 3';
      case 2:
        return 'Rush Level 2';
      case 3:
        return 'Rush Level 1';
      case 4:
        return 'Priority';
      default:
        return 'Standard Turnaround';
    }
  },
  getRushTextByDayValue(dayValue: number) {
    switch (dayValue) {
      case 1:
      case 2:
      case 3:
        return 'Adjusts the due date and alerts the press operator that this item is a RUSH';
      case 4:
        return 'Moves this item forward in line and marks it as priority to the press operators.';
      default:
        return 'Ships in approximately 10 workdays.';
    }
  },
  getMaterialIdFromEntity(entity: any) {
    if (entity.TypeName) {
      switch (entity.TypeName) {
        case 'Item': {
          return entity.Material.Id;
        }
        case 'Orderline': {
          return entity.Item.Material.Id;
        }
      }
    }
  },
  convertAddressToAddressModel(
    address: Address,
    isNewAddress: boolean,
    isAlsoBilling: boolean = false,
    IsMyDefaultAddress: boolean = false,
    IsProofAddress: boolean = false
  ): AddressRequestModel {
    console.log('Here in model.');
    return new AddressRequestModel({
      Id: address.Id,
      Attention: address.Attn,
      CompanyName: address.CompanyName,
      Street1: address.Street1,
      Street2: address.Street2,
      City: address.City,
      State: address.State,
      Zipcode: address.ZipCode,
      Province: address.Province,
      PhoneNumber: address.Phone ? address.Phone.PhoneNumber : '',
      DefaultUnwindDirection: address.Options.RollDirectionId,
      DfdOption: address.Options.DfdOption,
      DefaultMaximumOD:
        address.Options.OD > 0 ? address.Options.OD : ConstValues.DEFAULT_MAX_OD,
      BrandLabel: address.Options.BrandLabel,
      AddressOptionId: address.Options.Id,
      PhoneId: address.Phone ? address.Phone.PhoneNumber : '',
      ShipToId: address.Options.ShipToId,
      CountryId: address.Country.Id,
      EmailAddress: address.Email ? address.Email.EmailAddress : '',
      EmailId: address.Email ? address.Email.Id : null,
      RecordState: isNewAddress ? 'New' : 'Edit',
      ShouldUseProvince:
        !!address.Province && address.Country.Id !== ConstValues.DEFAULT_COUNTRY_ID,
      IsBilling: isAlsoBilling,
      IsAResidence: address.IsAResidence,
      IsMyDefaultAddress: IsMyDefaultAddress,
      IsProofAddress: IsProofAddress,
      AddressNote: address.HasAddressInstructions ? address.AddressNote : null
    });
  },
  convertToCompanyModel(customerOptions: any): CompanyModel {
    return new CompanyModel(customerOptions);
  },
  generateNewOrderlineModel(
    itemId: string,
    quantity: number,
    shippingAddressId: string,
    PO: string = '',
    Id: string = '',
    ShouldGenerateRetention = false
  ) {
    return {
      ItemId: itemId,
      Quantity: quantity,
      OD: 0,
      Status: 'Pending',
      RollQuantity: 0,
      MaxLabelsPerRoll: 0,
      DueDate: '',
      State: 'New',
      CanChange: true,
      OrderLineNumber: 0,
      CustomerOrderLineNumber: 0,
      ShippingAddressId: shippingAddressId,
      RollDirectionId: 0,
      HasBarcodeData: false,
      Action: 'Add',
      ShouldGenerateRetention,
      Id,
      PO
    };
  },
  generateNewOrderlineModelFromOrderline(Orderline: OrderlineDetails) {
    return {
      ItemId: Orderline.Item.Id,
      Quantity: Orderline.Quantity,
      OD: Orderline.OD,
      Status: 'Pending',
      RollQuantity: Orderline.RollQuantity,
      MaxLabelsPerRoll: Orderline.MaxLabelsPerRoll,
      DueDate: Orderline.DueDate,
      State: 'New',
      CanChange: true,
      OrderLineNumber: 0,
      CustomerOrderLineNumber: 0,
      ShippingAddressId: Orderline.ShippingAddress.Id,
      RollDirectionId: Orderline.RollDirection.Id,
      HasBarcodeData: false,
      Action: 'Add',
      Id: Orderline.Id,
      PO: Orderline.PO
    };
  },
  convertOrderlineToAddModel(ol: OrderlineDetails | Orderline) {
    if (isOrderlineDetails(ol)) {
      let orderline = ol as OrderlineDetails;
      return {
        ItemId: orderline.Item.Id,
        Quantity: orderline.Quantity,
        Id: orderline.Id,
        OD: orderline.OD,
        Status: orderline.Status,
        RollQuantity: orderline.RollQuantity,
        MaxLabelsPerRoll: orderline.MaxLabelsPerRoll,
        DueDate: DateHelper.GetFormattedDate(orderline.DueDate),
        State: 'New',
        CanChange: true,
        OrderLineNumber: orderline.OrderLineNumber,
        CustomerOrderLineNumber: orderline.OrderLineNumber,
        ShippingAddressId: orderline.ShippingAddress?.Id || orderline.Address?.Id,
        RollDirectionId: orderline.RollDirection.Id,
        HasBarcodeData: false,
        Action: 'Add',
        PO: orderline.PO,
        PriceEach: orderline.PriceEach,
        SetupCosts: orderline.SetupCosts,
        VariableDataPriceEach: orderline.VariableDataPriceEach,
        IsARush: orderline.IsARush,
        IsARetention: orderline.IsARetention,
        RushFee: orderline.RushFee,
        RushOptions: orderline.RushOption,
        ShippingDeliveryMethod: orderline.ShippingDeliveryMethod,
        HandApplyFee: orderline.HandApplyFee,
        OrderlinePurchaseStateType: orderline.OrderlinePurchaseStateType,
        QuotedQuantity: orderline.QuotedQuantity,
        IsQuote: orderline.IsQuote,
        IsHandApply: orderline.IsHandApply,
        OriginalDueDate: orderline.OriginalDueDate,
        RequestedOrderlineMetricType: orderline.RequestedOrderlineMetricType,
        RequestedOD: orderline.RequestedOD,
        RequestedRollQuantity: orderline.RequestedRollQuantity,
        RequestedMaxLabelQuantity: orderline.RequestedMaxLabelQuantity,
        RequestedRollDirectionId: orderline.RequestedRollDirectionId,
        CustomerNote: orderline.CustomerNote,
        OrderId: orderline.CustomerOrderId,
        ShippingDeliveryOptionId: orderline.ShippingDeliveryMethod.Id,
        OrderlineType: orderline.OrderLineType
      };
    } else {
      let orderline = ol as Orderline;
      return {
        ItemId: orderline.Item.Id,
        Quantity: orderline.Quantity,
        Id: orderline.Id,
        Status: orderline.Status,
        RollQuantity: orderline.RollQuantity,
        MaxLabelsPerRoll: orderline.MaxLabelsPerRoll,
        DueDate: DateHelper.GetFormattedDate(orderline.DueDate),
        State: 'New',
        CanChange: true,
        ShippingAddressId: orderline.Address.Id,
        HasBarcodeData: false,
        Action: 'Add',
        PO: orderline.PO,
        IsARush: orderline.IsARush,
        RushFee: orderline.RushFee,
        RushOptions: orderline.RushOption,
        ShippingDeliveryMethod: orderline.ShippingDeliveryMethod,
        OrderlinePurchaseStateType: orderline.OrderlinePurchaseStateType,
        QuotedQuantity: orderline.QuotedQuantity,
        IsQuote: orderline.IsQuote,
        OriginalDueDate: orderline.OriginalDueDate,
        OrderId: orderline.CustomerOrderId,
        OrderlineType: orderline.OrderLineType,
        RequestedOrderlineMetricType: orderline.RequestedOrderlineMetricType,
        RequestedOD: orderline.RequestedOD,
        RequestedRollQuantity: orderline.RequestedRollQuantity,
        RequestedMaxLabelQuantity: orderline.RequestedMaxLabelQuantity,
        RequestedRollDirectionId: orderline.RequestedRollDirectionId
      };
    }
  },
  convertQuickDialog(item: Item): QuickDialog {
    return new QuickDialog({
      Item: item,
      SubheaderPrimary: item.PartNumber,
      SubheaderSecondary: item.Description
    });
  },
  convertOrderlineToDialogLayout(orderline: Orderline): QuickDialog {
    return new QuickDialog({
      Item: orderline.Item,
      SubheaderPrimary: orderline.Item.PartNumber,
      SubheaderSecondary: orderline.Item.Description
    });
  },
  BuildCustomerNotes(orderlines: OrderlineDetails[]) {
    let notes = '';
    for (let i = 0; i < orderlines.length; i++) {
      notes += orderlines[i].CustomerNote;
      // notes += `Partnumber : ${orderlines[i].Item.PartNumber} | Description : ${orderlines[i].Item.Description} | PO: ${orderlines[i].PO} | NOTE : ${orderlines[i].CustomerNotes};`;
    }
    return notes;
  },
  GetDisplayValues(
    item: ItemDetails,
    displayObj: {
      id: string;
      text: string;
      value: any;
      colors: any | null;
      iconColor: any | null;
    }[]
  ): any {
    let formattedItem: any = new ItemInfoDisplay(item);
    displayObj.map((val, i) => {
      val.value = formattedItem[val.id];
      if (val.id === 'Color') {
        val.colors = formattedItem[val.id];
        val.value = StringHelper.getColorText(formattedItem[val.id]);
      }
      if (val.id === 'Status') {
        val.iconColor = item.IsApproved ? '#1be784' : 'red';
      }
    });
  }
};
