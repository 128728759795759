
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '@/components/DefaultLayout.vue';
import J1lSvg from '@/components/Svg.vue';
import { DieService } from '../services/die-service';
import { DieTemplate } from '../store/models/DieModel';
import { ValidationHelper } from '../helpers/ValidationHelper';
import MasterLayout from '@/components/MasterLayout.vue';
import { Getter } from 'vuex-class';
@Component({
  components: { DefaultLayout, J1lSvg, MasterLayout },
  filters: {}
})
export default class extends Vue {
  //  $refs!: {
  //   snackbar: HTMLFormElement;
  // };
  /* Properties */
  /* Store Actions */
  @Getter('dieTemplates', { namespace: 'dies' })
  dies: any;
  @Getter('areDiesLoading', { namespace: 'dies' })
  areDiesLoading: any;
  /* Watchers */
  @Watch('search')
  onSearchChange() {
    this.waitForSearch();
  }
  @Watch('debounce')
  onDebounce(val: boolean) {
    if (!val) {
      this.waitForSearch();
    }
  }
  @Watch('dies')
  onDiesLoad(val: any) {
    this.convertDiesToDataTemplates();
  }

  /* Data */
  headers: any = [
    { text: 'Tag', value: 'TagName' },
    { text: 'Shape', value: 'Shape' },
    { text: 'Height', value: 'Height' },
    { text: 'Width', value: 'Width' },
    { text: 'Corner Radius', value: 'CornerRadius' },
    { text: 'Download', value: 'Download', sortable: false, filterable: false },
    { text: 'Image', value: 'Svgtrimlineraster', sortable: false, filterable: false }
  ];
  templates: any = [
    {
      Height: 0,
      Width: 0,
      TagName: '',
      CornerRadius: '',
      Image: '',
      Shape: '',
      Download: '',
      Svgtrimlineraster: ''
    }
  ];
  specialTemplates: any[] = [
    {
      Height: 0,
      Width: 0,
      TagName: '',
      CornerRadius: '',
      Image: '',
      Shape: '',
      Download: '',
      Svgtrimlineraster: ''
    }
  ];
  cornerRadiusOptions: number[] = [0.125, 0.06325, 0.03125];
  shapeOptions: string[] = ['Rectangle', 'Square', 'Circle', 'Oval'];
  height: number = 0;
  width: number = 0;
  shape: string = '';
  cornerRadius: number = 0;
  rowsPerpage: number = 500;
  search: string = '';
  searchValue: string = '';
  shouldSearchByHeightAndWidth: boolean = false;
  loading: boolean = false;
  debounce: boolean = false;
  svgPreview: boolean = false;
  showSpecial: boolean = false;
  missingImgDiag: boolean = false;
  selectedDie: number = 0;
  addDieDiag: boolean = false;
  heightFilter: string = '';
  widthFilter: string = '';
  //validation rules

  waitForSearch() {
    this.searchValue = this.search;
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 1);
  }

  onSearchByHeightAndWidth() {
    this.shouldSearchByHeightAndWidth = true;
    this.search = '';
  }
  onSearchByTag() {
    this.shouldSearchByHeightAndWidth = false;
    this.heightFilter = '';
    this.widthFilter = '';
  }

  /* Computed */
  get filteredItems() {
    let templates = this.showSpecial ? this.specialTemplates : this.templates;
    if (!this.shouldSearchByHeightAndWidth) return templates;
    return templates.filter(item => {
      const heightMatch = this.heightFilter
        ? item.Height.toString().includes(this.heightFilter)
        : true;
      const widthMatch = this.widthFilter
        ? item.Width.toString().includes(this.widthFilter)
        : true;
      return heightMatch && widthMatch;
    });
  }

  /* Loaders */
  async DownloadDieTemplates(item: any) {
    this.missingImgDiag = false;
    try {
      this.selectedDie = item.Id;
      await DieService.DownloadDieTemplates(item);
      this.selectedDie = 0;
    } catch (err) {
      this.selectedDie = 0;
      this.missingImgDiag = true;
    }
  }

  filterObject(item: any, queryText: string) {
    if (!item) return false;
    let tag = this.removeLeadingZerosAfterChars(item.toLowerCase());
    let query = this.removeLeadingZerosAfterChars(queryText.toLowerCase());
    return tag.toLowerCase().startsWith(query);
  }
  removeLeadingZerosAfterChars(str) {
    return str.replace(/([A-Za-z]+)0+/g, '$1');
  }

  convertDiesToDataTemplates() {
    this.templates = this.dies.filter((d: any) => !d.IsAdvancedDie);
    this.specialTemplates = this.dies.filter((d: any) => d.IsAdvancedDie);
  }
  async getTemplates() {
    const response = await DieService.GetTemplatesListings();
    this.templates = response.data.filter(
      (d: any) => !d.IsAdvancedDie && d.IsVisibleInUi
    );
    this.specialTemplates = response.data.filter((d: any) => d.IsAdvancedDie);
  }
  /* Mounted */
  async mounted() {
    if (!this.templates || this.templates.length < 2) this.getTemplates();
  }
  /* Created */
  async created() {
    this.convertDiesToDataTemplates();
  }
}
