
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Item } from '../store/models/ItemModel';
import { PricingMatrixModel, QuoteData } from '../store/models/QuotesModel';
import { OrdersService } from '@/services/orders-service';
import { AddressService } from '@/services/address-service';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import DialogLayout from './DialogLayout.vue';
import AddEditBillingAddress from './addressComponents/AddEditBillingAddress.vue';
import PaymentTerms from '@/components/PaymentTerms.vue';
import PaymentSelectCard from '@/components/cardComponents/PaymentSelectCard.vue';
import { QuickDialog } from '../store/models/DialogModel';
import { QuoteService } from '../services/quote-service';
import ItemVarnishIcon from './Icons/ItemVarnishIcon.vue';

import {
  RushOption,
  OrderlineDetails,
  ShippingDeliveryMethod
} from '@/store/models/OrderlineModel';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { DateHelper } from '@/helpers/DateHelper';
import { PaymentProfile } from '@/store/models/PaymentModel';
import AddEditShippingAddress from './AddEditShippingAddress.vue';
import { Address } from '@/store/models/AddressModel';
import { ConstValues } from '@/ConstValues';
import AddressCardTooltip from './addressComponents/AddressCardTooltip.vue';

@Component({
  components: {
    DialogLayout,
    AddEditBillingAddress,
    PaymentTerms,
    PaymentSelectCard,
    AddEditShippingAddress,
    AddressCardTooltip,
    ItemVarnishIcon
  }
})
export default class ItemOrderProofDialog extends Vue {
  $refs!: {
    ProofDialogForm: HTMLFormElement;
    addressDialog: HTMLFormElement;
    PaymentTerms: HTMLFormElement;
    AddEditShippingAddress: HTMLFormElement;
  };
  /* Properties */
  @Prop({ required: true }) item!: Item;
  @Prop() isImageDoneProccessing!: boolean;

  /* Store Actions */
  @Getter('shippingDeliveryOptions', { namespace: 'orderlines' })
  getDeliveryOptionsFromStore: (isInternational: boolean) => ShippingDeliveryMethod[];

  /* Watchers */
  @Watch('dialog')
  onDialogChange(val: boolean) {
    if (!val) {
      this.dialog = false;
      this.$refs.ProofDialogForm.reset();
    }
  }

  @Watch('quantity')
  onQuantityChange() {
    this.debounceAction = DateHelper.Debounce(() => {
      this.isCalculatingPrice = true;
      this.calculateProofFees();
    }, this.debounceAction);
  }

  @Watch('selectedAddress')
  onSelectedAddressChange() {
    this.getDeliveryOptions();
  }

  /* Data */
  deliveryOptions: ShippingDeliveryMethod[] = [];
  selectedDeliveryOption: ShippingDeliveryMethod | null = null;
  isAddressLoading: boolean = false;
  isOrderingProof: boolean = false;
  isCalculatingPrice: boolean = false;
  doesItemHaveVanish: boolean = false;
  willUseVarnishOnProof: boolean = false;
  valid: boolean = true;
  lazy: boolean = true;
  rushOption: RushOption = new RushOption();
  rushOptions: RushOption[] = [];
  laminateOptions: any[] = [
    { text: 'No', value: false },
    { text: 'Yes', value: true }
  ];
  laminateOption: boolean = true;
  quantity: number = 1;
  quantitiesOption: number[] = [1, 2, 3, 4, 5];
  orderline: OrderlineDetails[] = [];
  totalPrice: string = '$0.00';
  dialog: boolean = false;
  data: QuickDialog | null = null;
  addresses: any[] = [];
  selectedAddress: any = '';
  hasBillingAddress: boolean = false;
  proofPrices: { ProofPrice: number; AdditionalProofPrice: number } = {
    ProofPrice: 0,
    AdditionalProofPrice: 0
  };
  paymentProfile: PaymentProfile = new PaymentProfile();
  debounceAction: any;
  /* Methods */
  setPaymentProfile(profile: any) {
    this.paymentProfile = profile;
  }
  async getAllCustomerAddresses(defaultAddress: Address | null = null) {
    try {
      this.isAddressLoading = true;
      const response = await AddressService.GetShippingAddressesFull(true, true);
      this.addresses = response.data;
      this.selectedAddress = defaultAddress
        ? this.addresses.find(a => a.Id == defaultAddress.Id)
        : this.addresses[0];
      this.isAddressLoading = false;
    } catch (err) {
      this.isAddressLoading = false;
      SetSnackBar('Something went wrong, please contact Technical support');
    }
  }
  async getDeliveryOptions() {
    try {
      this.deliveryOptions = this.getDeliveryOptionsFromStore(
        this.selectedAddress?.Country?.Id > 0 &&
          this.selectedAddress?.Country?.Id !== ConstValues.DEFAULT_COUNTRY_ID
      );
      if (!this.deliveryOptions.find(d => d.Id == this.selectedDeliveryOption?.Id)) {
        this.selectedDeliveryOption = this.deliveryOptions[0];
      }
    } catch (e) {
      console.log(e);
    }
  }

  async LoadRushFeeValues() {
    if (this.orderline) {
      try {
        const response = await OrdersService.GetRushFeeValues(
          ConversionHelper.convertOrderlineToAddModel(this.orderline[0])
        );
        this.rushOptions = response.data.filter((d: any) => d.IsAvailable);
      } catch (err) {
        SetSnackBar(
          'Something went wrong loading the rush options, please try refreshing your page or contact Technical support if issue persists.'
        );
      }
    }
  }
  orderProof() {
    this.valid =
      this.$refs.ProofDialogForm.validate() &&
      !!this.paymentProfile &&
      !!this.paymentProfile.Id;
    if (this.valid) {
      if (!this.$store.getters['customer/getCustomer'].BillingAddress.Id) {
        this.$refs.addressDialog.openAddressDialog();
        return;
      }
      this.isOrderingProof = true;
      const model = {
        ItemId: this.item.Id,
        AddressId: this.selectedAddress.Id,
        DieId: 0,
        Quantity: Number(this.quantity),
        HasFinish: this.laminateOption,
        ShouldChangeStatus: true,
        PaymentProfileId: this.paymentProfile?.PaymentProfileId,
        ShippingDeliveryOptionId: this.selectedDeliveryOption?.Id
      };
      this.$emit('response', model);
    }
  }
  async onOrderProofClick() {
    this.orderProof();
  }
  async calculateProofFees() {
    if (!this.quantity) {
      this.isCalculatingPrice = false;
      return;
    }
    const response = await OrdersService.CalculateProofPrice(
      this.quantity,
      this.item.VarnishInformation.HasVarnish
    );
    this.totalPrice = response.data.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });
    this.isCalculatingPrice = false;
  }

  async getQuoteValue() {
    let quantity = Number(this.quantity);
    const model = new PricingMatrixModel({
      DieId: this.item.Die.Id,
      MaterialId: this.item.Material.Id,
      FinishId: this.item.Finish.Id,
      ColorId: this.item.Color.Id,
      Quantity: quantity,
      StartQuantity: quantity,
      EndQuantity: quantity,
      IncrementBy: 1000,
      ShouldUseVarData: false
    });
    const response = await QuoteService.GetQuoteValues(model);
    if (response.data[2]) {
      let totalprice = response.data[2].TotalCost;
      this.totalPrice = totalprice.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      });
    }
  }
  async getProofPrices() {
    const { data } = await OrdersService.GetProofPrices();
    this.proofPrices = data;
  }

  async openDialog() {
    this.getProofPrices();
    this.data = ConversionHelper.convertQuickDialog(this.item);
    this.doesItemHaveVanish = this.item.VarnishInformation.HasVarnish;
    this.getAllCustomerAddresses();
    this.calculateProofFees();
    this.quantity = 1;
    this.dialog = true;
  }
  closeDialog() {
    this.isOrderingProof = false;
    this.dialog = false;
  }
  /* Computed */
  get ConstValues() {
    return ConstValues;
  }
  /* Mounted */
  async mounted() {}
  /* Created */
  created() {}
  /* Created */
}
