import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import store from '../store/modules/index';
import { AdminUser, NewCustomerModel, User } from '@/store/models/ProfileModel';
import { AdminCustomer } from '@/store/models/CustomerModel';
import AuthService from './auth-service';
import { BetaAccessModel, CustomerBetaAccessModel } from '@/store/models/RequestModels';
import { ChangeOrderlineDueDateModel, AltPricingModel } from '@/store/models/AdminModel';
import { handleResponse, handleErrorResponse, downloadFile } from '@/helpers/ApiHelper';

const adminUrl = `${process.env.VUE_APP_ROOT_API}/Administrator`;
const authUrl = `${process.env.VUE_APP_AUTH_API}`;
const webAccessUrl = `${process.env.VUE_APP_ROOT_API}/WebAccess`;

export const AdminService = {
  ChangeOrderlineDueDate(model: ChangeOrderlineDueDateModel): Promise<AxiosResponse> {
    return axios.post(`${adminUrl}/ChangeOrderlineDueDate`, model);
  },
  createCustomer(model: NewCustomerModel) {
    return axios.post(`${adminUrl}/CreateCustomer`, model);
  },
  getAllCustomers() {
    return axios.get(`${adminUrl}/GetCustomers`);
  },
  getAllCustomersCrm() {
    return axios.get(`${adminUrl}/GetCustomersCrm`);
  },
  getCodeManagerInfo(orderlineId: string) {
    return axios.get(`${adminUrl}/GetCodeManagerInfo?orderlineId=${orderlineId}`);
  },
  getCustomer(customerId: string): Promise<AdminCustomer> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `${adminUrl}/GetCustomer?customerId=${customerId}`
        );
        handleResponse(response);
        response.data.Customer.Users = response.data.Users;
        response.data.Customer.TotalInvoiced =
          response.data.TotalInvoiced.TotalAmount | 0;
        response.data.Customer.TotalInvoicedYear =
          response.data.TotalInvoicedYear.TotalAmount | 0;
        response.data.Customer.TotalPastDue = response.data.TotalPastDue;
        let returnValue = new AdminCustomer(response.data.Customer);
        return resolve(returnValue);
      } catch (error) {
        return reject(error);
      }
    });
  },
  getActivityByCustomer(customerId: string, queryDate: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `${adminUrl}/GetActivitiesForCustomer?customerId=${customerId}&activitiyDate=${queryDate}`
        );
        handleResponse(response);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  },
  getActivityByUser(userId: string, queryDate: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `${adminUrl}/GetActivitiesForUser?userId=${userId}&activitiyDate=${queryDate}`
        );
        handleResponse(response);
        return resolve(response.data);
      } catch (error) {
        return reject(error);
      }
    });
  },
  getAllNotesForOrderline(orderlineId: string): Promise<any> {
    return axios.get(`${adminUrl}/GetAllNotesForOrderline?orderlineId=${orderlineId}`);
  },
  getUsers() {
    const model = {
      SearchValue: '',
      Skip: 0,
      Take: 10000,
      OrderByValue: 0,
      IsDescending: false
    };
    return axios.post(`${adminUrl}/GetUsers`, model);
  },
  impersonateUser(model: any) {
    return axios.post(`${adminUrl}/impersonate`, model);
  },
  async RemoveCustomer(customerId: string) {
    let model = { PublicId: customerId };

    return axios.post(`${adminUrl}/RemoveCustomer`, model);
  },
  async saveCustomerData(model: AdminCustomer): Promise<AxiosResponse> {
    let responseModel: any = {
      PublicId: model.PublicId,
      Id: model.Id,
      Brand: model.Brand,
      Comments: model.Comments,
      ContactEmail: model.ContactEmail,
      OD: model.OD,
      Options: model.Options,
      SalesPersonId: model.SalesPerson.Id,
      Term: model.Term,
      ShipTo: model.ShipTo
    };
    return axios.post(`${adminUrl}/SaveCustomer`, responseModel);
  },
  async impersonateCustomer(model: FormData, store: any): Promise<AxiosResponse> {
    let username = model.get('userName') as string;
    let customerId = model.get('customerId') as string;
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      withCredentials: true
    };
    return new Promise(async (resolve, reject) => {
      let response = await axios.post(
        `${authUrl}/impersonate/`,
        { username, customerId },
        requestOptions
      );
      try {
        const token = handleImpersonationResponse(response);
        // login successful if there's a jwt token in the response
        if (token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.removeItem('user');
          const user = new User(token);
          axios.defaults.headers.common['Authorization'] = `Bearer ${user.sessionId}`;
          let displayNameResponse = await AuthService.getDisplayName();
          if (displayNameResponse.data) user.displayName = displayNameResponse.data;
          localStorage.setItem('user', JSON.stringify(user));
          return resolve(token);
        }
      } catch (error) {
        return reject(error);
      }
    });
  },
  async CreateUser(model: AdminUser) {
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      withCredentials: true
    };
    try {
      await axios.post(`${adminUrl}/CreateUser`, model, requestOptions);
    } catch (err) {
      throw err;
    }
  },
  async DeleteUser(username: string) {
    console.log(`UserName: ${username}`);
    const requestOptions: AxiosRequestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      withCredentials: true
    };
    try {
      const response = await axios.post(
        `${adminUrl}/DeleteUser`,
        JSON.stringify(username),
        requestOptions
      );
      return response;
    } catch (err) {
      console.log(err);
      if (err.response) {
        const errorMessage = err.response.data.Message || err.response.data;
        console.error('Error:', errorMessage);
        throw new Error(errorMessage);
      }
      throw err;
    }
  },
  async DeleteCustomer(customerId: string, deletePw: string) {
    let model = { customerId: customerId, celetePw: deletePw };
    return axios.post(`${adminUrl}/DeleteCustomer`, model);
  },
  getImpersonatedCustomer() {
    return axios.get(`${adminUrl}/GetImpersonatedCustomer`);
  },
  changeUserName(oldUserName: string, newUserName: string, config: any = null) {
    let model = { OldUserName: oldUserName, NewUserName: newUserName };
    return axios.post(`${adminUrl}/changeUserName`, model, config);
  },
  addActivity(entry: string) {
    return axios.get(`${webAccessUrl}/AddActivity/${entry}`);
  },
  addActivityStream(entry: string) {
    return axios.post(`${webAccessUrl}/AddActivityStream`, { Entry: entry });
  },
  getAltPricing(customerId: string, quoteQuantity: number) {
    return axios.get(
      `${adminUrl}/GetAltPricing?customerId=${customerId}&quoteQuantity=${quoteQuantity}`
    );
  },
  copyCurrentCustomerPricingToCloneCustomer(customerId: string) {
    return axios.get(`${adminUrl}/ClonePricingCustomer?customerId=${customerId}`);
  },
  updateAltPricingItem(model: AltPricingModel, config: any = null) {
    return axios.post(`${adminUrl}/UpdateAltPricing`, model, config);
  },
  deleteAltPricingItem(model: AltPricingModel, config: any = null) {
    return axios.post(`${adminUrl}/DeletePricingModel`, model, config);
  },
  addAltPricingItem(model: AltPricingModel, config: any = null) {
    return axios.post(`${adminUrl}/AddPricingModel`, model, config);
  },
  updateAltPricingEstimateValues(model: AltPricingModel, config: any = null) {
    return axios.post(`${adminUrl}/UpdateAltPricingEstimatedValues`, model, config);
  },
  comboPricingGetAllByCustomer(customerId: string) {
    return axios.get(`${adminUrl}/GetComboPricingForCustomer?customerId=${customerId}`);
  },
  generateComboPricing(model: any, config: any = null) {
    return axios.post(`${adminUrl}/GenerateComboPricing`, model, config);
  },
  async getComboCustomerPricingFile(customerId: string) {
    const response = await axios({
      url: `${adminUrl}/GetComboPricingCsv?customerId=${customerId}`,
      method: 'GET',
      responseType: 'blob'
    });
    const fileName = `CustomerComboPricing_${customerId}.csv`;
    if (response.data) downloadFile(response.data, fileName);
  },
  generatePriceQuoteForComboPricing(model: any, config: any = null) {
    return axios.post(`${adminUrl}/GenerateComboPricingQuote`, model, config);
  },
  saveComboPricing(model: any, config: any = null) {
    return axios.post(`${adminUrl}/SaveComboPricing`, model, config);
  },
  deleteComboPricing(model: any, config: any = null) {
    return axios.post(`${adminUrl}/DeleteComboPricings`, model, config);
  },
  getActivitiesByDate(lastActivityId: string, shouldIncludeAdmin: boolean) {
    return axios.get(
      `${webAccessUrl}/GetActivityStream?lastActivityId=${lastActivityId}&shouldIncludeAdmin=${shouldIncludeAdmin}`
    );
  },
  getActivityStreamByCustomerName(customerName: string, shouldIncludeAdmin: boolean) {
    return axios.get(
      `${webAccessUrl}/GetActivityStreamByCustomerName?customerName=${customerName}&shouldIncludeAdmin=${shouldIncludeAdmin}`
    );
  },
  processOrderByFile(formData: FormData, config: any = null) {
    return axios.post(`${adminUrl}/ProcessOrderByFile`, formData, config);
  },
  refreshCustomer(id: string) {
    return axios.get(`${adminUrl}/RefreshCustomer?customerId=${id}`);
  },
  refreshAllCaches() {
    return axios.get(`${adminUrl}/RefreshAllCache`);
  },
  refreshPricingCaches() {
    return axios.get(`${adminUrl}/RefreshPricingCache`);
  },
  refreshCustomerCache() {
    return axios.get(`${adminUrl}/RefreshCustomerCache`);
  },
  refreshDieCache() {
    return axios.get(`${adminUrl}/RefreshDieCache`);
  },
  getclonePricingCustomerId() {
    return axios.get(`${adminUrl}/GetCloneCustomerId`);
  },
  refreshMaterial() {
    return axios.get(`${adminUrl}/RefreshMaterialCache`);
  },
  saveOrderlineNote(model: any) {
    return axios.post(`${adminUrl}/SaveOrderlineNote`, model);
  },
  setBetaAccess(model: BetaAccessModel) {
    return axios.post(`${adminUrl}/SetBetaAccess`, model);
  },
  setCustomerBetaAccess(model: CustomerBetaAccessModel) {
    return axios.post(`${adminUrl}/SetBetaAccessForCustomer`, model);
  },
  GenerateSamDockCustomerImport() {
    return axios.get(`${adminUrl}/GenerateSamDockCustomerImport`);
  },
  SearchCompanyName(searchValue: string) {
    return axios.get(`${adminUrl}/GetCustomerNamesSearch?search=${searchValue}`);
  },
  ZeroOutOrderlines(orderlineIds: string[], reason: string) {
    return axios.post(`${adminUrl}/ZeroOutOrderlines`, {
      OrderlineIds: orderlineIds,
      Reason: reason
    });
  },
  async sendAmazonTspReport(endDate: string) {
    return axios.get(`${adminUrl}/GenerateAmazonReportEmail?endDate=${endDate}`);
  }
};

function handleImpersonationResponse(response: any) {
  const data = response.data;
  if (response.status !== 200) {
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      store.dispatch('profile/logout');
      location.reload();
    }

    const error = (data && data.message) || response.statusText;
    return error;
  }

  return data;
}

export default AdminService;
