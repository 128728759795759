import { VarDataDynamicTextTypes } from '@/ConstValues';
import { AddressNote } from './AddressModel';
import {
  OrderlineDetails,
  RushOption,
  OrderlinePurchaseStateTypes,
  OrderLineTypes
} from './OrderlineModel';
import { ItemDetails } from './ItemModel';

export class RequestedRollSpecificationModel {
  OriginalOrderline!: CalcOrderlineModel;
  OrderlineToEdit!: CalcOrderlineModel;
  RequestedMaxOD!: number;
  RequestedLabelsPerRoll!: number;
  RequestedMaxRolls!: number;
  RequestedValueToChange!: number;
  constructor(
    OrderlineToEdit: OrderlineDetails,
    OriginalOrderline: OrderlineDetails,
    RequestedMaxOD: number,
    RequestedLabelsPerRoll: number,
    RequestedMaxRolls: number,
    RequestedValueToChange: number = 0
  ) {
    this.OrderlineToEdit = new CalcOrderlineModel(OrderlineToEdit);
    this.OriginalOrderline = new CalcOrderlineModel(OriginalOrderline);
    this.RequestedMaxOD = RequestedMaxOD;
    this.RequestedLabelsPerRoll = RequestedLabelsPerRoll;
    this.RequestedMaxRolls = RequestedMaxRolls;
    this.RequestedValueToChange = RequestedValueToChange;
  }
}
export class BetaAccessModel {
  PublicId!: string;
  UserName!: string;
  DisplayName!: string;
  HasBetaAccess!: boolean;
  constructor(rawItem: any) {
    if (rawItem) {
      this.PublicId = rawItem.PublicId;
      this.HasBetaAccess = rawItem.HasBetaAccess;
      this.UserName = rawItem.UserName;
      this.DisplayName = rawItem.DisplayName;
    }
  }
}
export class CustomerBetaAccessModel {
  CustomerId!: string;
  HasBetaAccess!: boolean;
  constructor(rawItem: any) {
    if (rawItem) {
      this.CustomerId = rawItem.UserId;
      this.HasBetaAccess = rawItem.HasBetaAccess;
    }
  }
}

export class BarcodeGenModel {
  WidthInches!: number;
  HeightInches!: number;
  Id!: number;
  Top!: number;
  Left!: number;
  ItemId!: string;
  BarcodeTypeId: number;
}

export class CalcOrderlineModel {
  ItemId!: string;
  Quantity!: number;
  Id!: string;
  OD!: number;
  Status!: string;
  RollQuantity!: number;
  MaxLabelsPerRoll!: number;
  DueDate!: Date;
  State!: string;
  CanChange!: boolean;
  OrderLineNumber!: number;
  CustomerOrderLineNumber!: number;
  ShippingAddressId!: string;
  RollDirectionId!: number | string;
  HasBarcodeData!: boolean;
  Action!: string;
  PO!: string;
  PriceEach!: number;
  SetupCosts!: number;
  VariableDataPriceEach!: number;
  IsARush!: boolean;
  IsHandApply!: boolean;
  RushFee!: number;
  RushOptions!: RushOption;
  HandApplyFee!: number;
  OrderlinePurchaseStateType!: OrderlinePurchaseStateTypes;
  OrderLineType!: OrderLineTypes;
  constructor(orderline: OrderlineDetails | any) {
    if ((orderline as OrderlineDetails).Item !== undefined) {
      this.PopulateOrderlineDetail(orderline);
    } else {
      this.PopulateGeneric(orderline);
    }
  }
  PopulateGeneric(orderline: any) {
    this.ItemId = orderline.ItemId;
    this.Quantity = orderline.Quantity;
    this.Id = orderline.Id;
    this.OD = orderline.OD;
    this.Status = orderline.Status;
    this.RollQuantity = orderline.RollQuantity;
    this.MaxLabelsPerRoll = orderline.MaxLabelsPerRoll;
    this.DueDate = orderline.DueDate;
    this.State = 'New';
    this.CanChange = true;
    this.OrderLineNumber = orderline.OrderLineNumber;
    this.CustomerOrderLineNumber = orderline.OrderLineNumber;
    this.ShippingAddressId = orderline.ShippingAddressId;
    this.RollDirectionId = orderline.RollDirectionId;
    this.HasBarcodeData = false;
    this.Action = 'Add';
    this.PO = orderline.PO;
    this.PriceEach = orderline.PriceEach;
    this.SetupCosts = orderline.SetupCosts;
    this.VariableDataPriceEach = orderline.VariableDataPriceEach;
    this.IsARush = orderline.IsARush;
    this.IsHandApply = orderline.IsHandApply;
    this.RushFee = orderline.RushOption?.RushFee;
    this.RushOptions = orderline.RushOption;
    this.HandApplyFee = orderline.HandApplyFee;
    this.OrderlinePurchaseStateType = orderline.OrderlinePurchaseStateType;
    this.OrderLineType = orderline.OrderLineType;
  }
  PopulateOrderlineDetail(orderline: OrderlineDetails) {
    this.ItemId = orderline.Item.Id;
    this.Quantity = orderline.Quantity;
    this.Id = orderline.Id;
    this.OD = orderline.OD;
    this.Status = orderline.Status;
    this.RollQuantity = orderline.RollQuantity;
    this.MaxLabelsPerRoll = orderline.MaxLabelsPerRoll;
    this.DueDate = orderline.DueDate;
    this.State = 'New';
    this.CanChange = true;
    this.OrderLineNumber = orderline.OrderLineNumber;
    this.CustomerOrderLineNumber = orderline.OrderLineNumber;
    this.ShippingAddressId = orderline.ShippingAddress.Id;
    this.RollDirectionId = orderline.RollDirection.Id;
    this.HasBarcodeData = false;
    this.Action = 'Add';
    this.PO = orderline.PO;
    this.PriceEach = orderline.PriceEach;
    this.SetupCosts = orderline.SetupCosts;
    this.VariableDataPriceEach = orderline.VariableDataPriceEach;
    this.IsARush = orderline.IsARush;
    this.IsHandApply = orderline.IsHandApply;
    this.RushFee = orderline.RushFee;
    this.RushOptions = orderline.RushOption;
    this.HandApplyFee = orderline.HandApplyFee;
    this.OrderlinePurchaseStateType = orderline.OrderlinePurchaseStateType;
    this.OrderLineType = orderline.OrderLineType;
  }
}
export class SaveItemModel {
  AdditionalHitsOfWhite!: number;
  Asin!: string;
  AuthenticationStatus!: Boolean;
  BarcodeDataType: string;
  BrandId!: number;
  ColorId!: number;
  Description!: string;
  DieId!: number;
  FinishId!: number;
  FNSKU!: string;
  Gtin!: string;
  GtinType!: number;
  HasBarcode!: boolean;
  HasVarnish!: boolean;
  IsNew!: boolean;
  ItemId!: string;
  ManufacturingCity!: string;
  ManufacturingCountry!: string;
  ManufacturingName!: string;
  ManufacturingStateProvince!: string;
  ManufacturedCountryTextType!: number;
  MaterialId!: number;
  OriginalFileName!: string;
  OriginalSpotVarnishFileName: string;
  PartNumber!: string;
  RollDirectionId!: number;
  ShouldConvertFontsToOutline!: boolean;
  ShouldEnablePantonesForProofs!: boolean;
  ShouldOverride!: boolean;
  SKU!: string;
  TempFileName!: string;
  TempSpotVarnishFileName: string;
  VarDataDynamicTextType!: VarDataDynamicTextTypes;
  constructor(item: ItemDetails) {
    this.AdditionalHitsOfWhite = item.AdditionalHitsOfWhite;
    this.AuthenticationStatus = item.AuthenticationStatus;
    this.BrandId = item.Brand?.Id || 0;
    this.ColorId = item.Color.Id;
    this.Description = item.Description;
    this.DieId = item.Die.Id;
    this.FinishId = item.Finish.Id;
    this.HasBarcode = item.HasBarcode;
    this.HasVarnish = item.HasVarnish;
    this.IsNew = item.IsNew;
    this.ItemId = item.Id;
    this.MaterialId = item.Material.Id;
    this.PartNumber = item.PartNumber;
    this.RollDirectionId = item.RollDirection.Id as number;
    this.ShouldConvertFontsToOutline = item.ShouldConvertFontsToOutline;
    this.ShouldEnablePantonesForProofs = item.ShouldEnablePantonesForProofs;
    this.BarcodeDataType = 'None';
    if (this.HasBarcode) {
      this.Asin = item.ASIN;
      this.BarcodeDataType = 'Amazon';
      this.FNSKU = item.FNSKU;
      this.Gtin = item.Gtin;
      this.GtinType = item.GtinType.Id;
      this.ManufacturingCity = item.ManufacturingCity;
      this.ManufacturingCountry = item.ManufacturingCountry.CountryValue;
      this.ManufacturingName = item.ManufacturingName;
      this.ManufacturedCountryTextType = item.ManufacturedCountryTextType;
      this.ManufacturingStateProvince =
        item.ManufacturingCountry.Id !== 1
          ? item.ManufacturingProvince
          : item.ManufacturingState;
      this.SKU = item.SKU;
      this.VarDataDynamicTextType = item.HumanReadable.Id;
    }
  }
}
export class AnonCustomerContactModel {
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  Message: string;
  Url: string;
  BrowserType: string;
}
export class AddressRequestModel {
  Id!: string;
  Attention!: string;
  CompanyName!: string;
  Street1!: string;
  Street2!: string;
  City!: string;
  State!: string;
  Zipcode!: string;
  RecordState!: string;
  IsAResidence!: boolean;
  IsProofAddress!: boolean;
  ShouldUseProvince!: boolean;
  Province!: string;
  IsMyDefaultAddress!: boolean;
  PhoneNumber!: string;
  DefaultUnwindDirection!: number;
  DefaultMaximumOD!: number;
  BrandLabel!: string;
  AddressOptionId!: number;
  PhoneId!: string;
  ShipToId!: number;
  CountryId!: number;
  EmailAddress!: string;
  EmailId!: string;
  IsBilling!: boolean;
  AddressNote!: AddressNote;
  DfdOption!: number;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.Attention = rawItem.Attention;
      this.CompanyName = rawItem.CompanyName;
      this.Street1 = rawItem.Street1;
      this.Street2 = rawItem.Street2;
      this.City = rawItem.City;
      this.State = rawItem.State;
      this.Zipcode = rawItem.Zipcode;
      this.RecordState = rawItem.RecordState;
      this.IsAResidence = rawItem.IsAResidence;
      this.IsProofAddress = rawItem.IsProofAddress;
      this.ShouldUseProvince = rawItem.ShouldUseProvince;
      this.Province = rawItem.Province;
      this.IsMyDefaultAddress = rawItem.IsMyDefaultAddress;
      this.PhoneNumber = rawItem.PhoneNumber;
      this.DefaultUnwindDirection = rawItem.DefaultUnwindDirection;
      this.DefaultMaximumOD = rawItem.DefaultMaximumOD;
      this.BrandLabel = rawItem.BrandLabel;
      this.AddressOptionId = rawItem.AddressOptionId;
      this.PhoneId = rawItem.PhoneId;
      this.ShipToId = rawItem.ShipToId;
      this.CountryId = rawItem.CountryId;
      this.EmailAddress = rawItem.EmailAddress;
      this.EmailId = rawItem.EmailId;
      this.IsBilling = rawItem.IsBilling;
      this.AddressNote = rawItem.AddressNote;
      this.DfdOption = rawItem.DfdOption;
    }
  }
}
