import axios from 'axios';
const importationAccessUrl = `${process.env.VUE_APP_ROOT_API}/dataimportation`;

export const DataImporationService = {
  GetStaticItems: function() {
    return axios.get(`${importationAccessUrl}/GetStaticItems`);
  },
  ValidateStaticDataFile: function(model: any) {
    return axios.post(`${importationAccessUrl}/ValidateStaticDataFile`, model);
  },
  ImportStaticItemData(model: any) {
    return axios.post(`${importationAccessUrl}/ImportStaticItemData`, model);
  },
  ImportStaticItemWithOrderData(model: any) {
    return axios.post(`${importationAccessUrl}/ImportStaticItemWithOrderData`, model);
  },
  ImportStaticItemOrder(model: any) {
    return axios.post(`${importationAccessUrl}/ImportStaticItemOrder`, model);
  },
  UploadCodeFile(formData: FormData, config: any = null) {
    return axios.post(`${importationAccessUrl}/UploadDataFile`, formData, config);
  }
};
