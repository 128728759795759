
import { ConstValues } from '@/ConstValues';
import { ConversionHelper } from '@/helpers/ConversionHelper';
import { Clone } from '@/helpers/ObjectHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { AddressService } from '@/services/address-service';
import ShippingService from '@/services/shipping-service';
import {
  Address,
  ShippingVendor,
  AddressChallengeResponse
} from '@/store/models/AddressModel';
import { Customer } from '@/store/models/CustomerModel';
import { ShippingVendorModel } from '@/store/models/RequestModels/ShippingRequestModels';
import { CustomerShippingVendor } from '@/store/models/ShippingModels';
import { Vue, Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
@Component
export default class AddShippingVendorDialog extends Vue {
  /* Properties */
  /* Store Actions */
  @Getter('getCustomer', { namespace: 'customer' }) Customer: Customer;
  /* Watchers */
  /* Data */
  isLoading: boolean = false;
  isFormValid: boolean = false;
  dialog: boolean = false;
  isSameAsPrimaryAddress: boolean = false;
  billingAddress: Address = new Address();
  states: { id: string; name: string }[] = [];
  countries: any[] = [];
  accountNum: string = '';
  selectedShippingVendor: ShippingVendor = new ShippingVendor();
  shippingVendors: ShippingVendor[] = [];
  customerShippingVendor!: CustomerShippingVendor;
  exceptionMessage: string = '';
  /* Utility Functions */
  resetData() {
    this.exceptionMessage = '';
    this.accountNum = '';
    this.isSameAsPrimaryAddress = false;
    this.billingAddress = new Address();
  }
  openDialog(vendor: CustomerShippingVendor = null) {
    this.dialog = true;
    this.resetData();
    if (vendor) {
      this.customerShippingVendor = vendor;
      this.accountNum = this.customerShippingVendor.AccountNum;
      this.billingAddress = this.customerShippingVendor.Address;
      this.isSameAsPrimaryAddress =
        this.customerShippingVendor.Address.Id == this.Customer.BillingAddress.Id;
      this.$nextTick();
    }
    this.getShippingVendors();
    this.GetStates();
    this.GetCountries();
    if (!vendor) {
      this.isSameAsPrimaryAddress = true;
      this.updateBillingAddressFromPrimary();
    }
  }
  closeDialog() {
    this.dialog = false;
  }
  async saveCustomerShippingVendor() {
    this.isLoading = true;
    try {
      let addressId = this.billingAddress.Id;
      if (!this.isSameAsPrimaryAddress) {
        const newBillingAddress = await this.updateBillingAddress();
        if (newBillingAddress == null) {
          addressId = null;
        } else {
          addressId = newBillingAddress.Id;
        }
      }
      if (addressId) {
        await this.validateBillingAddress(addressId);
        const model: ShippingVendorModel = {
          AccountNum: this.accountNum,
          Id: this.customerShippingVendor ? this.customerShippingVendor.Id : 0,
          ShippingVendorId: this.selectedShippingVendor.Id,
          AddressId: addressId
        };
        await ShippingService.SaveCustomerShippingVendor(model);
        SetSnackBar('Saved shipping account successfully');
        this.closeDialog();
        this.$emit('savedCustomerShippingVendor');
      }
    } catch (err) {
      let e = err as any;
      this.exceptionMessage = e.response.data.ExceptionMessage;
    }
    this.isLoading = false;
  }

  setCustomerShippingVendor(data: CustomerShippingVendor) {
    this.customerShippingVendor = data;
  }
  updateBillingAddressFromPrimary() {
    if (this.isSameAsPrimaryAddress) {
      this.billingAddress = Clone(this.Customer.BillingAddress);
      let stateValue = this.states.find(s => s.id == this.billingAddress.State);
      if (stateValue) this.billingAddress.State = stateValue.id;
      this.$nextTick();
    } else {
      this.billingAddress = new Address();
    }
  }
  async updateBillingAddress() {
    try {
      let addressModel = ConversionHelper.convertAddressToAddressModel(
        this.billingAddress,
        true
      );
      const { data } = await AddressService.UpdateAddress(addressModel);
      let addressResponse = new AddressChallengeResponse(data);
      if (addressResponse.HasValidationErrors) {
        SetSnackBar(
          `Failed to add new address, reason: ${addressResponse.ValidationErrors[0]}`
        );
        return null;
      } else {
        return addressResponse.EnhancedAddress;
      }
    } catch (err) {
      SetSnackBar(`Failed to update the billing address, ${err}`);
    }
  }
  async validateBillingAddress(addressId: string) {
    if (this.selectedShippingVendor.Name == 'UPS') {
      await ShippingService.ValidateBillingAddressForShipping(addressId, this.accountNum);
    }
  }

  /* Loaders */
  async getShippingVendors() {
    this.isLoading = true;
    try {
      const { data } = await ShippingService.GetShippingVendors();
      this.shippingVendors = data;
      if (this.customerShippingVendor) {
        this.selectedShippingVendor = data.find(
          (d: any) => d.Id === this.customerShippingVendor.ShippingVendor.Id
        );
      } else {
        this.selectedShippingVendor = data.find((d: any) => d.Name == 'UPS');
      }
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
    this.isLoading = false;
  }
  async GetStates() {
    const response = await AddressService.GetStates();
    this.states = Object.keys(response.data).map(key => ({
      id: key,
      name: response.data[key]
    }));
  }
  async GetCountries() {
    const response = await AddressService.GetCountries();
    this.countries = response.data;
    if (this.billingAddress.Country.Id === 0) {
      this.billingAddress.Country = this.countries[0];
    }
  }
  /* Computed */
  get canAddShippingVendor() {
    return this.selectedShippingVendor && this.accountNum;
  }
  get ConstValues() {
    return ConstValues;
  }
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
