export class ImportReport {
  HasAnError!: boolean;
  ImportationReport!: string;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any): void {
    if (rawItem) {
      this.HasAnError = rawItem.HasAnError;
      this.ImportationReport = rawItem.ImportationReport;
    }
  }
}
