
import Vue from 'vue';
import { Watch } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import axios from 'axios';
import Component, { mixins } from 'vue-class-component';
import { User } from './store/models/ProfileModel';
import { ConfirmationDialogInfo, Snackbar } from './store/models/CustomerModel';
import confirmationDialog from '@/components/ConfirmationDialog.vue';
import BugReport from './components/BugReport.vue';
import FooterBanner from './components/FooterBanner.vue';
import ConfirmCustomerDetailsDialog from './components/ConfirmCustomerDetailsDialog.vue';
import J1LFooter from './components/J1LFooter.vue';
import { CookieHelpers } from './helpers/CookieHelper';
import { ConstValues } from './ConstValues';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
const namespace: string = 'profile';

Component.registerHooks(['beforeRouteUpdate', 'beforeRouteLeave', 'metaInfo']);
@Component({
  components: {
    confirmationDialog,
    BugReport,
    FooterBanner,
    ConfirmCustomerDetailsDialog,
    J1LFooter
  }
})
export default class App extends Vue {
  $refs!: {
    confirmationLeaveDialog: HTMLFormElement;
    ConfirmationDialog: HTMLFormElement;
    FooterBanner: HTMLFormElement;
    ConfirmCustomerDetailsDialog: HTMLFormElement;
  };
  @Getter('isAuthenticated', { namespace }) isAuthenticated!: boolean;
  @Getter('getCurrentSnackbar', { namespace: 'customer' })
  getSnackbarInfo!: Snackbar;
  @Getter('getItemPartNumber', { namespace: 'items' })
  getPartNumbers: any;
  @Getter('impersonatedUser', { namespace: 'profile' })
  impersonatedUser!: string;
  @Action('GetCustomerItemsLimited', { namespace: 'items' })
  getCustomerItemsLimited: any;
  @Action('GetItemInfo', { namespace: 'items' })
  getItemInfo: any;
  @Action('getDieTemplates', { namespace: 'dies' })
  getDieTemplates: any;
  @Action('logout', { namespace }) logout: any;
  @Action('isAdmin', { namespace }) isAdmin: any;
  @Action('getUserValidation', { namespace }) getUserValidation: any;
  @Action('GetSavedOrders', { namespace: 'orderlines' }) getSavedOrders: any;
  @Action('GetShoppingCart', { namespace: 'orderlines' })
  getShoppingCart: any;
  @Getter('getCurrentConfirmationDialog', { namespace: 'profile' })
  getCurrentConfirmationDialog!: ConfirmationDialogInfo;
  @Action('getCurrentCustomer', { namespace: 'customer' })
  getCurrentCustomerStore: any;
  @Getter('isTransparencyCustomer', { namespace: 'customer' })
  isTransparencyCustomer: any;
  @Action('getDeliveryOptions', { namespace: 'orderlines' })
  getDeliveryOptions: () => {};
  layout = 'div';

  snackbarBackgroundColor: string = 'black';
  snackbarActionTextColor: string = 'pink';
  snackbarTimeout: number = -1;
  snackbarText: string = '';
  snackbar: boolean = false;
  snackbarWidth: number | undefined = undefined;
  actionText: string = 'close';
  action: any = null;
  hashChanged: boolean = false;

  @Watch('isAuthenticated')
  onIsAuthenticatedChange(val: boolean, prev: boolean) {
    if (val) {
      this.getUserValidation();
      this.loadStoreValues();
      this.$refs.ConfirmCustomerDetailsDialog.loadData();
    }
  }
  @Watch('getCurrentConfirmationDialog', { deep: true })
  onConfirmationDialogChange(val: ConfirmationDialogInfo) {
    this.$refs.ConfirmationDialog.openDialog(val);
  }

  @Watch('getSnackbarInfo')
  onChange() {
    let snackbarData = this.getSnackbarInfo;
    this.snackbarTimeout = snackbarData.timeout;
    this.snackbarText = snackbarData.message;
    this.actionText = snackbarData.actionText;
    this.action = snackbarData.customAction;
    this.snackbarWidth = snackbarData.width;
    this.snackbarActionTextColor = snackbarData.actionTextColor;
    this.snackbarBackgroundColor = snackbarData.backgroundColor;
    this.snackbar = true;
  }
  handleConfirmationDialogResponse(didAccept: boolean) {
    if (didAccept) this.getCurrentConfirmationDialog.action();
  }
  onSnackbarActionClick() {
    if (this.action !== null) this.action();
    this.snackbar = false;
  }
  shouldShowFooterBanner() {
    return (
      this.$route.path && this.$route.path !== '/AddPayment' && this.$route.path !== '/'
    );
  }
  setUpUserCredentials() {
    const localStorageUser = localStorage.getItem('user');
    if (localStorageUser) {
      this.getDieTemplates();
      this.getItemInfo();
      const user = new User(JSON.parse(localStorageUser));
      if (user.isImpersonating) {
        this.$store.dispatch('profile/getImpersonatedCustomer');
      } else {
        this.$store.commit('profile/setToken', user);
      }
      this.getCurrentCustomerStore();
      this.setDisplayStyle();
    }
  }
  setUpAxiosGlobals() {
    let that = this;
    axios.interceptors.response.use(
      function(response) {
        if (response.data == 'Redirect to Transparency') {
          window.location.href = process.env.VUE_APP_TRANSPARENCY_URL;
          return new Promise(() => {});
        }
        return response;
      },
      function(error) {
        // Do something with response error
        if (error.response && error.response.status === 401) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          that.logout();
        }
        return Promise.reject(error);
      }
    );
  }
  setIdleTimer() {
    let time: any;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;

    const refreshPage = () => {
      window.location.reload(true);
    };

    function resetTimer() {
      clearTimeout(time);
      // time = setTimeout(refreshPage, 3000);
      time = setTimeout(refreshPage, 30 * 60000);
    }
  }
  reloadApp() {
    window.location.reload(true);
  }
  closeModal() {
    this.hashChanged = false;
  }
  checkFormatVersion() {
    const formatVersion = localStorage.getItem('formatVersion');
    if (!formatVersion) {
      this.logout();
    }
  }
  async loadStoreValues() {
    if (this.isAuthenticated) {
      if (this.getPartNumbers) {
        await this.getCustomerItemsLimited();
      }
      this.getDeliveryOptions();
      this.getShoppingCart();
      this.getSavedOrders();
    }
  }
  checkVersion() {
    if (process.env.NODE_ENV == 'development') return;
    setInterval(() => {
      fetch(`${document.location.origin}/version.json`, { cache: 'no-cache' }).then(
        response => {
          response.json().then(data => {
            let currentVersion = localStorage.getItem('version');
            if (!data.hash || (currentVersion && currentVersion != data.hash)) {
              this.hashChanged = true;
            }
            if (!currentVersion || this.hashChanged) {
              localStorage.setItem('version', data.hash);
            }
          });
        }
      );
    }, 30000);
  }
  setDisplayStyle() {
    let displayStyle = CookieHelpers.getCookie(ConstValues.COOKIE_NAME_DISPLAY_STYLE);
    if (!displayStyle) {
      const value = this.isTransparencyCustomer
        ? ConstValues.DISPLAY_STYLE_LIST
        : ConstValues.DISPLAY_STYLE_CARD;
      CookieHelpers.setCookie(ConstValues.COOKIE_NAME_DISPLAY_STYLE, value);
      displayStyle = value;
    }
    this.$store.commit('customer/setDisplayStyle', displayStyle);
  }
  async created() {
    this.setUpAxiosGlobals();
    this.setUpUserCredentials();
    this.setIdleTimer();
    this.checkVersion();
  }
  async mounted() {
    this.onIsAuthenticatedChange(this.isAuthenticated, this.isAuthenticated);
  }
}
