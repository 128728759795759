
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import VueKonva from 'vue-konva';
import DefaultLayout from '@/components/DefaultLayout.vue';
import { ItemService } from '../services/item-service';
import { QuoteService } from '../services/quote-service';
import { DieService } from '../services/die-service';
import { Item, Material, Color, Finish, Die, ItemInfo } from '@/store/models/ItemModel';
import { PricingMatrixModel, QuoteData } from '../store/models/QuotesModel';
import { MathHelper } from '@/helpers/MathHelper';
import { ValidationHelper } from '@/helpers/ValidationHelper';
import QuoterDieTemplateDialog from '@/components/QuoterDieTemplate.vue';
import { StringHelper } from '@/helpers/StringHelper';
import { Stage } from 'konva/types/Stage';
import { Layer } from 'node_modules/konva/types/Layer';

interface KonvaLayer extends Vue {
  getNode(): Layer;
}
interface KonvaStage extends Vue {
  getStage(): Stage;
}

@Component({
  components: { DefaultLayout, QuoterDieTemplateDialog }
})
export default class Quoter extends Vue {
  $refs!: {
    quoteForm: HTMLFormElement;
    quoterDieTemplate: HTMLFormElement;
    stage: KonvaStage;
    layer: KonvaLayer;
  };
  /* Properties */
  /* Store Actions */
  @State('dieTemplates', { namespace: 'dies' })
  dieTemplates!: any;
  @State('itemInfo', { namespace: 'items' })
  itemInfo!: ItemInfo;
  @Getter('getItemPartNumber', { namespace: 'items' })
  getPartNumbers: any;
  @Action('GetCustomerItemsLimited', { namespace: 'items' })
  getCustomerItemsLimited: any;
  @Getter('isTransparencyCustomer', { namespace: 'customer' })
  isTransparencyCustomer: boolean;
  /* Watchers */
  @Watch('item.Die.Width')
  async onWidthChange(val: any) {
    let shape = this.getAccurateShape();
    await this.getDieId(Number(this.item.Die.Height), Number(this.item.Die.Width), shape);
    if (val && this.quantity && this.item.Die.Height) {
      this.debounce = true;
      setTimeout(() => {
        this.debounce = false;
      }, 1500);
    }
    if (this.canGetQuote()) this.getQuoteValue();
  }
  @Watch('item.Die.Height')
  async onHeightChange(val: any) {
    let shape = this.getAccurateShape();
    await this.getDieId(Number(this.item.Die.Height), Number(this.item.Die.Width), shape);
    if (val && this.quantity && this.item.Die.Width) {
      this.debounce = true;
      setTimeout(() => {
        this.debounce = false;
      }, 1500);
    }
    if (val <= 0.5) {
      this.hint =
        'Labels with a height under 1" may not be separated into individual rolls';
    } else {
      this.hint = '';
    }
    if (this.canGetQuote()) this.getQuoteValue();
  }
  @Watch('dieShape')
  async onDieShapeChange() {
    if (this.item.Die.Height && this.item.Die.Width) {
      let shape = this.getAccurateShape();
      await this.getDieId(
        Number(this.item.Die.Height),
        Number(this.item.Die.Width),
        shape
      );
    } else {
      if (this.dieShape === 0) {
        this.item.Die.Shape = 'Rectangle or Square';
      } else {
        this.item.Die.Shape = 'Circle or Oval';
      }
    }
    if (this.canGetQuote()) this.getQuoteValue();
  }
  @Watch('quantity')
  onQuantityChange(val: any) {
    if (this.item.Die.Height && this.item.Die.Width && val) {
      this.debounce = true;
      setTimeout(() => {
        this.debounce = false;
      }, 1000);
    }
  }
  @Watch('debounce')
  onDebounce(val: boolean) {
    if (!val) {
      this.getQuoteAfterPause();
    }
  }

  /* Data */
  dieToQuote: Die | null = null;
  dieShape: number = 0;
  totalPrice: string = '$0.00';
  totalPriceNum: number = 0;
  priceEach: number = 0;
  isLoading: boolean = false;
  debounce: boolean = false;
  selectedColor: number = 4;
  hint: string = '';
  numberUp!: number;
  quantity: number | null = null;
  totalFrames: number = 0;
  shapeOptions: string[] = ['Rectangle', 'Square', 'Circle', 'Oval'];
  colorOptions: Color[] = [
    new Color({ Id: 1, ClickColors: 1, Description: 'One Color (Black Only)' }),
    new Color({ Id: 4, ClickColors: 4, Description: 'Full Color (CMYK)' }),
    new Color({ Id: 5, ClickColors: 5, Description: 'Full Color + White' }),
    new Color({
      Id: 6,
      ClickColors: 6,
      Description: 'Full Color + 2 Hit White'
    })
  ];
  configKonva: any = {
    width: 200,
    height: 200
  };
  configCircle: any = {
    x: 100,
    y: 100,
    radius: 70,
    fill: 'red',
    stroke: 'black',
    strokeWidth: 4
  };
  valid: boolean = true;
  lazy: boolean = true;
  shouldUseVarData: boolean = false;
  shouldUseVarnish: boolean = false;

  heightRules = [
    (v: number) => !!v || 'The height is required',
    (v: number) => (v && v <= 12.375) || 'The height must be less than 12.375 inches',
    (v: number) => ValidationHelper.getDecimalPlaces(v, 4) || 'The max decimal is 4'
  ];
  widthRules = [
    (v: number) => !!v || 'The height is required',
    (v: number) => (v && v <= 24.375) || 'The width must be less than 24.375 inches',
    (v: number) => (v && v >= 0.125) || 'The width must at least .125 inches',
    (v: number) => ValidationHelper.getDecimalPlaces(v, 4) || 'The max decimal is 4'
  ];

  item: Item = new Item({
    Die: new Die()
  });

  maxHeight: number = 0;
  trueMaxHeight: number = 0;
  maxWidth: number = 0;
  aspectRatio: number = 38.58 / 12.375;
  // get this value from the database presssingleton for default printer
  isDieInStock: boolean | null = null;
  isDownloadingDieTemplate: boolean = false;
  isCalculatingPrice: boolean = false;
  konvaShapeList: any = [];
  /* Methods */
  setUpKonvaVariables() {
    this.konvaShapeList = [];
    this.configKonva = null;
    this.$refs.stage.getStage().clear();
    const frameWidth = 38.58;
    const frameHeight = 12.375;
    const maxWidthInPixels = 286;
    const maxHeightInPixels = 450;
    const screenHeightRatio = frameHeight / frameWidth;
    const screenWidthRatio = frameWidth / frameHeight;
    const maxWidthByHeightRatio = maxWidthInPixels * screenHeightRatio;
    const maxHeightByWidthRatio = maxHeightInPixels * screenWidthRatio;
    let width =
      maxWidthByHeightRatio <= maxHeightInPixels
        ? maxWidthInPixels
        : maxHeightByWidthRatio;
    let height =
      maxHeightByWidthRatio <= maxWidthInPixels
        ? maxHeightInPixels
        : maxWidthByHeightRatio;
    this.configKonva = { width, height };
    var die = {
      Height: parseInt(this.item.Die.Height as string),
      Width: parseInt(this.item.Die.Width as string),
      BleedHeight: parseInt(this.item.Die.Height as string) + 1.25,
      BleedWidth: parseInt(this.item.Die.Width as string) + 1.25,
      CornerRadius: this.item.Die.CornerRadius,
      GapAcross: 0.125,
      NumberAcross: this.trueMaxHeight,
      NumberAround: this.maxWidth
    };
    var x = (0.125 / frameWidth) * width;
    var y = (0.125 / frameHeight) * height;
    let rectWidth;
    let rectHeight: number;
    for (var row = 0; row < die.NumberAcross; row++) {
      for (var col = 0; col < die.NumberAround; col++) {
        rectWidth = (die.Width / frameWidth) * width;
        rectHeight = (die.Height / frameHeight) * height;
        var rect1 = {
          x: x,
          y: y,
          width: rectWidth,
          height: rectHeight,
          stroke: 'black',
          strokeWidth: 0,
          cornerRadius: (die.CornerRadius / frameWidth) * width,
          shadowBlur: 0,
          fill: '#0096ff',
          draggable: false
        };
        x += rectWidth + (die.GapAcross / frameWidth) * width;
        this.konvaShapeList.push(rect1);
      }
      y += rectHeight! + (die.GapAcross / frameHeight) * height;
      x = (0.125 / frameWidth) * width;
    }
    this.$refs.layer.getNode().draw();
  }
  formatStringLength(value: string, length: number) {
    if (value) {
      return `${value.substring(0, length)}${value.length >= length ? '...' : ''}`;
    }
    return '';
  }
  setDieWidthToEqualDieHeight() {
    this.item.Die.Width = this.item.Die.Height;
  }
  setDieShape(type: number) {
    this.dieShape = type;
  }
  validate() {
    this.$refs.quoteForm.validate();
  }
  onClickSeeDies() {
    this.$refs.quoterDieTemplate.openDialog();
  }

  setColorClick(data: any) {
    this.item.Color.Id = data.Color.Id;
    this.selectedColor = data.value;
    this.getQuoteValue();
  }
  onFinishChange(item: any) {
    this.item.Finish.BadgeText = item.Finish.ShortDesc;
    this.item.Finish.Id = item.Finish.Id;
    this.getQuoteValue();
  }
  onMaterialChange(item: any) {
    this.item.Material.BadgeText = item.Material.ShortDesc;
    this.item.Material.Id = item.Material.Id;
    if (this.item.Material.Id === 7 && this.item.Color.Id < 5) {
      this.item.Color = this.itemInfo.Colors.find(c => c.Id === 5);
    }
    this.getQuoteValue();
  }
  onShapeChange(option: number) {
    if (option === 0 || option === 3) this.item.Die.Width = null;
    this.dieShape = option;
    this.item.Die.Shape = this.shapeOptions[option];
    if (this.quantity && this.item.Die.Height && this.item.Die.Width)
      this.getQuoteValue();
  }
  async getQuoteAfterPause() {
    this.isLoading = true;
    if (this.debounce) return;
    await this.getQuoteValue();
    this.isLoading = false;
  }
  getAccurateShape(): string {
    let shape = '';
    switch (this.dieShape) {
      case 0: {
        shape = this.isDimensionsTheSame ? this.shapeOptions[1] : this.shapeOptions[0];
        this.item.Die.Shape = shape;
        return shape;
      }
      case 1: {
        shape = this.isDimensionsTheSame ? this.shapeOptions[2] : this.shapeOptions[3];
        this.item.Die.Shape = shape;
        return shape;
      }
    }
    this.item.Die.Shape = shape;
    return shape;
  }
  async getDieId(height: number, width: number, shape: string) {
    this.isDieInStock = false;
    if (!this.item.Die.Shape || !this.item.Die.Height || !this.item.Die.Width) {
      return;
    }
    const response = await DieService.GetDieIdByHeightWidthAndShape(height, width, shape);
    this.onGetDieCallback(response.data);
  }
  async DownloadDieTemplate() {
    this.isDownloadingDieTemplate = true;
    try {
      await DieService.DownloadDieTemplates(this.dieToQuote);
    } catch (err) {
      //error
    }
    this.isDownloadingDieTemplate = false;
  }
  setDieDimensions(height: string, width: string) {
    if (height) this.item.Die.Height = height;
    else this.item.Die.Width = width;
  }
  swapHeightAndWidth() {
    const height = this.item.Die.Height;
    this.item.Die.Height = this.item.Die.Width;
    this.item.Die.Width = height;
  }
  onGetDieCallback(response: any) {
    this.item.Die.Id = response.Id;
    this.numberUp = response.NumberUp;
    this.dieToQuote = response;
    this.isDieInStock = response.IsInStock;
    this.item.Die.CornerRadius = response.CornerRadius;
    this.maxWidth = response.NumberAround;
    this.trueMaxHeight = response.NumberAcross;
    this.maxHeight = response.NumberAcross > 9 ? 9 : response.NumberAcross;
    this.setUpKonvaVariables();
  }
  calculateNumberOfFrames() {
    if (this.quantity) {
      this.totalFrames = MathHelper.calculateNumberOfFrames(
        Number(this.quantity),
        this.numberUp
      );
    }
  }
  canGetQuote() {
    return (
      this.item.Die.Shape &&
      this.item.Die.Height &&
      this.item.Die.Width &&
      this.item.Material &&
      this.item.Finish &&
      this.item.Color &&
      this.quantity
    );
  }
  /* Loaders */

  async getQuoteValue() {
    if (this.valid && this.canGetQuote()) {
      this.isCalculatingPrice = true;
      let quantity = Number(this.quantity);
      const model = new PricingMatrixModel({
        DieId: this.item.Die.Id,
        MaterialId: this.item.Material.Id,
        FinishId: this.item.Finish.Id,
        ColorId: this.item.Color.Id,
        Quantity: quantity,
        StartQuantity: quantity,
        EndQuantity: quantity,
        IncrementBy: 1000,
        ShouldUseVarData: this.shouldUseVarData,
        ShouldUseVarnish: this.shouldUseVarnish,
        DieHeight: this.dieToQuote!.Height,
        DieWidth: this.dieToQuote!.Width,
        DieShape: this.dieToQuote!.Shape
      });
      const response = await QuoteService.GetQuoteValues(model);
      if (response.data[1] && this.IsCurrentQuote(response.data[1])) {
        let totalprice = response.data[1].TotalCost;
        let priceEach = totalprice / this.quantity!;
        this.priceEach = parseFloat(priceEach.toFixed(4));
        this.totalPriceNum = totalprice;
        this.totalPrice = totalprice.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD'
        });
      }
      this.isCalculatingPrice = false;
    }
  }
  IsCurrentQuote(data: any) {
    let quantity = Number(this.quantity);
    return (
      this.item.Die.Id == data.DieId,
      this.item.Material.Id == data.MaterialId,
      this.item.Finish.Id == data.FinishId,
      this.item.Color.Id == data.ColorId,
      quantity == data.Quantity,
      this.dieToQuote!.Height == data.Height,
      this.dieToQuote!.Width == data.Width
    );
  }
  onDieTemplateSelect(response: any) {
    this.item.Die.Shape = response.Shape;
    this.item.Die.Height = response.HeightValue;
    this.item.Die.Width = response.WidthValue;
    this.dieShape = this.getDieShape;
  }
  swapDieHeightAndWidthValues() {
    let initialDieHeight = this.item.Die.Height;
    let initialDieWidth = this.item.Die.Width;
    this.item.Die.Height = initialDieWidth;
    this.item.Die.Width = initialDieHeight;
  }

  getAssociatedColorSvg(clickColor: number) {
    switch (clickColor) {
      case 1:
        return 'blackonly.svg';
      case 4:
        return 'fullcolor.svg';
      case 5:
        return 'fullcolorwhite.svg';
      default:
        return 'fullcolorwhitex2.svg';
    }
  }

  get isDimensionsTheSame() {
    return (
      this.item.Die.Height &&
      this.item.Die.Width &&
      this.item.Die.Height === this.item.Die.Width
    );
  }
  get isDieInStockDisplay() {
    if (this.isDieInStock === false) return 'newtoolrequired.svg';
    else if (this.isDieInStock === true) return 'instock.svg';
    return '';
  }
  get getDieShape() {
    switch (this.item.Die.Shape) {
      case 'Rectangle':
        return 0;
      case 'Square':
        return 1;
      case 'Circle':
        return 2;
      case 'Oval':
        return 3;
      default:
        return 0;
    }
  }
  handleQuantityInput(newValue: any) {
    this.quantity = parseInt(newValue.toString().replace(/\D/g, ''));
  }
  get formattedQuantity() {
    return StringHelper.formatQuantity(this.quantity);
  }
  /* Created */
  async created() {}
  /* Mounted */
  async mounted() {
    this.item = new Item({
      Die: new Die({
        Shape: 'Rectangle or Square'
      }),
      Finish: new Finish({
        Id: 2,
        BadgeText: 'Gloss Finish'
      }),
      Material: new Material({
        Id: 2,
        BadgeText: 'White Paper'
      }),
      Color: this.colorOptions[1]
    });
    this.shouldUseVarData = this.isTransparencyCustomer;
  }
}
