
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '@/components/DefaultLayout.vue';
import AdminService from '@/services/admin-service';
import AuthService from '@/services/auth-service';
import { AdminUser } from '@/store/models/ProfileModel';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { Confirm } from '@/helpers/AuthHelper';

@Component({
  components: { DefaultLayout }
})
export default class extends Vue {
  refs!: {
    DefaultActions: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  showPassword: boolean = true;
  search: string = '';
  users: any[] = [];
  headers: any[] = [
    {
      text: 'User Name',
      align: 'start',
      sortable: true,
      value: 'UserName'
    },
    {
      text: 'Customer',
      align: 'start',
      sortable: false,
      value: 'CustomerName'
    },
    {
      text: 'First Name',
      align: 'start',
      sortable: false,
      value: 'FirstName'
    },
    {
      text: 'Last Name',
      align: 'start',
      sortable: false,
      value: 'LastName'
    },
    {
      text: 'Last Login Date',
      align: 'start',
      sortable: false,
      value: 'LastLoginDate'
    },
    {
      text: 'Options',
      align: 'start',
      sortable: false,
      value: 'Options'
    }
  ];
  defaultUser: AdminUser = new AdminUser(null);
  shouldShowNewUserDialog: boolean = false;
  customers: any[] = [];
  isLoadingCustomers: boolean = false;
  isLoadingUsers: boolean = false;
  isTransparencyUser: boolean = false;
  shouldSendConfirmationEmail: boolean = false;
  shouldSendWelcomeEmailWithUandP: boolean = false;
  hasAnIdentityAccount: boolean = false;
  originalLockoutValue: boolean = false;
  isEdit: boolean = false;
  dialog: boolean = false;
  changeUserNameDialog: boolean = false;
  oldUserName!: string;
  newUserName!: string;

  /* Utility Functions */
  async editUser(userInfo: any) {
    this.isEdit = true;
    this.defaultUser = new AdminUser(userInfo);
    this.shouldShowNewUserDialog = true;
    try {
      const hasAnIdentityReturn = await AuthService.hasAnIdentityAccount(
        this.defaultUser.UserName
      );
      this.hasAnIdentityAccount = hasAnIdentityReturn.data;
      if (this.hasAnIdentityAccount) {
        const isLockedoutReturn = await AuthService.isUserLockedout(
          this.defaultUser.UserName
        );
        this.defaultUser.UserLockedOutStatus = isLockedoutReturn.data.isUserLockedOut
          ? 1
          : 0;
        this.originalLockoutValue = isLockedoutReturn.data.isUserLockedOut;
      }
    } catch (err) {
      SetSnackBar('Something horrible has happended. Cthulhu is coming for you!');
    }
    //document.getElementById('addbtn')?.click();
    this.dialog = true;
  }
  async setLockout(userInfo: any) {
    try {
      const response = await AuthService.lockoutUser(userInfo.UserName);
      SetSnackBar('This user account has been locked out.');
    } catch (err) {
      SetSnackBar(
        'A most terrible thing has happenend.  Tell your family you love them before the end.'
      );
    }
  }
  closeDialog() {
    this.defaultUser = new AdminUser(null);
    this.dialog = false;
    this.isEdit = false;
  }
  addUser() {
    this.isEdit = false;
  }
  async saveUser() {
    try {
      if (this.isEdit) {
        // just check the lockout switch for now.
        // if (this.originalLockoutValue != this.defaultUser.UserLockedOutStatus) {
        //   if (this.defaultUser.IsUserLockedOut) {
        //     const lockoutReturn = await AuthService.lockoutUser(
        //       this.defaultUser.UserName
        //     );
        //   } else {
        //     const unlockReturn = await AuthService.unlockUser(this.defaultUser.UserName);
        //   }
        // }
        if (
          !this.hasAnIdentityAccount &&
          this.defaultUser.ShouldOnlyCreateIdentityAccount
        ) {
          let formData = new FormData();
          formData.append('Email', this.defaultUser.UserName);
          formData.append('Password', (Math.random() + 1).toString(36).substring(7));
          await AuthService.createUserForAdmin(formData);
          await AuthService.SendPasswordResetEmail(this.defaultUser.UserName);
        }
      } else {
        // save new user
        // first check to make sure the account does not already exist.
        const checkForExistingAccountReturn = await AuthService.hasAnIdentityAccount(
          this.defaultUser.UserName
        );
        if (checkForExistingAccountReturn.data) {
          SetSnackBar(
            'This username/email has already been used, CANNOT SAVE USER (Loser).'
          );
        } else {
          this.defaultUser.ShouldSendConfirmationEmail = this.shouldSendConfirmationEmail;
          this.defaultUser.IsATransparencyCustomer = this.isTransparencyUser;
          this.defaultUser.HasBetaAccess = !this.isTransparencyUser;
          this.defaultUser.ShouldSendPasswordEmail = this.shouldSendWelcomeEmailWithUandP;
          let formData = new FormData();
          formData.append('Email', this.defaultUser.UserName);
          formData.append('Password', this.defaultUser.Password);
          await AuthService.createUserForAdmin(formData);
          if (!this.defaultUser.ShouldOnlyCreateIdentityAccount) {
            await AdminService.CreateUser(this.defaultUser);
          }
          // good to go lets do this.
          SetSnackBar('User information has been saved.');
        }
      }
      this.closeDialog();
    } catch (err) {
      SetSnackBar(
        'User failed to save.  Its most likely your fault, you should feel bad about yourself.'
      );
    }
  }
  async unlockUser(userInfo: any) {
    try {
      const response = await AuthService.unlockUser(userInfo.UserName);
      SetSnackBar('This user account has been unlocked.');
    } catch (err) {
      SetSnackBar('The puppies are dead.');
    }
  }
  async deleteUser(userInfo: any) {
    Confirm(
      async () => {
        try {
          const response = await AdminService.DeleteUser(userInfo.UserName);
          AuthService.deleteUser(userInfo.UserName);
          SetSnackBar(
            `The user ${userInfo.UserName} has been removed from our system with extreme anger.`
          );
          this.GetUsers();
        } catch (err) {
          // Get the actual error message
          const errorMessage = err.message || 'An unknown error occurred';
          SetSnackBar(errorMessage);
        }
      },
      'Delete User',
      `Are you sure you want to delete user ${userInfo.UserName}?`
    );
  }
  async changeUserNameClick(userInfo: any) {
    this.oldUserName = userInfo.UserName;
    this.newUserName = userInfo.UserName;
    this.changeUserNameDialog = true;
  }
  closeChangeUserNameDialog() {
    this.changeUserNameDialog = false;
  }
  async changeUserName() {
    if (this.oldUserName && this.newUserName) {
      Confirm(
        async () => {
          try {
            const response = await AdminService.changeUserName(
              this.oldUserName,
              this.newUserName
            );
            AuthService.changeUserName(this.oldUserName, this.newUserName);
            SetSnackBar(
              `The username ${this.oldUserName} has been changed to ${this.newUserName}.  Let the rejoycing begin.`
            );
            this.closeChangeUserNameDialog();
            this.GetUsers();
          } catch (err) {
            // Get the actual error message
            const errorMessage =
              err.message ||
              'An unknown error occurred, looks like you wont be changing any usernames today.';
            SetSnackBar(errorMessage);
          }
        },
        'Change Username',
        `Are you sure you want to change the username ${this.oldUserName} to ${this.newUserName}?`
      );
    }
  }
  async getAllCustomers() {
    this.isLoadingCustomers = true;
    console.log('Got customers');
    const customers = await AdminService.getAllCustomers();
    this.customers = customers.data;
    this.isLoadingCustomers = false;
  }
  async GetUsers() {
    this.isLoadingUsers = true;
    const { data } = await AdminService.getUsers();
    this.users = data.data;
    this.isLoadingUsers = false;
  }
  /* Mounted */
  async mounted() {
    this.GetUsers();
    await this.getAllCustomers();
  }
  /* Created */
  /* Emmited Functions */
}
