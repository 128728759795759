
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import StripePaymentComponent from '@/components/StripePaymentComponent.vue';
import PaymentSelectCard from '@/components/cardComponents/PaymentSelectCard.vue';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { PaymentService } from '@/services/payment-service';
import { PaymentProfile } from '@/store/models/PaymentModel';
@Component({
  components: { StripePaymentComponent, PaymentSelectCard }
})
export default class extends Vue {
  $refs!: {
    StripeCard: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  isLoading: boolean = false;
  isAddingPaymentMethod: boolean = false;
  shouldShowSuccess: boolean = false;
  successData: any = null;
  /* Computed */
  /* Async Functions */
  async saveStripePaymentMethod() {
    this.isAddingPaymentMethod = true;
    try {
      await this.$refs.StripeCard.submitCard();
    } catch (error) {
      SetSnackBar(
        'There was an error adding your payment. Please contact techsupport@just1label.com or try again later'
      );
    }
    this.isAddingPaymentMethod = false;
  }
  /* Utility Functions */
  onCancelClick() {
    window.close();
  }
  /* Loaders */
  /* Mounted */
  async mounted() {
    if (this.$route.query.setup_intent) {
      this.isLoading = true;
      const { data } = await PaymentService.ConfirmSetupIntent();
      this.successData = data.map((d: any) => new PaymentProfile(d));
      this.successData.forEach(p => {
        if (p.CardType == 8 || p.CardType == -1 || !p.CardName)
          p.Avatar = require('@/assets/Payment/bank.png');
        else p.Avatar = require(`@/assets/Payment/${p.CardName}.png`);
      });
      this.shouldShowSuccess = true;
      this.isLoading = false;
    }
  }
  /* Created */
  /* Emmited Functions */
}
