import axios, { AxiosResponse } from 'axios';
import { handleResponse, handleErrorResponse, downloadFile } from '@/helpers/ApiHelper';
import { Item } from '@/store/models/ItemModel';
import { BarcodeGenModel } from '@/store/models/RequestModels';
import { TransparencyItemModel } from '@/store/models/TransparencyItemModel';

const itemUrl = `${process.env.VUE_APP_ROOT_API}/Item`;

export const ItemService = {
  AutoGenStaticPart(): Promise<AxiosResponse> {
    return axios.get(`${itemUrl}/AutoGenStaticPart`);
  },
  DispatchGenerate1Up(itemId: string, brandId: number): Promise<AxiosResponse> {
    return axios.get(
      `${itemUrl}/DispatchGenerate1Up?itemId=${itemId}&brandId=${brandId}`
    );
  },
  async DownloadImage(fileName: string) {
    const response = await axios({
      url: `${itemUrl}/DownloadImage?fileName=${fileName}`,
      method: 'GET',
      responseType: 'arraybuffer'
    });
    const filename = 'hirezPreview.jpg';
    if (response.data) {
      downloadFile(new Blob([response.data]), filename);
    }
  },
  DownloadItemFileToCache(itemId: string, version: number): Promise<AxiosResponse> {
    return axios.get(
      `${itemUrl}/DownloadItemFileToCache?itemId=${itemId}&version=${version}`
    );
  },
  ExportItemsForOrder(customerId?: string) {
    return axios.get(`${itemUrl}/GetExportItems?customerId=${customerId}`, {
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'application/json' }
    });
  },
  HasBrandLogoBeenProcessed(brandId: number, fileName: string): Promise<AxiosResponse> {
    return axios.get(
      `${itemUrl}/HasBrandLogoBeenProcessed?brandId=${brandId}&fileName=${fileName}`
    );
  },
  GetBarcodePlacement(itemId: string, placementId: number): Promise<AxiosResponse> {
    return axios.get(
      `${itemUrl}/GetBarcodePlacement?itemId=${itemId}&placementId=${placementId}`
    );
  },
  GetBrandLogo(brandId: number): Promise<AxiosResponse> {
    return axios.get(`${itemUrl}/GetBrandLogo?brandId=${brandId}`);
  },
  getStaticItems() {
    return axios.get(`${itemUrl}/GetStaticItems`);
  },
  GetItemCountForCustomer(customerId: string) {
    return axios.get(`${itemUrl}/GetItemCountForCustomer?customerId=${customerId}`);
  },
  GetItemThumbnail(
    fixedWidth: number,
    fixedHeight: number,
    itemId: string,
    version: number
  ): Promise<AxiosResponse> {
    return axios.get(
      `${itemUrl}/GetItemFileThumbNailFixedDim/${fixedWidth}/${fixedHeight}/${itemId}/${version}`
    );
  },
  GetItemHighRezGraphicAsStream(itemId: string, version: number): Promise<AxiosResponse> {
    return axios.get(`${itemUrl}/GetItemHighRezGraphicAsStream/${itemId}/${version}`);
  },
  GetItems(model: any = null): Promise<Item[]> {
    return new Promise(async (resolve, reject) => {
      if (!model) {
        model = {
          IsDescending: true,
          OrderBy: 0,
          SearchValue: '',
          Start: 0,
          Take: 30
        };
      }
      try {
        const response = await axios.post(`${itemUrl}/GetItems`, model);
        handleResponse(response);
        let returnValue = response.data.allItems.map((r: any) => (r = new Item(r)));
        return resolve(returnValue);
      } catch (error) {
        return reject(error);
      }
    });
  },
  GetItemDetails(itemId: string): Promise<AxiosResponse> {
    return axios.get(`${itemUrl}/GetItem/${itemId}`);
  },
  PlaceItemOnHold(itemId: string): Promise<AxiosResponse> {
    return axios.get(`${itemUrl}/PutOnHold/${itemId}`);
  },
  DeleteItem(itemId: string): Promise<AxiosResponse> {
    return axios.get(`${itemUrl}/DeleteItem/${itemId}`);
  },
  GetItemTypesForItemEdit(): Promise<AxiosResponse> {
    return axios.get(`${itemUrl}/GetItemTypesForItemEdit`);
  },
  async SaveItem(model: Object): Promise<AxiosResponse> {
    return axios.post(`${itemUrl}/SaveItem`, model);
  },
  async SaveStaticItem(model: Object): Promise<AxiosResponse> {
    return axios.post(`${itemUrl}/SaveStaticItem`, model);
  },
  GetNewPartNumber(customerId: string) {
    return axios.get(`${itemUrl}/GetNewPartNumber/${customerId}`);
  },
  async GetStaticItems(): Promise<AxiosResponse> {
    return axios.get(`${itemUrl}/GetStaticItems`);
  },
  async getOriginalArtwork(itemId: string, filename: string) {
    const response = await axios({
      url: `${itemUrl}/GetOriginalArtwork/${itemId}`,
      method: 'GET',
      responseType: 'blob'
    });
    let headers = response.headers;
    let blob = new Blob([response.data], { type: headers['content-type'] });
    const fileName = filename;
    if (response.data) downloadFile(response.data, fileName);
  },
  async getOriginalArtworkAsPdf(itemId: string, partNumber: string) {
    const response = await axios({
      url: `${itemUrl}/GetOriginalArtworkAsPdf/${itemId}`,
      method: 'GET',
      responseType: 'arraybuffer'
    });
    const fileName = `${partNumber}.pdf`;
    if (response.data) downloadFile(new Blob([response.data]), fileName);
  },
  async GetDigitalProof(fileId: string, filename: string) {
    const response = await axios({
      url: `${itemUrl}/GetDigitalProof?supportingFileId=${fileId}`,
      method: 'GET',
      responseType: 'arraybuffer'
    });
    const fileName = filename;
    if (response.data) downloadFile(new Blob([response.data]), fileName);
  },
  UploadItemFile(formData: FormData, config: any = null) {
    return axios.post(`${itemUrl}/UploadItemFile`, formData, config);
  },
  ChangePartNumber(itemId: string, newPartNumber: string, shouldOverride: boolean) {
    return axios.get(
      `${itemUrl}/ChangePartNumber/${itemId}/${newPartNumber}/${shouldOverride}`
    );
  },
  HasItemBeenProcessed(itemId: string) {
    return axios.get(`${itemUrl}/HasItemBeenProcessed/${itemId}`);
  },
  HasHighRezImageBeenProcessed(itemId: string) {
    return axios.get(`${itemUrl}/HasHighRezImageBeenProcessed/${itemId}`);
  },
  UpdatePreviewGenProof(itemId: string) {
    return axios.get(`${itemUrl}/UpdatePreviewGenProof/${itemId}`);
  },
  RefreshItem(itemId: string) {
    return axios.get(`${itemUrl}/RefreshItem/${itemId}`);
  },
  RegenerateFileGraphics(itemId: string) {
    return axios.get(`${itemUrl}/RegenerateFileGraphics/${itemId}`);
  },
  RebuildHighRezImage(itemId: string) {
    return axios.get(`${itemUrl}/RebuildHighRezImage/${itemId}`);
  },
  ApproveItemForPrinting(itemId: string) {
    return axios.get(`${itemUrl}/ApproveItemForPrinting/${itemId}`);
  },
  GenerateBarcode1Up(itemId: string, placementId: number) {
    return axios.get(
      `${itemUrl}/GenerateBarcode1Up?itemId=${itemId}&placementId=${placementId}`
    );
  },
  GetItemHistory(itemId: string) {
    return axios.get(`${itemUrl}/GetItemHistory/${itemId}`);
  },
  GetCustomerPartNumbers() {
    return axios.get(`${itemUrl}/GetAllCustomerItemPartNumbers`);
  },
  GetAllOnOrderCustomerItemPartNumbers() {
    return axios.get(`${itemUrl}/GetAllOnOrderCustomerItemPartNumbers`);
  },
  GetAllOnHoldCustomerItemPartNumbers() {
    return axios.get(`${itemUrl}/GetAllOnHoldCustomerItemPartNumbers`);
  },
  GetAllSignOffCustomerItemPartNumbers() {
    return axios.get(`${itemUrl}/GetAllSignOffCustomerItemPartNumbers`);
  },
  GetItemFilterCount() {
    return axios.get(`${itemUrl}/GetItemFilterCount`);
  },
  GetGenerated1Up(fileName: string) {
    return axios.get(`${itemUrl}/GetGenerated1Up?fileName=${fileName}`);
  },
  GetItemsNoPagination(Ids: string[], CurrentPage: number = 1) {
    return axios.post(`${itemUrl}/GetItemsNoPagination`, {
      Ids,
      CurrentPage
    });
  },
  IsDuplicatePartNumber(partNumber: string) {
    return axios.get(`${itemUrl}/IsDuplicatePartNumber?partNumber=${partNumber}`);
  },
  GiveCachedImageFilesVersions() {
    return axios.get(`${itemUrl}/GiveCachedImageFilesVersions`);
  },
  GetSupportingFileRaw(itemId: string) {
    return axios.get(`${itemUrl}/GetSupportingFileRaw?itemId=${itemId}`);
  },
  StartDigitalProof(itemId: string) {
    return axios.get(`${itemUrl}/StartDigitalProof/${itemId}`);
  },
  GetDieLines(itemId: string, orderlineId?: string) {
    let url = `${itemUrl}/GetDieLines/${itemId}`;
    if (orderlineId) {
      url += `?orderlineId=${orderlineId}`;
    }
    return axios.get(url);
  },
  RequestPhysicalProof(model: Object) {
    return axios.post(`${itemUrl}/RequestPhysicalProof`, model);
  },
  GetItemsByPartNumberOrDescriptionForPagination(model: any): Promise<AxiosResponse> {
    return axios.post(`${itemUrl}/GetItemsByPartNumberOrDescriptionForPagination`, model);
  },
  GetItemsByPOAndCustId(model: any): Promise<AxiosResponse> {
    return axios.post(`${itemUrl}/GetItemsByPOAndCustId`, model);
  },
  GetItemsBySearchQuery(model: any): Promise<AxiosResponse> {
    return axios.post(`${itemUrl}/GetItemsBySearchQuery`, model);
  },
  SaveExistingTransparencyItem(model: TransparencyItemModel): Promise<AxiosResponse> {
    return axios.post(`${itemUrl}/SaveExistingTransparencyItem`, model);
  },
  SaveNewTransparencyItem(model: TransparencyItemModel): Promise<AxiosResponse> {
    return axios.post(`${itemUrl}/SaveTransparencyItem`, model);
  },
  SaveBarcodePosition(model: BarcodeGenModel) {
    return axios.post(`${itemUrl}/SaveBarcodePosition`, model);
  },
  ShouldGenerateRetention(itemId: string) {
    return axios.get(`${itemUrl}/ShouldGenerateRetention?itemId=${itemId}`);
  }
};

export default ItemService;
