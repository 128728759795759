import axios, { AxiosResponse } from 'axios';
import { handleResponse, handleErrorResponse, downloadFile } from '@/helpers/ApiHelper';
import { Orderline } from '@/store/models/OrderlineModel';
import { PaginationModel } from '@/store/models/PaginationModel';
import {
  CalcOrderlineModel,
  RequestedRollSpecificationModel
} from '@/store/models/RequestModels';
import { RequestedOrderlineMetricTypes } from '@/ConstValues';

const ordersUrl = `${process.env.VUE_APP_ROOT_API}/Orders`;

export const OrdersService = {
  AddToCart(model: CalcOrderlineModel): Promise<AxiosResponse> {
    return axios.post(`${ordersUrl}/AddToCart`, model);
  },
  async GenerateCertificateOfConformance(
    orderlineId: string,
    partNumber: string
  ): Promise<AxiosResponse> {
    const response = await axios({
      url: `${ordersUrl}/GenerateCertificateOfConformance?orderLineId=${orderlineId}`,
      method: 'GET',
      responseType: 'arraybuffer'
    });
    const filename = `COC - ${partNumber}.pdf`;
    if (response.data) {
      downloadFile(new Blob([response.data]), filename, false);
    }
    return axios.get(`${ordersUrl}/GenerateCertificateOfConformance`);
  },
  GetAllCustomerOrderPOs(): Promise<AxiosResponse> {
    return axios.get(`${ordersUrl}/GetAllCustomerOrderPOs`);
  },
  GetOrderlineFilterCount(): Promise<AxiosResponse> {
    return axios.get(`${ordersUrl}/GetOrderlineFilterCount`);
  },
  GetOrderlinesByPO(model: any): Promise<AxiosResponse> {
    return axios.post(`${ordersUrl}/GetOrderlinesByPOAndCustId`, model);
  },
  GetOrderlinesByItemId(itemId: string) {
    return axios.get(`${ordersUrl}/GetOrderLinesByItemId/${itemId}`);
  },
  GetFullOrderlinesByItemId(itemId: string) {
    return axios.get(`${ordersUrl}/GetFullOrderLinesByItemId/${itemId}`);
  },
  GetCustomerOrderByOrderLineId(orderlineId: string) {
    return axios.get(
      `${ordersUrl}/GetCustomerOrderByOrderLineId?orderlineId=${orderlineId}`
    );
  },
  GetOrderFull(
    po: string,
    rushCheck: number = 0,
    isCartOrder: boolean = false
  ): Promise<AxiosResponse> {
    return axios.get(`${ordersUrl}/GetOrderFull?po=${po}&isCartOrder=${isCartOrder}`);
  },
  GenerateOrderlineDefaults(model: any): Promise<AxiosResponse> {
    return axios.post(`${ordersUrl}/GenerateNewOrderline`, model);
  },
  RecreateOrder(orderlineModels: any): Promise<AxiosResponse> {
    return axios.post(`${ordersUrl}/RecreateOrder`, orderlineModels);
  },
  CalculatePricingData(model: any): Promise<AxiosResponse> {
    return axios.post(`${ordersUrl}/CalculatePricingData`, model);
  },
  ChangeOrderlineShippingAddress(model: {
    Id: string;
    ShippingAddressId: string;
    RollDirectionId: number;
    ShouldChangeAllShippingAddress: boolean;
  }) {
    return axios.post(`${ordersUrl}/ChangeOrderlineShippingAddress`, model);
  },
  ChangeRollSpecifications(model: {
    Id: string;
    RequestedOrderlineMetricType: RequestedOrderlineMetricTypes;
    RequestedRollQuantity: number;
    RequestedMaxLabelQuantity: number;
    PO: string;
  }) {
    return axios.post(`${ordersUrl}/ChangeRollSpecifications`, model);
  },
  ChangeOrderlineQuantity(model: {
    Id: string;
    Quantity: number;
    PO: string;
    IsCartOrder: boolean;
  }) {
    return axios.post(`${ordersUrl}/ChangeOrderlineQuantity`, model);
  },
  ChangeOrderPO(OldPO: string, NewPO: string, IsCartOrder: boolean) {
    let model = {
      OldPO: OldPO,
      NewPO: NewPO,
      IsCartOrder: IsCartOrder
    };
    return axios.post(`${ordersUrl}/ChangeOrderPO`, model);
  },
  GetPaymentMethodUsedOnOrder(orderId: string) {
    return axios.get(`${ordersUrl}/GetPaymentMethodUsedOnOrder/${orderId}`);
  },
  RefreshOrderline(orderlineId: string) {
    return axios.get(`${ordersUrl}/RefreshOrderline/${orderlineId}`);
  },
  RushOrderline(model: {
    Id: string;
    RushOptionId: string;
    ShippingDeliveryOptionId: number;
    AdditionalOrderlineIdsToRush: number[];
  }) {
    return axios.post(`${ordersUrl}/RushOrderline`, model);
  },
  SendOrderConfirmation(orderId: string, emailAddress: string) {
    return axios.get(
      `${ordersUrl}/SendOrderConfirmation?orderId=${orderId}&emailAddress=${emailAddress}`
    );
  },
  DeleteOrderline(model: { Id: string; HasCompletedRequest: boolean }) {
    return axios.post(`${ordersUrl}/DeleteOrderline`, model);
  },
  PlaceOrder(model: any): Promise<AxiosResponse> {
    return axios.post(`${ordersUrl}/PlaceOrder`, model);
  },
  SaveOrder(model: any): Promise<AxiosResponse> {
    return axios.post(`${ordersUrl}/SaveCustomerOrder`, model);
  },
  CancelOrder(po: string, isCartOrder: boolean): Promise<AxiosResponse> {
    return axios.get(
      `${ordersUrl}/CancelFullOpenOrder?po=${po}&isCartOrder=${isCartOrder}`
    );
  },
  GetCustomerOrderPO(): Promise<AxiosResponse> {
    return axios.get(`${ordersUrl}/GetOrderlinePosForPagination?maxCountShown=10`);
  },
  GetCustomerOrderlinesOpenInvoice(): Promise<AxiosResponse> {
    return axios.get(`${ordersUrl}/GetCustomerOrderlinesOpenInvoice`);
  },
  GetOrderlinesInCart(): Promise<AxiosResponse> {
    return axios.get(`${ordersUrl}/GetOrderlinesInCart`);
  },
  GetOrderlinesThatAreOnHold(): Promise<AxiosResponse> {
    return axios.get(`${ordersUrl}/GetOrderlinesThatAreOnHold `);
  },
  GetOrderlinesRecentlyShipped(): Promise<AxiosResponse> {
    return axios.get(`${ordersUrl}/GetOrderlinesRecentlyShipped `);
  },
  GetOrderlineProofs(): Promise<AxiosResponse> {
    return axios.get(`${ordersUrl}/GetOrderlineProofs `);
  },
  GetOrderlinesPending(): Promise<AxiosResponse> {
    return axios.get(`${ordersUrl}/GetOrderlinesPending `);
  },
  GetOrderlineSearchContent(): Promise<AxiosResponse> {
    //not being used 1
    return axios.get(`${ordersUrl}/GetOrderlineSearchContent`);
  },
  GetAllOrderPoByCustomerId(): Promise<AxiosResponse> {
    return axios.get(`${ordersUrl}/GetAllOrderPoByCustomerId`);
  },
  GetPacklistByPo(po: string): Promise<AxiosResponse> {
    return axios.get(`${ordersUrl}/GetPackingListByPo?po=${po} `);
  },
  GetPoByOrderId(orderId: string): Promise<AxiosResponse> {
    return axios.get(`${ordersUrl}/GetPoByOrderId?orderId=${orderId} `);
  },
  GetProofPrices(): Promise<AxiosResponse> {
    return axios.get(`${ordersUrl}/GetProofPrices`);
  },
  GetOrderlinesByPartNumberOrDescriptionForPagination(
    model: any
  ): Promise<AxiosResponse> {
    return axios.post(
      `${ordersUrl}/GetOrderlinesByPartNumberOrDescriptionForPagination`,
      model
    );
  },
  GetOrderlinesBySearchQuery(model: any): Promise<AxiosResponse> {
    return axios.post(`${ordersUrl}/GetOrderlinesBySearchQuery`, model);
  },
  GetAutocompleteSuggestionsBySearchQuery(model: any): Promise<AxiosResponse> {
    return axios.post(`${ordersUrl}/GetAutocompleteSuggestionsBySearchQuery`, model);
  },
  GetOrderByPO(po: string) {
    return axios.get(`${ordersUrl}/GetOrderByPO?po=${po}`);
  },
  GetRushFeeValues(model: any) {
    return axios.post(`${ordersUrl}/GetRushFeeValues`, model);
  },
  CalculateExpectedRollSpecifications(model: any) {
    return axios.post(`${ordersUrl}/CalculateExpectedRollSpecifications`, model);
  },
  GetGeneratedMetricData(model: any) {
    return axios.post(`${ordersUrl}/GetGeneratedMetricData`, model);
  },
  CalculateProofPrice(quantity: number, hasVarnish: boolean) {
    return axios.get(`${ordersUrl}/CalculateProofPrice/${quantity}/${hasVarnish}`);
  },
  GetCustomerOrdersByItemId(itemId: string) {
    return axios.get(`${ordersUrl}/GetCustomerOrdersByItemId/${itemId}`);
  },
  CreateDefaultPo() {
    return axios.get(`${ordersUrl}/CreateDefaultPo`);
  },
  GetTrackingInformation(orderlineId: string) {
    return axios.get(`${ordersUrl}/GetTrackingInformation/${orderlineId}`);
  },
  async GeneratePackinglistReport(plnumber: string) {
    const response = await axios.get(
      `${ordersUrl}/GeneratePackinglistReport?plnumber=${plnumber}`
    );
    if (response.data) window.open(response.data);
  },
  async DownloadPacklist(plnumber: string) {
    const response = await axios({
      url: `${ordersUrl}/DownloadPacklist?plnumber=${plnumber}`,
      method: 'GET',
      responseType: 'arraybuffer'
    });
    const filename = `${plnumber}.pdf`;
    if (response.data) {
      downloadFile(new Blob([response.data]), filename);
    }
  },
  GetAllOrderlineByShippingIdAndStatus(shippingAddressId: string) {
    return axios.get(
      `${ordersUrl}/GetAllOrderlineByShippingIdAndStatus?shippingAddressId=${shippingAddressId}`
    );
  },
  RefreshCustomerOrder(orderId: string) {
    return axios.get(`${ordersUrl}/RefreshCustomerOrder?orderId=${orderId}`);
  },
  SaveOrderlines(model: any) {
    return axios.post(`${ordersUrl}/SaveOrderlines`, model);
  },
  GetSavedOrders() {
    return axios.get(`${ordersUrl}/GetSavedOrders`);
  },
  GetPacklistByOrderlineId(orderlineId: string) {
    return axios.get(
      `${ordersUrl}/GetPackingListByOrderlineId?orderlineId=${orderlineId}`
    );
  },
  IsCustomerAtTermLimit() {
    return axios.get(`${ordersUrl}/IsCustomerAtTermLimit`);
  },
  GetPastDueOrders() {
    return axios.get(`${ordersUrl}/GetPastDueOrders`);
  }
  // GetOrderlinesByPoSearch(q: string): Promise<AxiosResponse> {
  //   return axios.get(`${ordersUrl}/GetOrderlinesByPoSearch/${q}`);
  // }
};
