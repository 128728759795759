
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter, Mutation } from 'vuex-class';
import DefaultLayout from '@/components/DefaultLayout.vue';
import J1LPagination from '@/components/J1LPagination.vue';
import { GetParentElementByClassName } from '@/helpers/HtmlHelper';
import { OrdersService } from '@/services/orders-service';
import {
  Orderline,
  SearchRequestModel,
  OrderlineGroup,
  RushOption,
  OrderStatus,
  OrderLineTypes
} from '@/store/models/OrderlineModel';
import { BuildOrderlineArrayForDisplay } from '@/Builders/builders';
import OrderActions from '@/components/OrderActions.vue';
import OrderlineCard from '@/components/cardComponents/OrderlineCard.vue';
import J1lSearchBox from '@/components/J1lSearchBox.vue';
import ItemDialog from '@/components/ItemDialog.vue';
import OrderInvoiceDialog from '@/components/OrderInvoiceDialog.vue';
import OrderPacklistDialog from '@/components/OrderPacklistDialog.vue';
import OrderReorder from '@/components/OrderReorder.vue';
import NoItemOrder from '@/components/NoItemOrder.vue';
import UploadVarDataDialog from '@/components/UploadVarDataDialog.vue';
import { StringHelper } from '@/helpers/StringHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { areListsDifferent } from '@/helpers/ObjectHelper';
import { SearchHelper } from '@/helpers/SearchHelper';
import { CodeRequestModel } from '@/store/models/RequestModels/CodeRequestModel';

@Component({
  components: {
    DefaultLayout,
    J1LPagination,
    OrderActions,
    OrderlineCard,
    J1lSearchBox,
    ItemDialog,
    OrderInvoiceDialog,
    OrderPacklistDialog,
    OrderReorder,
    NoItemOrder,
    UploadVarDataDialog
  }
})
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
    searchBox: HTMLFormElement;
    ItemDialog: HTMLFormElement;
    OrderInvoiceDialog: HTMLFormElement;
    OrderPacklistDialog: HTMLFormElement;
    OrderReorderDialog: HTMLFormElement;
    autocomplete: HTMLFormElement;
    UploadVarDataDialog: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  @Action('GetSearchedOrderlinesByEntity', { namespace: 'orderlines' })
  getSearchedOrderlines: any;
  @Action('GetAllOrderPoByCustomerId', { namespace: 'orderlines' })
  getOrderSearchContent!: any;
  @Action('ClearCurrentOrder', { namespace: 'orderlines' })
  clearCurrentOrder!: any;
  @Action('refreshOrderline', { namespace: 'orderlines' })
  refreshOrderline!: any;
  @Action('GetOrderlinesThatAreOnHold', { namespace: 'orderlines' })
  getOnHoldOrderlines!: any;
  @Action('GetOrderlinesRecentlyShipped', { namespace: 'orderlines' })
  getOrderlinesRecentlyShipped!: any;
  @Action('GetOrderlineProofs', { namespace: 'orderlines' })
  getOrderlineProofs!: any;
  @Action('GetOrderlinesPending', { namespace: 'orderlines' })
  getOrderlinesPending!: any;
  @Action('GetOrderlinesWithInvoice', { namespace: 'orderlines' })
  getOrderlinesWithInvoices!: any;
  @Action('refreshMultipleOrderlines', { namespace: 'orderlines' })
  refreshMultipleOrderlines!: any;
  @Getter('isTransparencyCustomer', { namespace: 'customer' })
  isTransparencyCustomer: boolean;
  @Getter('getLoadingState', { namespace: 'orderlines' })
  areOrderlinesLoading: any;
  @Getter('getUserValidation', { namespace: 'profile' })
  userValidation!: any;
  @Getter('orderlines', { namespace: 'orderlines' })
  orderlines!: Orderline[];
  @Getter('getSearchedOrderlines', { namespace: 'orderlines' })
  searchedOrderlines: any;
  @Getter('onHoldOrderlines', { namespace: 'orderlines' })
  onHoldOrderlines: any;
  @Getter('recentlyShippedOrderlines', { namespace: 'orderlines' })
  recentlyShippedOrderlines: any;
  @Getter('orderlineProofs', { namespace: 'orderlines' })
  orderlineProofs: any;
  @Getter('pendingOrderlines', { namespace: 'orderlines' })
  pendingOrderlines: any;
  @Getter('orderlinesWithInvoice', { namespace: 'orderlines' })
  invoicedOrderlines: any;
  @Mutation('orderlinesLoaded', { namespace: 'orderlines' })
  setOrderlines!: any;
  @Getter('hasOrderlines', { namespace: 'orderlines' })
  hasOrderlines!: boolean;

  $router: any;
  @Watch('orderlines', { deep: true })
  onOrderlinesChanged(val: Orderline[], oldVal: Orderline[]) {
    if (val.length == 0 && oldVal.length > 0) {
      this.OrderlinesForDisplay = [];
    }
    if (
      (this.orderlines[0] &&
        this.searchedOrderlines.length == 0 &&
        !this.selectedSuggestion) ||
      (this.OrderlinesForDisplay?.length == 0 && this.searchedOrderlines.length > 0)
    ) {
      this.showAllOrderlines();
    }
  }
  @Watch('searchedOrderlines')
  onSearchedOrderlines(val: any, oldVal: any) {
    if (val[0]) {
      if (
        val[0] != -1 &&
        areListsDifferent(
          [val],
          this.OrderlinesForDisplay.map(o => o.Orderlines)
        )
      ) {
        this.handleChipSelection(0);
        this.activeChip = 0;
        this.OrderlinesForDisplay = BuildOrderlineArrayForDisplay(val);
        this.searchLoading = false;
      } else {
        this.shouldLoadMoreOrderlines = false;
        this.searchLoading = false;
      }
    } else {
      this.shouldLoadMoreOrderlines = !oldVal[0];
    }
  }
  @Watch('searchText')
  onSearchTextChange(val: string) {
    val && val !== this.selectedSuggestion && this.getSuggestions();
  }
  @Watch('selectedSuggestion')
  onSelectedSuggestionChange(val: any) {
    if (!val) {
      this.shouldLoadMoreOrderlines = true;
    }
    this.searchBySearchSuggestion(val);
  }

  /* Data */

  itemsSearch: any[] = [
    {
      headline: ['PartNumber', 'Description'],
      title: 'Items'
    },
    {
      headline: [],
      title: 'Order'
    }
  ];
  currentFilter: any = {
    id: 0,
    text: 'Everything',
    count: 0,
    isSelected: true,
    isLoading: false,
    shouldShow: true
  };
  filterOptions: Array<{
    id: number;
    text: string;
    count: number;
    isSelected: boolean;
    isLoading: boolean;
    shouldShow: boolean;
  }> = [
    {
      id: 0,
      text: 'Everything',
      count: 0,
      isSelected: true,
      isLoading: false,
      shouldShow: true
    },
    {
      id: 1,
      text: 'On Hold',
      count: 0,
      isSelected: false,
      isLoading: false,
      shouldShow: true
    },
    {
      id: 2,
      text: 'Recently Shipped',
      count: 0,
      isSelected: false,
      isLoading: false,
      shouldShow: true
    },
    {
      id: 3,
      text: 'Sign-Offs',
      count: 0,
      isSelected: false,
      isLoading: false,
      shouldShow: true
    },
    {
      id: 4,
      text: 'Saved For Later',
      count: 0,
      isSelected: false,
      isLoading: false,
      shouldShow: true
    },
    {
      id: 5,
      text: 'Invoiced',
      count: 0,
      isSelected: false,
      isLoading: false,
      shouldShow: true
    }
  ];
  searchText: string = 'PartNumber';
  filteredOrderlines: any[] = [];
  OrderlinesForDisplay: OrderlineGroup[] = [];
  searchModel: SearchRequestModel | null = null;
  stopPagination: boolean = false;
  shouldLoadMoreOrderlines: boolean = true;
  searchLoading: boolean = false;
  areChipsGrabbingData: boolean = false;
  activeChip: number = 0;
  orderlineGroupForInvoicing: OrderlineGroup | null = null;
  orderlineGroupForPacklist: OrderlineGroup | null = null;
  orderConfirmation: string[] = [];
  suggestions: any[] = [];
  selectedSuggestion: any = null;
  isLoading: boolean = false;
  isLoadingInitialSuggestions: boolean = false;
  typingTimer: any;
  doneTypingInterval: number = 750;
  newItemActions: any[] = [
    { title: 'New Transparency Item', icon: 'mdi-plus' },
    { title: 'New Non Transparency Item', icon: 'mdi-plus' }
  ];

  /* Methods */
  refreshOrderlineValue(orderline: Orderline, successMsg: string) {
    if (orderline) {
      this.refreshOrderlineAndState(orderline, successMsg);
    }
  }
  applyRedBorder() {
    setTimeout(() => {
      let input = document.getElementById('searchBox');
      if (input) {
        let parent = GetParentElementByClassName(input, 'v-input');
        if (parent) parent.style.border = 'red 4px solid';
        let menu = document.getElementsByClassName(
          'v-menu__content'
        )[0] as HTMLScriptElement;
        if (menu) menu.style.marginTop = '4px';
      }
    }, 100);
  }
  showAllOrderlines() {
    this.searchText = '';
    this.selectedSuggestion = null;
    this.OrderlinesForDisplay = BuildOrderlineArrayForDisplay(this.orderlines);
  }
  searchBySearchSuggestion(searchSuggestion: any) {
    if (!searchSuggestion) return;
    let searchValue = searchSuggestion;
    if (searchSuggestion.PartNumber) searchValue = searchSuggestion.PartNumber;
    if (searchSuggestion.PO) searchValue = [searchSuggestion.PO];
    let typeValue = -1;
    if (searchSuggestion.PartNumber) typeValue = 0;
    if (searchSuggestion.PO) typeValue = 1;
    if (searchSuggestion)
      this.searchBySearchValue({
        value: searchValue,
        isEnter: true,
        TypeValue: typeValue
      });
  }
  removeRedBorder() {
    let input = document.getElementById('searchBox');
    if (input) {
      let parent = GetParentElementByClassName(input, 'v-input');
      if (parent) parent.style.border = null;
    }
  }
  openUploadVarDataDialog(orderline: Orderline) {
    this.$refs.UploadVarDataDialog.openDialog();

    this.$refs.UploadVarDataDialog.setData(new CodeRequestModel(orderline));
  }
  async getSuggestions(shouldSetInitialLoading: boolean = false) {
    this.isLoading = true;
    clearTimeout(this.typingTimer);
    if (shouldSetInitialLoading) this.isLoadingInitialSuggestions = true;
    this.typingTimer = setTimeout(async () => {
      try {
        let model = {
          Take: 10,
          Skip: 0,
          Query: !this.searchText ? this.searchText : this.searchText.trim(),
          ShouldIncludeDisabledItems: true
        };
        const response = await OrdersService.GetAutocompleteSuggestionsBySearchQuery(
          model
        );
        if (this.selectedSuggestion) {
          this.suggestions = [
            this.selectedSuggestion,
            ...this.formatSuggestions(response.data)
          ];
        } else {
          this.suggestions = [];
          this.suggestions = this.formatSuggestions(response.data);
        }
      } catch (err) {
        console.log(err);
      }
      if (shouldSetInitialLoading) this.isLoadingInitialSuggestions = false;
      this.isLoading = false;
    }, this.doneTypingInterval);
  }
  formatSuggestions(suggestionList: any[]) {
    return SearchHelper.FormatSuggestions(suggestionList, this.searchText);
  }
  doesMatch(firstItem: string, secondItem: string) {
    return StringHelper.doesFirstStringContainSecond(firstItem, secondItem);
  }
  formatMatchedString(stringToChange: string) {
    return SearchHelper.FormatMatchedString(stringToChange, this.searchText);
  }
  search() {
    if (this.selectedSuggestion) return;
    if (this.searchText) this.suggestions.push(this.searchText);
    this.$refs.autocomplete.blur();
    this.selectedSuggestion = this.suggestions[this.suggestions.length - 1];
    if (!this.searchText || this.searchText.length < 3) {
      this.currentFilter = this.filterOptions[0];
      this.clearSearch();
    } else {
      this.refreshPagination();
    }
  }
  searchByPartNumber(partnumber: string) {
    this.searchText = partnumber;
    this.search();
  }
  async handleChipSelection(chipId: number) {
    if (chipId != 0 && this.searchModel) {
      this.clearSearch();
    }
    this.currentFilter = this.filterOptions[chipId];
    this.areChipsGrabbingData = true;
    this.searchLoading = true;
    this.stopPagination = chipId > 0;
    this.OrderlinesForDisplay = [];
    switch (chipId) {
      case 0: {
        this.OrderlinesForDisplay = BuildOrderlineArrayForDisplay(this.orderlines);
        break;
      }
      case 1: {
        await this.getOrderlinesOnHold();
        this.OrderlinesForDisplay = BuildOrderlineArrayForDisplay(this.onHoldOrderlines);
        break;
      }
      case 2: {
        await this.getOrderlinesRecentlyShipped();
        this.OrderlinesForDisplay = BuildOrderlineArrayForDisplay(
          this.recentlyShippedOrderlines
        );
        break;
      }
      case 3: {
        await this.getSignOffs();
        this.OrderlinesForDisplay = BuildOrderlineArrayForDisplay(this.orderlineProofs);
        break;
      }
      case 4: {
        await this.getPendingOrderlines();
        this.OrderlinesForDisplay = BuildOrderlineArrayForDisplay(this.pendingOrderlines);
        break;
      }
      case 5: {
        await this.getOrderlinesWithInvoices();
        this.OrderlinesForDisplay = BuildOrderlineArrayForDisplay(
          this.invoicedOrderlines
        );
        break;
      }
    }
    this.areChipsGrabbingData = false;
    this.searchLoading = false;
  }
  clearSearch() {
    this.suggestions = this.suggestions.filter(s => s !== this.selectedSuggestion);
    this.selectedSuggestion = null;
    this.$route.params.partnumber = '';
    this.$refs.autocomplete.reset();
    this.searchBySearchValue(null);
  }
  async getFilteredOrderlines(response: any) {
    this.$store.commit('orderlines/setCurrentPage', 1);
    this.refreshPagination();
    this.$store.dispatch('orderlines/ClearSearchOrder');
    this.currentFilter = this.filterOptions[0];
    this.searchModel = new SearchRequestModel({
      SearchValue: response.value[0] ? response.value : response.value.trim(),
      TypeValue: response.TypeValue
    });
  }

  clearFilteredOrderlines() {
    this.$store.dispatch('orderlines/ClearSearchOrder');
    this.OrderlinesForDisplay = BuildOrderlineArrayForDisplay(this.orderlines);
    this.searchModel = null;
  }
  handleNewItemActionEvent(action: any) {
    switch (action) {
      case 'New Non Transparency Item':
        this.$refs.ItemDialog.openDialog();
        break;
      case 'New Transparency Item':
        this.$router.push({ name: 'AddEditTransparencyItem' });
        break;
    }
  }

  refreshPagination() {
    this.stopPagination = true;
    setTimeout(() => {
      this.OrderlinesForDisplay = [];
      this.stopPagination = false;
      this.searchLoading = true;
    }, 1);
  }
  searchBySearchValue(response: any) {
    if (response && response.value) {
      this.getFilteredOrderlines(response);
    } else {
      this.clearFilteredOrderlines();
    }
  }

  refreshFilterOptionArray() {
    this.handleChipSelection(this.activeChip);
  }
  openInvoiceDialog(orderlineGroup: any) {
    this.orderlineGroupForInvoicing = orderlineGroup;
    setTimeout(() => {
      this.$refs.OrderInvoiceDialog.openDialog();
    }, 100);
  }
  openPacklistDialog(orderlineGroup: any) {
    this.orderlineGroupForPacklist = orderlineGroup;
    setTimeout(() => {
      this.$refs.OrderPacklistDialog.openDialog();
    }, 100);
  }
  async onReorderClick(orderlineGroup: any) {
    const response = await OrdersService.CreateDefaultPo();
    let po = response.data;
    this.$router.push({
      name: 'OrderView',
      params: {
        po: po,
        orderlineId: orderlineGroup.Orderlines[0].Id,
        isReordering: true
      }
    });
  }
  getActiveOrderlineArray(): Orderline[] {
    if (this.searchModel) {
      return this.searchedOrderlines;
    } else {
      switch (this.activeChip) {
        case 0:
          return this.orderlines;
        case 1:
          return this.onHoldOrderlines;
        case 2:
          return this.recentlyShippedOrderlines;
        case 3:
          return this.orderlineProofs;
        default:
          return this.orderlines;
      }
    }
  }

  async deleteOrderline(val: any) {
    if (val) {
      let originalOrderlines = this.orderlines;
      try {
        SetSnackBar('Canceling your item...');
        var orderlines = this.orderlines.filter(ol => ol.Id !== val.Id);
        this.setOrderlines(orderlines);
        await OrdersService.DeleteOrderline({
          Id: val.Id,
          HasCompletedRequest: val.AmazonRequest.AmazonRequest.IsComplete
        });
        SetSnackBar('Line item removed from system');
      } catch (err) {
        this.setOrderlines(originalOrderlines);
        SetSnackBar(
          'There was an error deleting your order, please contact techsupport@just1label.com',
          'Refresh',
          () => location.reload(),
          -1
        );
      }
    }
  }
  async changeAddress(val: any) {
    if (val.orderId) {
      let orderline = this.getActiveOrderlineArray().find(ol => ol.Id == val.orderId);
      if (orderline) {
        orderline.Address = val.shippingAddress;
        await OrdersService.ChangeOrderlineShippingAddress({
          Id: val.orderId,
          ShippingAddressId: val.shippingAddress.Id,
          RollDirectionId: val.shippingAddress.Options.RollDirectionId,
          ShouldChangeAllShippingAddress: val.shippingAddress.ShouldChangeAllAddresses
        });
        if (val.shippingAddress.ShouldChangeAllAddresses) {
          await this.refreshAllOrderlineAndState(
            orderline,
            'All addresses have changed successfully'
          );
          this.OrderlinesForDisplay = BuildOrderlineArrayForDisplay(this.orderlines);
        } else {
          this.refreshOrderlineAndState(orderline, 'Address changed successfully');
        }
      }
    }
  }
  async changeQuantity(val: { orderId: string; quantity: number }) {
    if (val.orderId) {
      let orderline = this.getActiveOrderlineArray().find(
        (ol: Orderline) => ol.Id == val.orderId
      );
      if (orderline) {
        orderline.Quantity = val.quantity;
        try {
          await OrdersService.ChangeOrderlineQuantity({
            Id: orderline.Id,
            Quantity: orderline.Quantity,
            PO: orderline.PO,
            IsCartOrder: false
          });
          this.refreshOrderlineAndState(orderline, 'Quantity changed successfully');
        } catch (err) {
          SetSnackBar(
            'There was an error while changing your quantity, please refresh your page, or contact techincal support if error persists'
          );
          console.log(err);
        }
      }
    }
  }
  refreshOrderlineAndState(orderline: Orderline, onSuccessText?: string) {
    this.refreshOrderline(orderline.Id);
    if (this.searchModel) {
      let ol = this.orderlines.find(ol => ol.Id == orderline.Id);
      if (ol) {
        ol.Quantity = orderline.Quantity;
      }
    }
    if (onSuccessText) SetSnackBar(onSuccessText);
  }

  async refreshAllOrderlineAndState(orderline: Orderline, onSuccessText: string) {
    let orderlinesForOrder = this.orderlines.filter(
      ol => ol.CustomerOrderId == orderline.CustomerOrderId
    );
    for (let ol of orderlinesForOrder) {
      ol.Address = orderline.Address;
      this.refreshOrderline(ol.Id);
    }
    if (this.searchModel) {
      let ol = this.orderlines.find(ol => ol.Id == orderline.Id);
      if (ol) {
        ol.Quantity = orderline.Quantity;
      }
    }
    SetSnackBar(onSuccessText);
  }

  async selectRush(val: {
    orderId: string;
    rush: RushOption;
    deliveryOption: number;
    additionalIdsToRush: number[];
  }) {
    if (val.orderId) {
      let orderline = this.getActiveOrderlineArray().find(
        (ol: Orderline) => ol.Id == val.orderId
      );
      if (orderline) {
        orderline.RushOption = val.rush;
        try {
          const { data } = await OrdersService.RushOrderline({
            Id: orderline.Id,
            RushOptionId: val.rush.PublicId,
            ShippingDeliveryOptionId: val.deliveryOption,
            AdditionalOrderlineIdsToRush: val.additionalIdsToRush
          });
          const { Orderline, AffectedOrderlines } = data;
          orderline.DueDate = Orderline.DueDate;
          orderline.IsARush = Orderline.IsARush;
          for (let ol of AffectedOrderlines) {
            this.refreshOrderline(ol);
          }
          SetSnackBar(`Rush ${val.rush.PublicId ? 'applied' : 'removed'} successfully`);
        } catch (err) {
          SetSnackBar(
            'Something went wrong while adding your rush to this item, please contact technical support.'
          );
          console.log(err);
        }
      }
    }
  }

  async buildFilterOptions() {
    const response = await OrdersService.GetOrderlineFilterCount();
    this.filterOptions[1].count = response.data.OnHold;
    this.filterOptions[2].count = response.data.OnOrder;
    this.filterOptions[3].count = response.data.SignOffs;
    this.filterOptions[4].count = response.data.Pending;
    for (let i = 1; i < this.filterOptions.length; i++) {
      this.filterOptions[i].shouldShow = this.filterOptions[i].count > 0;
    }
  }

  AddNewItemSelected() {
    if (this.isTransparencyCustomer) {
      this.$router.push({ name: 'AddEditTransparencyItem' });
    } else {
      this.$refs.ItemDialog.openDialog();
    }
  }

  async createItem(response: any) {
    this.$refs.ItemDialog.closeDialog();
    if (response.didAccept) {
      await this.$store.dispatch('items/refreshItem', response.itemId);
      this.$store.dispatch('items/addProcessImageItemId', response.itemId);
    }
  }

  goToOrderView(po: string = '') {
    if (
      this.OrderlinesForDisplay.find(
        ol =>
          ol.GroupByValue === po &&
          ol.Orderlines.some(ol => ol.OrderLineType === OrderLineTypes.Proof)
      )
    ) {
      //TODO: make dialog saying you can't drill into it
      return;
    }
    if (po) {
      this.clearCurrentOrder();
      this.$router.push({ name: 'OrderView', params: { po: po } });
    } else {
      this.$router.push({ name: 'OrderView' });
    }
  }
  goToOrderDetails(po: string = '') {
    if (
      this.OrderlinesForDisplay.find(
        ol => ol.GroupByValue === po && ol.Orderlines.some(ol => ol.Status === 'PENDING')
      )
    ) {
      this.clearCurrentOrder();
      this.$router.push({ name: 'OrderView', params: { po: po } });
      return;
    }
    this.clearCurrentOrder();
    this.$router.push({ name: 'OrderDetails', params: { po: po } });
  }
  generateOrderConfirmation() {
    let confirmation = localStorage.getItem('orderConfirmation');
    if (confirmation) {
      let orderConfirmation = JSON.parse(confirmation);
      for (let date in orderConfirmation) {
        this.orderConfirmation.push(
          `${orderConfirmation[date].length} item${
            orderConfirmation[date].length > 0 ? 's' : ''
          } will ship on ${date}`
        );
      }
    }
  }
  /* Computed */

  get filteredFilterOptions() {
    return this.filterOptions.filter(f => f.shouldShow);
  }
  get shouldShowNoItemOrder() {
    return (
      !this.OrderlinesForDisplay[0] &&
      !this.areOrderlinesLoading &&
      !this.searchLoading &&
      !this.searchModel
    );
  }
  /* Loaders */
  async getOrderlinesOnHold() {
    await this.getOnHoldOrderlines();
    this.onHoldOrderlines;
  }
  async getOrderlinesThatAreRecentlyShipped() {
    await this.getOrderlinesRecentlyShipped();
    this.recentlyShippedOrderlines;
  }
  async getSignOffs() {
    await this.getOrderlineProofs();
    this.orderlineProofs;
  }
  async getPendingOrderlines() {
    await this.getOrderlinesPending();
    this.pendingOrderlines;
  }
  /* Mounted */
  async mounted() {
    if (this.$route.params.hasPlacedOrder) {
      this.generateOrderConfirmation();
    }
    if (this.$route.params.partnumber) {
      this.searchText = this.$route.params.partnumber;
      this.search();
    } else {
      await this.getSuggestions(true);
      this.showAllOrderlines();
    }
    this.buildFilterOptions();
    this.currentFilter = this.filterOptions[0];
  }
  /* Created */
}
