
import { Confirm } from '@/helpers/AuthHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { CustomerService } from '@/services/customer-service';
import { PaymentProfile } from '@/store/models/PaymentModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import PaymentSelect from './PaymentSelect.vue';
import { Getter } from 'vuex-class';
import { Customer } from '@/store/models/CustomerModel';
@Component({
  components: {
    PaymentSelect
  }
})
export default class SelectPaymentDialog extends Vue {
  /* Properties */
  @Prop() paymentProfiles!: PaymentProfile[];
  /* Store Actions */
  @Getter('getCustomer', { namespace: 'customer' }) Customer: Customer;
  /* Watchers */
  /* Data */
  isLoading: boolean = false;
  dialog: boolean = false;
  paymentOptions: string[] = ['Set as Default', 'Delete'];
  isAlreadyDefault: boolean = false;
  shouldShowTerms: boolean = true;
  /* Computed */
  get selectedProfile() {
    return this.defaultPaymentProfile;
  }
  get defaultPaymentProfile() {
    return this.paymentProfiles.find(p => p.IsDefault);
  }
  get paymentProfilesToDisplay() {
    return this.shouldShowTerms
      ? this.paymentProfiles
      : this.paymentProfiles.filter(p => p.CardType !== 7);
  }
  /* Async Functions */
  async updatePaymentMethodDefault(profile: PaymentProfile) {
    this.isLoading = true;
    try {
      await CustomerService.UpdatePaymentMethodDefault(profile);
      this.$emit('defaultPaymentUpdated');
      SetSnackBar('Updated Payment Method successfully');
    } catch (err) {
      this.$emit(
        'snackbar',
        'There was an error updating your payment method. Please contact technical support'
      );
    }
    this.isLoading = false;
    this.closeDialog();
  }
  async deletePaymentProfile(profile: PaymentProfile) {
    this.isLoading = true;
    try {
      await CustomerService.DeletePaymentProfile(profile.PaymentProfileId);
      SetSnackBar('Deleted Payment Method successfully');
      this.$emit('deletedPaymentProfile');
    } catch (err) {
      let e = err as any;
      SetSnackBar(e.response.data.Message);
    }
    this.isLoading = false;
  }
  /* Utility Functions */
  isValidDate(date: string) {
    if (!date) return false;
    let [month, year] = date.split('/');
    const todaysDate = new Date();
    if (year.length > 2) year = year.substring(2);
    if (`20${year}` === todaysDate.getFullYear().toString())
      return parseInt(month) >= todaysDate.getMonth() + 1;
    if (`20${year}` > todaysDate.getFullYear().toString()) return true;
    return false;
  }
  getCardName(profile: any) {
    switch (profile.CardType) {
      case 1:
        return 'Visa';
      case 2:
        return 'MasterCard';
      case 3:
        return 'American Express';
      case 4:
        return 'diners';
      case 5:
        return 'Discover';
      case 6:
        return 'jcb-logo';
      case 7:
        return profile.NameOnCard?.replace(/\s/g, '');
    }
    return 'Unknown Card.';
  }
  handleOptionSelection(option: string, profile: PaymentProfile) {
    this.isAlreadyDefault = false;
    switch (option) {
      case 'Set as Default':
        if (this.defaultPaymentProfile !== profile)
          this.updatePaymentMethodDefault(profile);
        else {
          this.isAlreadyDefault = true;
        }
        return;
      case 'Delete':
        Confirm(
          async () => {
            await this.deletePaymentProfile(profile);
          },
          'Delete Payment Method',
          'This will delete your payment method. Do you want to proceed?',
          "don't delete",
          'delete'
        );
        return;
    }
  }
  openDialog(shouldShowTerms: boolean = true) {
    this.shouldShowTerms = shouldShowTerms;
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
