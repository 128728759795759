
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import {
  Orderline,
  OrderlineDetails,
  OrderLineTypes
} from '@/store/models/OrderlineModel';
import J1lImg from '@/components/J1lImg.vue';
import OrderActions from '@/components/OrderActions.vue';
import J1LDateIcon from '@/components/J1LDateIcon.vue';
import RushIcon from '@/components/Icons/RushIcon.vue';
import TrackingInfoDialog from '@/components/TrackingInfoDialog.vue';
import { Address } from '@/store/models/AddressModel';
import { StringHelper } from '@/helpers/StringHelper';
import { Item } from '@/store/models/ItemModel';
import { DateHelper } from '@/helpers/DateHelper';
import { GetDisplayDate } from '@/helpers/ObjectHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { ConstValues } from '@/ConstValues';
import J1LContactDialog from '../J1LContactDialog.vue';
import PackagePickupDetailsDialog from '../PackagePickupDetailsDialog.vue';
import AddressCardTooltip from '@/components/addressComponents/AddressCardTooltip.vue';
@Component({
  components: {
    AddressCardTooltip,
    J1lImg,
    OrderActions,
    J1LDateIcon,
    RushIcon,
    TrackingInfoDialog,
    J1LContactDialog,
    PackagePickupDetailsDialog
  }
})
export default class OrderlineCard extends Vue {
  $refs!: {
    OrderActions: HTMLFormElement;
    J1lImg: HTMLFormElement;
    TrackingInfoDialog: HTMLFormElement;
    J1LContactDialog: HTMLFormElement;
    PackagePickupDetailsDialog: HTMLFormElement;
  };
  /* Properties */
  @Prop() shouldUseInOrderActions!: boolean;
  @Prop({ required: true }) orderline!: Orderline;
  @Prop() orderlinesOnOrder!: Array<Orderline | OrderlineDetails>;
  @Prop({ required: true, default: '' })
  itemId!: string;
  @Prop()
  shouldShowExtendedInfo!: boolean;
  @Prop({ required: true })
  backgroundColor!: string;
  @Prop() shouldDisableOptions!: boolean;
  @Prop() isForDisplay!: boolean;
  /* Store Actions */
  /* Watchers */
  /* Data */
  isGrabbingHiRezImage: boolean = false;
  showTooltip: boolean = false;
  colors: string[] = ['rgb(102,102,102)', 'blue', 'magenta', 'yellow', 'white', 'white'];
  colorIcons: string[] = [
    'looks_one',
    'looks_two',
    'looks_3',
    'looks_4',
    'looks_5',
    'looks_6'
  ];
  itemActions: string[] = ['Search in Manage Item', 'Search in Orders'];
  quickActionItems: { Icon: string; EventId: number }[] = [
    { Icon: 'mdi-currency-usd', EventId: 2 },
    { Icon: 'mdi-traffic-light', EventId: 0 },
    { Icon: 'mdi-share-variant', EventId: 0 },
    { Icon: 'mdi-bell', EventId: 0 },
    { Icon: 'mdi-headphones', EventId: 0 }
  ];
  areOptionsDisabled() {
    return (
      this.shouldDisableOptions ||
      this.isForDisplay ||
      this.orderline.OrderLineType == OrderLineTypes.Service
    );
  }
  /* Methods */
  isMobile() {
    return window.innerWidth <= 768;
  }
  GetDisplayDate() {
    return GetDisplayDate(this.orderline);
  }
  GetShipVerbiage() {
    return DateHelper.GetShipVerbiage(this.orderline);
  }
  getRollDirectionSvg() {
    switch (this.orderline.RollDirection.Id) {
      case 3:
        return '3unwind.svg';
      case 4:
        return '4unwind.svg';
      default:
        return '4unwind.svg';
    }
  }
  handleItemActionEvent(index: number) {
    switch (index) {
      case 0:
        this.$router.push({
          name: 'ManageItems',
          params: {
            partnumber: this.orderline.Item.PartNumber
          }
        });
        break;
      case 1:
        this.$emit('orderSearchByPartNumber', this.orderline.Item.PartNumber);
        break;
    }
  }
  grabbingHighRezImgChange(isLoading: boolean) {
    this.isGrabbingHiRezImage = isLoading;
  }
  deleteOrderline(orderId: string) {
    this.$emit('deleteOrderline', orderId);
  }
  editQuantity(returnValue: { orderId: string; quantity: number }) {
    this.$emit('changeQuantity', returnValue);
  }
  changeAddress(returnValue: { orderId: string; shippingAddress: Address }) {
    this.$emit('addressChanged', returnValue);
  }
  selectRush(returnValue: { orderId: string; rush: any }) {
    this.$emit('rushSelected', returnValue);
  }
  updateOrderline(orderline: OrderlineDetails) {
    this.$emit('updateOrderline', orderline);
  }
  openTrackingInfoDialog() {
    this.$refs.TrackingInfoDialog.showDialog();
  }
  getTextColor(status: string) {
    return StringHelper.getStatusTextColorClass(status);
  }
  getBackgroundColorByStatus(status: string) {
    return StringHelper.getStatusTextBackgroundColorClass(status);
  }
  getBorderColorByStatus(status: string) {
    return StringHelper.getStatusTextBorderColorClass(status);
  }
  goToItem(item: Item) {
    let route = this.$router.resolve({
      name: 'ItemInformation',
      params: { id: item.Id }
    });
    window.open(route.href, '_blank');
  }
  refreshItem(item: Item) {
    this.$emit('refreshItem', { orderlineId: this.orderline.Id, item });
  }
  handleOrderStatusClick() {
    switch (this.orderline.OrderStatus.Action) {
      case 'Directions':
        this.$refs.J1LContactDialog.openDialog();
        return;
      case 'More info':
        this.$refs.PackagePickupDetailsDialog.openDialog(this.orderline);
        return;
      case 'Tracking':
        this.openTrackingInfoDialog();
        return;
    }
  }
  /* Loaders */
  /* Computed */
  get determineTextColor() {
    switch (this.orderline.Status) {
      case 'CLOSED':
        return 'j1l-black';
      case 'HOLD':
        return 'bubble-gum-purple';
      case 'PRINTING SOON (EDITABLE)':
        return 'mintGreen';
      case 'SENT TO PRESS':
        return 'mustard';
      case 'PRINTED WAITING TO CUT':
        return 'hotPink';
      case 'CUT WAITING TO REWIND':
      case 'PREPARING TO PICKUP':
      case 'READY TO PICKUP':
      case 'PREPARING TO SHIP':
      case 'SHIPPED':
      case 'READY TO SHIP':
        return 'j1lorange';
      default:
        return 'bold-blue';
    }
  }
  shouldUseNullDate() {
    let statuses = ['Production hold', 'Processing Error(s)', 'Waiting on Codes'];
    return statuses.includes(this.orderline.OrderStatus.OrderStatusDisplay);
  }
  get cardHeight() {
    return 125;
  }
  get priceEach() {
    return (this.orderline.ExtendedPrice / this.orderline.Quantity).toFixed(4);
  }
  get price() {
    let price = this.orderline.PriceEach * this.orderline.Quantity;
    return price.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD'
    });
  }
  showHighRez() {
    this.$refs.J1lImg.showHighRezDialog();
  }
  /* Mounted */
  /* Created */
}
