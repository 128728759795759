
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ItemService } from '@/services/item-service';
import HiRezImg from '@/components/HiRezImg.vue';
import { Getter } from 'vuex-class';
import { ImageHelper } from '@/helpers/ImageHelper';
@Component({
  components: {
    HiRezImg
  }
})
export default class j1lImg extends Vue {
  @Getter('getAllProcessingImageIds', { namespace: 'items' })
  processingImageItemIds: any;
  @Prop({ required: true, default: [] })
  itemId!: string;
  @Prop({ required: true })
  version!: number;
  @Prop()
  shouldDisableHiRez!: boolean;
  @Prop()
  shouldContain!: boolean;
  @Prop()
  height!: number;
  @Prop()
  maxWidth!: number;
  @Prop()
  quantity!: number;
  @Prop()
  shouldDisplayErrorImg!: boolean;
  @Prop()
  shouldDisableImg!: boolean;
  @Prop()
  defaultHiRezOption!: number;
  @Prop()
  orderlineId!: string;
  @Prop()
  isStaticItem!: boolean;
  @Prop()
  staticItemId!: number;

  @Watch('shouldDisplayErrorImg')
  onShouldDisplayErrorImgChange(val: boolean) {
    if (val) this.rerender();
  }
  @Watch('shouldDisableImg')
  onShouldDisableImgChave(val: boolean) {
    if (val) this.rerender();
  }
  @Watch('staticItemId')
  onStaticItemIdChange() {
    console.log('rerendering');
    this.rerender();
  }

  imageSrc: string = '';
  hiRezDialog: boolean = false;
  hasRequestedImageToCache: boolean = false;
  maskBuildType: string = 'trimmedlabel';
  selectedOption: any = 0;
  filterOptions: any[] = [
    {
      id: 0,
      text: 'Trimed label',
      value: 'trimmedlabel',
      isSelected: true
    },
    { id: 1, text: 'Safe zone', value: 'safezone', isSelected: false },
    {
      id: 2,
      text: 'Full bleed',
      value: 'fullbleed',
      isSelected: false
    }
  ];
  async showHighRezDialog() {
    if (!this.shouldDisableHiRez) {
      if (this.defaultHiRezOption) this.setFilterOption(this.defaultHiRezOption);
      this.hiRezDialog = true;
    }
  }
  setFilterOption(id: number) {
    this.chipDielineClick(this.filterOptions.find(f => f.id === id)!.value);
  }

  closeHighRezDialog() {
    this.hiRezDialog = false;
  }
  async error() {
    try {
      const imageResponse = await ItemService.GetItemThumbnail(
        this.fixedHeight,
        this.fixedWidth,
        this.itemId,
        this.version
      );
      this.imageSrc = `${imageResponse.data}?${new Date().getTime()}`;
    } catch (error) {
      this.setDefaultImage();
    }
  }
  async rerender() {
    if (this.isStaticItem) {
      const fileName = ImageHelper.getImageFileNameByStaticItemId(this.staticItemId);
      if (!fileName) this.setDefaultImage();
      else this.imageSrc = require(`@/assets/Transparency/${fileName}`);
      return;
    }
    if (this.shouldDisableImg) {
      this.imageSrc = '';
      if (this.shouldDisplayErrorImg) this.imageSrc = require('@/assets/J1L-Logo1.svg');
      return;
    }
    if (this.itemId) {
      try {
        if (this.processingImageItemIds.includes(this.itemId)) {
          return;
        }
        this.imageSrc = `${process.env.VUE_APP_IMG_URL}/${this.itemId}_v${this.version}.jpg`;
      } catch (err) {
        this.setDefaultImage();
      }
    } else {
      this.setDefaultImage();
    }
  }
  chipDielineClick(val: any) {
    this.maskBuildType = val;
  }

  async onError(err) {
    const oldImgSrc = this.imageSrc;
    this.setDefaultImage();
    if (this.hasRequestedImageToCache) return;
    this.hasRequestedImageToCache = true;
    await ItemService.DownloadItemFileToCache(this.itemId, this.version);
    this.imageSrc = oldImgSrc;
  }

  setDefaultImage() {
    this.imageSrc = require('@/assets/J1L-Logo1.svg');
  }
  get fixedWidth() {
    return 300;
  }
  get fixedHeight() {
    return 400;
  }
  async mounted() {
    this.rerender();
  }
  get imageHeight() {
    return this.height || 200;
  }
  get imageWidth() {
    return this.maxWidth || 300;
  }
}
