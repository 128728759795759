
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { ImportReport } from '@/store/models/ImportReportModel';
@Component
export default class ImportProcessResultDialog extends Vue {
  /* Properties */
  @Prop({ required: true }) report!: ImportReport;
  /* Store Actions */
  /* Watchers */
  /* Data */
  isLoading: boolean = false;
  dialog: boolean = false;

  /* Async Functions */
  /* Utility Functions */
  openDialog() {
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
