
import { Action, Getter } from 'vuex-class';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { AddressService } from '@/services/address-service';
import { Address } from '@/store/models/AddressModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import RollDirectionAlert from './RollDirectonAlert.vue';
import AddEditShippingAddress from '@/components/AddEditShippingAddress.vue';
import AddressCardTooltip from '@/components/addressComponents/AddressCardTooltip.vue';
@Component({
  components: {
    RollDirectionAlert,
    AddEditShippingAddress,
    AddressCardTooltip
  }
})
export default class AddressSelector extends Vue {
  $refs!: {
    AddEditShippingAddress: HTMLFormElement;
  };
  /* Properties */
  @Prop() shouldShowAddAddress!: boolean;
  /* Store Actions */
  @Getter('getCustomer', { namespace: 'customer' })
  currentCustomer: any;
  @Action('getCurrentCustomer', { namespace: 'customer' })
  getCurrentCustomerStore: any;
  /* Watchers */
  /* Data */
  selectedAddress: Address = new Address();
  addresses: Address[] = [];
  shouldShowAlert: boolean = false;
  /* Utility Functions */
  async setAddress() {
    if (!this.currentCustomer.Name) await this.getCurrentCustomerStore();
    this.shouldShowAlert = this.selectedAddress.IsCustomerPickup
      ? this.currentCustomer.Options.RollDirection.Position == 10
      : this.selectedAddress.Options.RollDirectionId == 10;
    this.$emit('addressChanged', this.selectedAddress);
  }
  getAddressId() {
    return this.selectedAddress.Id;
  }
  closeAlert() {
    this.shouldShowAlert = false;
  }
  isAddressValid() {
    return this.selectedAddress.Id && this.shouldShowAlert == false;
  }
  /* Loaders */
  async getAllCustomerAddresses() {
    try {
      const response = await AddressService.GetShippingAddressesFull();
      this.addresses = response.data;
      const defaultAddress = this.addresses.find(a => a.IsDefault);
      this.selectedAddress = defaultAddress ? defaultAddress : this.addresses[0];
      this.setAddress();
    } catch (err) {
      SetSnackBar(
        'We had trouble loading your addresses, please contact techsupport@just1label.com'
      );
    }
  }
  async updateRollDirection() {
    let oldAddress = this.selectedAddress;
    await this.getAllCustomerAddresses();
    let newAddress = this.addresses.find((a: any) => a.Id === oldAddress.Id);
    if (newAddress) {
      this.selectedAddress = newAddress;
      this.selectedAddress.Options.RollDirectionId = newAddress.Options.RollDirectionId;
    }
  }
  openNewAddressDialog() {
    this.$refs.AddEditShippingAddress.openAddressDialog(true);
  }
  async updateAddress(newAddress: any) {
    await this.getAllCustomerAddresses();
    if (newAddress) {
      this.selectedAddress = this.addresses.find(a => a.Id === newAddress.Id)!;
    }
  }
  /* Mounted */
  mounted() {
    this.getAllCustomerAddresses();
  }
  /* Created */
  /* Emmited Functions */
}
