import axios, { AxiosResponse } from 'axios';
import { handleResponse, handleErrorResponse, downloadFile } from '@/helpers/ApiHelper';
import { Invoice } from '@/store/models/InvoiceModel';
const invoiceUrl = `${process.env.VUE_APP_ROOT_API}/Invoice`;

export const InvoiceService = {
  async GenerateInvoice(invoiceId: string) {
    const response = await axios.get(
      `${invoiceUrl}/GenerateInvoice?invoiceId=${invoiceId}`
    );
    if (response.data) window.open(response.data);
  },
  async DownloadInvoice(invoiceId: string, invoiceNumber: string) {
    const response = await axios({
      url: `${invoiceUrl}/DownloadInvoice?invoiceId=${invoiceId}`,
      method: 'GET',
      responseType: 'arraybuffer'
    });
    const filename = `${invoiceNumber}.pdf`;
    if (response.data) {
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      downloadFile(pdfBlob, filename, true);
    }
  },
  async DownloadMultipleInvoices(invoiceIds: string[]) {
    const response = await axios({
      url: `${invoiceUrl}/DownloadMultipleInvoices`,
      method: 'POST',
      data: { Ids: invoiceIds },
      responseType: 'arraybuffer'
    });
    const filename = 'invoices.zip';
    if (response.data) {
      downloadFile(new Blob([response.data]), filename);
    }
  },
  async DownloadInvoicesByDateRange(startDate: Date, endDate: Date, type: number) {
    const response = await axios({
      url: `${invoiceUrl}/DownloadMultipleInvoices`,
      method: 'POST',
      data: { DownloadType: type, StartDate: startDate, EndDate: endDate },
      responseType: 'arraybuffer'
    });
    const filename = `Invoices_${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}.zip`;
    if (response.data) {
      downloadFile(new Blob([response.data]), filename);
    }
  },
  GetInvoicesForPagination(model: any = null): Promise<Invoice[]> {
    return new Promise(async (resolve, reject) => {
      try {
        if (!model) {
          model = {
            Take: 30,
            Skip: 0,
            Query: '',
            ShouldShowUnpaidInvoicesOnly: false
          };
        }
        const response = await axios.post(
          `${invoiceUrl}/GetInvoicesForPagination`,
          model
        );
        handleResponse(response);
        let returnValue = response.data.map((r: any) => (r = new Invoice(r)));
        return resolve(returnValue);
      } catch (error) {
        return reject(error);
      }
    });
  },
  GetAutocomleteSuggestionsBySearchQuery(model: any): Promise<AxiosResponse> {
    return axios.post(`${invoiceUrl}/GetAutocompleteSuggestionsBySearchQuery`, model);
  },
  GetInvoiceLines(id: string): Promise<AxiosResponse> {
    return axios.get(`${invoiceUrl}/GetInvoiceLinesForInvoice?invoiceId=${id}`);
  },
  GetInvoiceById(id: string): Promise<AxiosResponse> {
    return axios.get(`${invoiceUrl}/GetInvoiceById?id=${id}`);
  },
  GetInvoiceByPO(po: string): Promise<AxiosResponse> {
    return axios.get(`${invoiceUrl}/GetInvoiceByPO?po=${po}`);
  },
  GetInvoiceByPOFull(po: string): Promise<AxiosResponse> {
    return axios.get(`${invoiceUrl}/GetInvoiceByPOFull?po=${po}`);
  },
  GenerateInvoiceEmail(invoiceId: string): Promise<AxiosResponse> {
    return axios.get(`${invoiceUrl}/GenerateInvoiceEmail?invoiceId=${invoiceId}`);
  },
  PayInvoice(paymentProfileId: string, invoiceId: string): Promise<AxiosResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        var model = {
          paymentId: paymentProfileId,
          invoiceId: invoiceId
        };
        const response = await axios.post(`${invoiceUrl}/`, model);
        handleResponse(response);
        return resolve(response);
      } catch (error) {
        return reject(error);
      }
    });
  }
};
