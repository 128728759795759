
import { Address } from '@/store/models/AddressModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class AddressCardTooltip extends Vue {
  $refs!: {
    DefaultActions: HTMLFormElement;
  };
  /* Properties */
  @Prop() address: Address;
  @Prop({ default: 'right' }) position!: string;
  /* Store Actions */
  /* Watchers */
  /* Data */
  /* Computed */
  /* Async Functions */
  /* Utility Functions */
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
