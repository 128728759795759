import { Address, PhoneNumber } from '@/store/models/AddressModel';
import { Brand, RollDirection } from '@/store/models/ItemModel';
import { BetaAccessModel } from './RequestModels';
import { AdminUser } from '@/store/models/ProfileModel';
import { ConversionHelper } from '@/helpers/ConversionHelper';

export class Customer {
  Addresses!: Address[];
  Brand!: Brand;
  BillingAddress: Address;
  Comments!: string;
  ContactEmail!: Email;
  Contact: string;
  Phone: PhoneNumber;
  PublicId: string;
  CreationDate!: string;
  Emails!: Email[];
  Id: string;
  Name!: string;
  OD!: number;
  Options: CustomerOptions;
  SalesPerson: SalesPerson;
  Term: any;
  ShipTo!: any;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Addresses = rawItem.Addresses.map((a: any) => new Address(a));
      this.BillingAddress = rawItem.BillingAddress;
      this.Brand = rawItem.Brand;
      this.Comments = rawItem.Comments;
      this.Contact = rawItem.Contact;
      this.ContactEmail = rawItem.ContactEmail;
      this.CreationDate = rawItem.CreationDate;
      this.Emails = rawItem.Emails;
      this.Id = rawItem.Id;
      this.PublicId = rawItem.PublicId;
      this.Name = rawItem.Name;
      this.OD = rawItem.OD;
      this.Options = rawItem.Options;
      this.Phone = rawItem.Phone;
      this.Term = rawItem.Term;
      this.SalesPerson = new SalesPerson(rawItem.SalesPerson);
      this.ShipTo = rawItem.ShipTo;
    }
  }
  ConvertToBasicInfoModel = () => {
    return {
      Address: ConversionHelper.convertAddressToAddressModel(
        this.BillingAddress,
        false,
        true,
        true
      ),
      Contact: {
        EmailAddress: this.ContactEmail.EmailAddress,
        PhoneNumber: this.Phone.PhoneNumber,
        ContactName: this.Name
      },
      SalesPerson: {
        Id: this.SalesPerson.Id,
        FirstName: this.SalesPerson.FirstName,
        LastName: this.SalesPerson.LastName,
        EmailAddress: this.SalesPerson.EmailAddress,
        PhoneNumber: this.SalesPerson.PhoneNumber
      }
    };
  };
}
export class AdminCustomer extends Customer {
  Users!: AdminUser[];
  TotalInvoiced!: number;
  TotalInvoicedThisYear!: number;
  TotalPastDue!: number;
  constructor(rawItem?: any) {
    super(rawItem);
    if (rawItem) {
      this.Users = rawItem.Users.map((u: any) => new AdminUser(u));
      this.PublicId = rawItem.PublicId;
      this.Id = rawItem.Id;
      this.TotalInvoiced = rawItem.TotalInvoiced;
      this.TotalInvoicedThisYear = rawItem.TotalInvoicedYear;
      this.TotalPastDue = rawItem.TotalPastDue;
    }
  }
}

export class Email {
  EmailAddress!: string;
  IsPrimary!: boolean;
  Id: string;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.EmailAddress = rawItem.EmailAddress;
      this.IsPrimary = rawItem.IsPrimary;
      this.Id = rawItem.Id || rawItem.PublicId;
    }
  }
}
export enum FramePricingOptionTypes {
  FramePricingOnly = 0,
  QuantityPricingOnly = 1,
  BestOfBoth = 2,
  None = 100
}
export class CustomerOptions {
  RollDirection!: RollDirection;
  AllItemsShouldNotBeOutline!: boolean;
  AltUtilization!: number;
  AreProofsFree!: boolean;
  CanAddToOrder!: boolean;
  CanAddTransparencyItems!: boolean;
  CanConvertToOutline!: boolean;
  CanInvoice!: boolean;
  CanSeePrivateLabel!: boolean;
  CanUploadOrders!: boolean;
  CanUseAmazonVarData!: boolean;
  CanUseChainApprovals!: boolean;
  CanUseGroupApprovals!: boolean;
  CanUseVarData!: boolean;
  CoreFee!: number;
  CustomerRetentionQuantity!: number;
  DefaultProofQuantity!: number;
  DiscountPercentage!: number;
  EndingLabelCount!: number;
  FramePricingOptionType!: FramePricingOptionTypes;
  HasBetaAccess!: boolean;
  InfoVerifiedDate: string;
  IsOnCreditHold!: boolean;
  IsShippingIncluded!: boolean;
  LastLoginDate!: string;
  LeadTimeDays!: number;
  MinimumPrice!: number;
  RetentionLabelCount!: number;
  ShouldAddPantonesToItem!: boolean;
  ShouldChargeCoreFee!: boolean;
  ShouldChargeRushFee!: boolean;
  ShouldGenerateCustomerRetentions!: boolean;
  ShouldUseFrameForPrice!: boolean;
  ShouldUseItemizedPricing!: boolean;
  ShouldUseLabelPricing!: boolean;
  ShouldUseParentPricing!: boolean;
  StartLabelCount!: number;
  SummaryLabelCount!: number;
  TermsAcceptedDate: string;
  TermsCCPaymentMethodId!: string;
  TermLimit!: number;
  UsesFlatRatePricing!: boolean;
  VarDataCostEach!: number;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.RollDirection = rawItem.RollDirection;
      this.AltUtilization = rawItem.AltUtilization;
      this.AreProofsFree = rawItem.AreProofsFree;
      this.CanAddToOrder = rawItem.CanAddToOrder;
      this.CanAddTransparencyItems = rawItem.CanAddTransparencyItems;
      this.CanConvertToOutline = rawItem.CanConvertToOutline;
      this.CanInvoice = rawItem.CanInvoice;
      this.CanSeePrivateLabel = rawItem.CanSeePrivateLabel;
      this.CanUploadOrders = rawItem.CanUploadOrders;
      this.CanUseAmazonVarData = rawItem.CanUseAmazonVarData;
      this.CanUseChainApprovals = rawItem.CanUseChainApprovals;
      this.CanUseGroupApprovals = rawItem.CanUseGroupApprovals;
      this.CanUseVarData = rawItem.CanUseVarData;
      this.CoreFee = rawItem.CoreFee;
      this.CustomerRetentionQuantity = rawItem.CustomerRetentionQuantity;
      this.DefaultProofQuantity = rawItem.DefaultProofQuantity;
      this.DiscountPercentage = rawItem.DiscountPercentage;
      this.EndingLabelCount = rawItem.EndingLabelCount;
      this.FramePricingOptionType = rawItem.FramePricingOptionType;
      this.InfoVerifiedDate = rawItem.InfoVerifiedDate;
      this.IsOnCreditHold = rawItem.IsOnCreditHold;
      this.IsShippingIncluded = rawItem.IsShippingIncluded;
      this.LeadTimeDays = rawItem.LeadTimeDays;
      this.MinimumPrice = rawItem.MinimumPrice;
      this.RetentionLabelCount = rawItem.RetentionLabelCount;
      this.ShouldAddPantonesToItem = rawItem.ShouldAddPantonesToItem;
      this.ShouldChargeCoreFee = rawItem.ShouldChargeCoreFee;
      this.ShouldChargeRushFee = rawItem.ShouldChargeRushFee;
      this.ShouldGenerateCustomerRetentions = rawItem.ShouldGenerateCustomerRetentions;
      this.ShouldUseFrameForPrice = rawItem.ShouldUseFrameForPrice;
      this.ShouldUseItemizedPricing = rawItem.ShouldUseItemizedPricing;
      this.ShouldUseLabelPricing = rawItem.ShouldUseLabelPricing;
      this.ShouldUseParentPricing = rawItem.ShouldUseParentPricing;
      this.StartLabelCount = rawItem.StartLabelCount;
      this.SummaryLabelCount = rawItem.SummaryLabelCount;
      this.UsesFlatRatePricing = rawItem.UsesFlatRatePricing;
      this.VarDataCostEach = rawItem.VarDataCostEach;
      this.AllItemsShouldNotBeOutline = rawItem.AllItemsShouldNotBeOutline;
      this.TermsAcceptedDate = rawItem.TermsAcceptedDate;
      this.TermLimit = rawItem.TermLimit;
      this.TermsCCPaymentMethodId = rawItem.TermsCCPaymentMethodId;
    }
  }
}

export class SalesPerson {
  Id!: number;
  FirstName!: string;
  LastName!: string;
  EmailAddress!: string;
  PhoneNumber!: string;
  get Name(): string {
    return `${this.FirstName} ${this.LastName}`.trim();
  }
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Id = rawItem.Id;
      this.FirstName = rawItem.FirstName;
      this.LastName = rawItem.LastName;
    }
  }
}

export class FeedbackMsgModel {
  BrowserType!: string;
  Url!: string;
  SubmissionTime!: string;
  ScreenShot!: string;
  ReportType!: string;
  Message!: string;
}
export class HelpDeskRequestModel {
  Subject!: string;
  Question!: string;
  Department!: DepartmentTypes;
  Url!: string;
  BrowserType!: string;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.Subject = rawItem.Subject;
      this.Question = rawItem.Question;
      this.Department = rawItem.Department;
      this.Url = rawItem.Url;
      this.BrowserType = rawItem.BrowserType;
    }
  }
}
export class Snackbar {
  backgroundColor!: string;
  timeout!: number;
  message!: string;
  actionText!: string;
  actionTextColor!: string;
  customAction!: any;
  width!: number;
  constructor(rawItem?: any) {
    this.Populate(rawItem);
  }
  Populate(rawItem: any) {
    if (rawItem) {
      this.backgroundColor = rawItem.backgroundColor;
      this.customAction = rawItem.customAction;
      this.timeout = rawItem.timeout;
      this.message = rawItem.message;
      this.actionText = rawItem.actionText;
      this.actionTextColor = rawItem.actionTextColor;
      this.width = rawItem.width;
    }
  }
}
export class ConfirmationDialogInfo {
  action!: any;
  confirmationTitle!: string;
  confirmationText!: string;
  cancelButtonText!: string;
  commitButtonText!: string;
  shouldPersist!: boolean;
  constructor(rawItem?: any) {
    if (rawItem) {
      this.action = rawItem.action;
      this.confirmationText = rawItem.confirmationText;
      this.confirmationTitle = rawItem.confirmationTitle;
      this.cancelButtonText = rawItem.cancelButtonText;
      this.commitButtonText = rawItem.commitButtonText;
      this.shouldPersist = rawItem.shouldPersist;
    } else {
      this.cancelButtonText = 'Cancel';
      this.commitButtonText = 'I am sure';
      this.confirmationTitle = 'Are you sure?';
    }
  }
}
export enum DepartmentTypes {
  Artwork = 0,
  Billing,
  General,
  Ordering,
  Website
}

export interface CustomerState {
  customer: Customer;
  snackbar: Snackbar;
  displayStyle: string;
}
