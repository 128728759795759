
import { Confirm } from '@/helpers/AuthHelper';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import { CustomerService } from '@/services/customer-service';
import { PaymentProfile } from '@/store/models/PaymentModel';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import PaymentSelect from './PaymentSelect.vue';
import PaymentSelectCard from '@/components/cardComponents/PaymentSelectCard.vue';
import { Getter } from 'vuex-class';
import { Customer } from '@/store/models/CustomerModel';
@Component({
  components: {
    PaymentSelect,
    PaymentSelectCard
  }
})
export default class SelectAutopayDialog extends Vue {
  /* Properties */
  @Prop() paymentProfiles!: PaymentProfile[];
  /* Store Actions */
  @Getter('getCustomer', { namespace: 'customer' }) Customer: Customer;
  /* Watchers */
  /* Data */
  isLoading: boolean = false;
  dialog: boolean = false;
  paymentOptions: string[] = ['Set as Default', 'Delete'];
  isAlreadyDefault: boolean = false;
  /* Computed */
  get selectedAutopayProfile() {
    return this.paymentProfiles?.find(
      p => p.PaymentProfileId === this.Customer.Options.TermsCCPaymentMethodId
    );
  }
  get paymentProfilesToDisplay() {
    return this.paymentProfiles?.filter(
      p =>
        p.CardType !== 7 &&
        p.PaymentProfileId !== this.Customer.Options.TermsCCPaymentMethodId
    );
  }
  /* Async Functions */
  async onPaymentSelected(profile: PaymentProfile) {
    Confirm(
      async () => {
        try {
          SetSnackBar('Updating Autopay method...');
          await CustomerService.UpdateCustomerDefaultCCForTerms(profile.PaymentProfileId);
          this.$emit('refreshCustomer');
          this.closeDialog();
          SetSnackBar('Successfully updated your Autopay method');
        } catch (err) {
          SetSnackBar(
            'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
          );
        }
      },
      'Confirm Default Invoice Payment',
      'Linking this card will automatically charge your account on the day your Invoice is due. Are you sure you would like to continue?',
      'Never mind',
      'I am sure'
    );
  }
  /* Utility Functions */
  openDialog() {
    this.dialog = true;
  }
  closeDialog() {
    this.dialog = false;
  }
  /* Loaders */
  /* Mounted */
  /* Created */
  /* Emmited Functions */
}
