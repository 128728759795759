
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import DefaultLayout from '@/components/DefaultLayout.vue';
import AdminService from '@/services/admin-service';
import {
  AdminCustomer,
  FramePricingOptionTypes,
  SalesPerson
} from '@/store/models/CustomerModel';
import { BetaAccessModel, CustomerBetaAccessModel } from '@/store/models/RequestModels';
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import AuthService from '@/services/auth-service';
import { AdminUser } from '@/store/models/ProfileModel';
import { Confirm } from '@/helpers/AuthHelper';
import { VDataTableHeader } from '@/store/models/VDataTableModel';
import {
  AltPricingModel,
  ComboPricingModel,
  ComboPriceGenerationModel,
  ComboPricingGroup
} from '@/store/models/AdminModel';
import { ItemInfo } from '@/store/models/ItemModel';
import ItemService from '@/services/item-service';
import { downloadFile } from '@/helpers/ApiHelper';
import { CustomerService } from '@/services/customer-service';

const namespace: string = 'profile';

interface PriceQuote {
  Item1: number;
  Item2: number;
}

@Component({
  components: { DefaultLayout }
})
export default class EditCustomer extends Vue {
  /* Properties */
  @Prop({ default: () => [] }) comboPricingGroups!: ComboPricingGroup[];
  localComboPricingGroups: ComboPricingGroup[] = [];
  get computedComboPricingGroups() {
    return this.localComboPricingGroups;
  }

  set computedComboPricingGroups(newGroups: ComboPricingGroup[]) {
    this.localComboPricingGroups = newGroups;
    this.$emit('update:comboPricingGroups', newGroups);
  }
  /* Store Actions */
  @Action('login', { namespace }) login: any;
  @Action('getImpersonatedCustomer', { namespace })
  getImpersonatedCustomer: any;

  /* Watchers */
  @Watch('activityDate')
  async onActivityDateChange() {
    await this.updateActivityLog();
  }
  @Watch('comboPricingGroups', { immediate: true, deep: true })
  onComboPricesChanged(newVal) {
    this.localComboPricingGroups = [...newVal];
  }
  @Watch('customer.OD')
  OnODChange(val: any) {
    this.dataIsDirty = true;
  }
  @Watch('customer.Options', { deep: true })
  OnDefaultProofQuantityChange(val: any) {
    this.dataIsDirty = true;
  }
  @Watch('customer.Term.Id')
  OnTermsChange(val: any) {
    this.dataIsDirty = true;
  }
  @Watch('customer.SalesPerson.Id')
  OnSalesPersonChange(val: any) {
    this.dataIsDirty = true;
  }
  @Watch('defaultQuoteQuantity')
  async updateDataTable() {
    console.log('default quote value');
    await this.getAltPricingValues();
  }
  @Watch('panelOpenIndex')
  async OnPanelOpen() {
    await this.updateActivityLog();
  }
  @Watch('userActivityDate')
  async onUserActivityDateChange() {
    await this.updateUserActivityLog();
  }
  get isAnyGroupInEditState(): boolean {
    return this.computedComboPricingGroups.some(group => group.GroupState !== 0);
  }
  get isAnyGroupNewState(): boolean {
    return this.computedComboPricingGroups.some(group => group.GroupState === 1);
  }
  /* Data */
  $refs!: {
    defaultActions: HTMLFormElement;
    dateMenu: any;
  };
  activeUserId!: string;
  salesPeople: SalesPerson[] = [];
  groupToClone: ComboPricingGroup = new ComboPricingGroup();
  cloneDieId!: number;
  selectedDieText: '';
  activityDate: string = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
  activityHeaders: Array<{
    text: string;
    align: any;
    sortable: boolean;
    value: string;
  }> = [
    {
      text: 'Date Time',
      align: 'start',
      sortable: false,
      value: 'ActivityTimeStamp'
    },
    {
      text: 'Type',
      value: 'ActivityTypeValue',
      align: 'start',
      sortable: false
    },
    {
      text: 'Description',
      value: 'Description',
      align: 'start',
      sortable: false
    },
    { text: 'User', value: 'User', align: 'start', sortable: false }
  ];
  altPricingItems: AltPricingModel[] = [];
  altPricingTableHeaders: VDataTableHeader[] = [
    {
      text: 'Facestock',
      name: 'Facestock',
      value: 'Facestock',
      sortable: true,
      align: 'center'
    },
    {
      text: 'Lamination',
      name: 'Lamination',
      value: 'Lamination',
      align: 'center',
      sortable: true
    },
    { text: 'Die', name: 'Die', value: 'Die', align: 'center', sortable: true },
    {
      text: 'Colors',
      name: 'Colors',
      value: 'Colors',
      align: 'center',
      sortable: true
    },
    {
      text: 'Cost Each',
      name: 'CostEach',
      value: 'Cost',
      align: 'right',
      sortable: true
    },
    {
      text: 'Price Each',
      name: 'PriceEach',
      value: 'PriceEach',
      align: 'right',
      sortable: true
    },
    {
      text: 'Normal Price Each',
      name: 'NormalPriceEach',
      value: 'NormalPriceEach',
      align: 'right',
      sortable: true
    },
    {
      text: 'Difference',
      name: 'Difference',
      value: 'Difference',
      align: 'right',
      sortable: true
    },
    {
      text: 'Total Labels',
      name: 'TotalLabels',
      value: 'TotalItemCount',
      align: 'center',
      sortable: true
    },
    {
      text: 'Total Shipped',
      name: 'TotalItemsOrdered',
      value: 'TotalItemsOrdered',
      align: 'right',
      sortable: true
    },
    {
      text: 'Options',
      name: 'Options',
      value: 'Options',
      align: 'center',
      sortable: false
    }
  ];
  calculatedPrice: {
    [key: string]: { priceEach: number; markup: number };
  } = {};
  cloneCustomerId!: string;
  comboPriceHeaders: VDataTableHeader[] = [
    {
      text: 'Facestock',
      name: 'Material',
      value: 'Material',
      sortable: true,
      align: 'center'
    },
    {
      text: 'Lamination',
      name: 'Lamination',
      value: 'Finish',
      align: 'center',
      sortable: true
    },
    { text: 'Die', name: 'Die', value: 'Die', align: 'center', sortable: true },
    {
      text: 'Colors',
      name: 'Colors',
      value: 'Color',
      align: 'center',
      sortable: true
    },
    {
      text: 'Quantity',
      name: 'Quantity',
      value: 'QuantityThreshold',
      align: 'left',
      sortable: false
    },
    {
      text: 'Markup Value',
      name: 'Markup Value',
      value: 'PricePercentage',
      sortable: true
    },
    {
      text: 'Entry Type',
      name: 'EntryType',
      value: 'EntryType',
      sortable: false
    },
    {
      text: 'Options',
      name: 'Options',
      value: 'Options',
      align: 'center',
      sortable: false
    }
  ];
  currentAltPricingItem = new AltPricingModel();
  currentComboPriceGenerationModel: ComboPriceGenerationModel = new ComboPriceGenerationModel();
  currentTab: number = 0;
  customer: AdminCustomer | null = null;
  dataIsDirty: boolean = false;
  dateMenu: boolean = false;
  defaultQuoteQuantity = 1000;
  entryTypeOptions = [
    { value: 1, label: 'Markup Percent By Frame' },
    { value: 2, label: 'Markup Percent By Label Quantity' },
    { value: 3, label: 'Price Each By Frame' },
    { value: 4, label: 'Price Each By Label Quantity' }
  ];
  groupedComboPrices: any = {};
  isImpersonating: boolean = false;
  isLoading: boolean = false;
  isRefreshingCache: boolean = false;
  isSettingBetaAccess: boolean = false;
  itemCount: number = 0;
  labelQuantity: number = 0;
  optionGroups: Array<{
    key: number;
    groupName: string;
    model: boolean;
    settings: Array<{
      lesserkey: number;
      description: string;
      dataValue: boolean;
    }>;
  }> = [
    {
      key: 0,
      groupName: 'Payment Options',
      model: false,
      settings: [
        { lesserkey: 0, description: 'Is on credit hold?', dataValue: false },
        { lesserkey: 1, description: 'Are Proofs Free?', dataValue: false },
        {
          lesserkey: 2,
          description: 'Should charge a Core Fee?',
          dataValue: false
        },
        {
          lesserkey: 3,
          description: 'Should charge a rush Fee?',
          dataValue: false
        },
        {
          lesserkey: 4,
          description: 'Should use Itemized Pricing?',
          dataValue: false
        }
      ]
    },
    {
      key: 1,
      groupName: 'Printing Options',
      model: false,
      settings: [
        {
          lesserkey: 0,
          description: 'Should convert to outlines?',
          dataValue: false
        },
        {
          lesserkey: 1,
          description: 'Should add Pantones to item?',
          dataValue: false
        }
      ]
    },
    {
      key: 2,
      groupName: 'Transparency Options',
      model: false,
      settings: [
        {
          lesserkey: 0,
          description: 'Can use Amazon Auth Program?',
          dataValue: false
        },
        {
          lesserkey: 1,
          description: 'Can use regular var data?',
          dataValue: false
        },
        {
          lesserkey: 2,
          description: 'Can add Transparency Items?',
          dataValue: false
        }
      ]
    }
  ];
  panelOpenIndex: number = -1;
  pricingOptions: Array<{
    FrameOption: FramePricingOptionTypes;
    Description: string;
  }> = [
    {
      FrameOption: FramePricingOptionTypes.FramePricingOnly,
      Description: 'Frame Pricing Only'
    },
    {
      FrameOption: FramePricingOptionTypes.QuantityPricingOnly,
      Description: 'Quantity Pricing Only'
    },
    {
      FrameOption: FramePricingOptionTypes.BestOfBoth,
      Description: 'Best of Both World'
    }
  ];
  showAltModelDialog: boolean = false;
  showGenComboPriceModelDialog: boolean = false;
  showCloneComboPriceModelDialog: boolean = false;
  showRemoveCustomerDialog: boolean = false;
  tabItems: Array<{ key: number; textValue: string }> = [
    { key: 0, textValue: 'General Information' },
    { key: 1, textValue: 'User Management' },
    { key: 2, textValue: 'Advanced Options' },
    { key: 3, textValue: 'Alt Pricing' },
    { key: 4, textValue: 'Combined Pricing' }
  ];
  tableData: Array<{
    ActivityTimeStamp: Date;
    ActivityTypeValue: string;
    Description: string;
    Name: string;
    User: string;
  }> = [];
  terms: Array<{ Terms: string; Days: number; Id: number }> = [
    { Terms: 'Due Upon Receipt', Days: 0, Id: 5 },
    { Terms: 'Net 15', Days: 15, Id: 6 },
    { Terms: 'Net 30', Days: 30, Id: 7 },
    { Terms: 'Net 45', Days: 45, Id: 8 }
  ];
  userActivityData: Array<{
    ActivityTimeStamp: Date;
    ActivityTypeValue: string;
    Description: string;
    Name: string;
    User: string;
  }> = [];
  userActivityDate: string = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
  userDateMenu: boolean = false;
  userName: string = '';

  @State('itemInfo', { namespace: 'items' })
  itemInfo!: ItemInfo;
  @Getter('dieTemplates', { namespace: 'dies' })
  dieTemplates!: any;

  /* Methods */
  getFilteredComboPricingModels(group: ComboPricingGroup) {
    return group.ComboPricingModels.filter(model => model.EntryState !== 3);
  }
  addRow(group: ComboPricingGroup, item: ComboPricingModel) {
    const newItem: ComboPricingModel = {
      ...item,
      Id: item.Id * -1, // Use current timestamp as a temporary unique Id
      QuantityThreshold: item.QuantityThreshold + 1
    };

    this.$set(newItem, 'isClonedEntry', true);
    this.$set(newItem, 'EntryState', 1);
    if (group.GroupState !== 1) {
      this.$set(group, 'GroupState', 2);
    }
    const index = group.ComboPricingModels.indexOf(item);
    if (index !== -1) {
      group.ComboPricingModels.splice(index + 1, 0, newItem); // Insert the new item below the current item
      this.reindexItems();
      this.sortComboPricingModels(group); // Sort items in ascending order
    }
  }
  reindexItems() {
    console.log('reindexing');
    this.localComboPricingGroups.forEach(group => {
      let indexValue = 0;
      group.ComboPricingModels.forEach((item, index) => {
        this.$set(item, 'ItemOrder', indexValue);
        indexValue++;
      });
    });
  }

  async getComboPricingSheet() {
    const response = await AdminService.getComboCustomerPricingFile(this.customer.Id);
  }
  deleteRow(group: ComboPricingGroup, item: ComboPricingModel) {
    const index = group.ComboPricingModels.indexOf(item);
    if (index !== -1) {
      if (item.EntryState !== 1) {
        Confirm(
          () => {
            this.$set(item, 'EntryState', 3);
            this.$set(group, 'GroupState', 2); // Mark group as modified
          },
          'Delete Pricing Row',
          'Are you sure you want to delete this pricing row?'
        );
      } else {
        group.ComboPricingModels.splice(index, 1);
        if (group.ComboPricingModels.length === 0) {
          const groupIndex = this.comboPricingGroups.indexOf(group);
          this.comboPricingGroups.splice(groupIndex, 1);
        }
      }
      this.sortComboPricingModels(group); // Sort items in ascending order
    }
  }

  async generateNewComboBasedPricing() {
    try {
      const response = await AdminService.generateComboPricing(
        this.currentComboPriceGenerationModel
      );
      let newGroup = new ComboPricingGroup(response.data);
      this.addEditFlagsToItem(newGroup);
      this.$set(newGroup, 'GroupState', 1);
      newGroup.ComboPricingModels.forEach(item => {
        this.$set(item, 'EntryState', 1);
      });
      this.reindexItems();
      this.computedComboPricingGroups = [newGroup, ...this.localComboPricingGroups];
      this.$forceUpdate();
      console.log('forcing update.');
      this.showGenComboPriceModelDialog = false;
    } catch (error) {
      console.error('Error generating new combobased pricing.');
    }
  }
  async saveEditChangesCheck(group: ComboPricingGroup) {
    Confirm(
      () => {
        this.reindexItems();
        this.saveNewGroup(group.ComboPricingModels);
      },
      'Should Save changes?',
      'Are you sure you want to save your changes pricing for this group?'
    );
  }

  async saveNewGroup(comboValues: ComboPricingModel[]) {
    try {
      comboValues.forEach(item => {
        if (item.Id < 0) {
          item.Id = 0;
        }
      });
      console.log('saving...');
      console.log(comboValues);
      const response = await AdminService.saveComboPricing(comboValues);
      await this.LoadComboPriceValues(this.customer.Id);
      this.addEditFlagsToItems();
    } catch (error) {
      console.error('Failed to save group', error);
    }
  }
  setDate(value: any) {
    console.log(value);
  }
  async deleteComboBasedGroupCheck(group: ComboPricingGroup) {
    Confirm(
      () => {
        this.deleteComboBased(group);
      },
      'Delete all pricing for this group',
      'Are you sure you want to delete all pricing for this group?'
    );
  }
  async deleteComboBased(group: ComboPricingGroup) {
    try {
      const response = await AdminService.deleteComboPricing(group.ComboPricingModels);
      await this.LoadComboPriceValues(this.customer.Id);
      this.addEditFlagsToItems();
    } catch (error) {
      console.error('Failed to remove entire pricing group.');
    }
  }
  showClonePricingComboDialog(group: ComboPricingGroup) {
    this.groupToClone = group;
    this.showCloneComboPriceModelDialog = true;
  }
  updateSelectedDieText(value) {
    const selectedItem = this.dieTemplates.find(item => item.Id === value);
    console.log(selectedItem);
    this.selectedDieText = selectedItem ? selectedItem.Tag : '';
  }
  async clonePricingComboGroup() {
    try {
      this.showCloneComboPriceModelDialog = false;
      if (this.groupToClone.DieId == this.cloneDieId) {
        SetSnackBar('You cannot use the same die to make a clone entry (loser).');
      } else {
        // Clone the group
        const clonedGroup: ComboPricingGroup = {
          ...this.groupToClone,
          ComboPricingModels: this.groupToClone.ComboPricingModels.map(item => ({
            ...item,
            Id: 0, // Set each item's Id to 0`
            DieId: this.cloneDieId, // Set the new dieId
            Die: this.selectedDieText
          }))
        };
        // Save the cloned group (assuming you have a method to save the group)
        this.addEditFlagsToItem(clonedGroup);
        this.$set(clonedGroup, 'GroupState', 1);
        clonedGroup.ComboPricingModels.forEach(item => {
          this.$set(item, 'EntryState', 1);
        });
        this.reindexItems();
        this.computedComboPricingGroups = [clonedGroup, ...this.localComboPricingGroups];
        this.$forceUpdate();
        console.log('forcing update.');
        console.log('Successfully cloned pricing combo group.');
      }
    } catch (error) {
      console.error('Failed to clone pricing combo group.', error);
    }
  }
  checkToSeeIfLessThanHundredDollars(
    group: ComboPricingGroup,
    item: ComboPricingModel
  ): boolean {
    const checkAmount = 100.0;
    if (group && item) {
      if (item.EntryType <= 2) {
        // markup percentage
        return (
          item.QuantityThreshold * group.TrueCost * (item.PricePercentage + 1) <
          checkAmount
        );
      } else {
        // priceEach
        // quantityThreshold * Price < checkAmount
        return item.QuantityThreshold * item.PricePercentage < checkAmount;
      }
    }
    return false;
  }
  /*

  addRowAbove(item) {
    const index = this.comboPrices.indexOf(item);
    if (index !== -1) {
      const newItem = {
        ...item,
        Id: Date.now(),
        QuantityThreshold: item.QuantityThreshold + 1
      }; // Use current timestamp as a temporary unique Id and increment quantity
      this.comboPrices.splice(index, 0, newItem); // Insert the new item above the current item
      this.refreshGroups(); // Refresh the groups to reflect the new item
    }
  }

  addRowBelow(item) {
    const index = this.comboPrices.indexOf(item);
    if (index !== -1) {
      const newItem = {
        ...item,
        Id: Date.now(),
        QuantityThreshold: item.QuantityThreshold + 1
      }; // Use current timestamp as a temporary unique Id and increment quantity
      this.comboPrices.splice(index + 1, 0, newItem); // Insert the new item below the current item
      this.refreshGroups(); // Refresh the groups to reflect the new item
    }
  }

  duplicateRow(item) {
    const newItem = {
      ...item,
      Id: Date.now(),
      QuantityThreshold: item.QuantityThreshold + 1,
      isClonedEntry: true
    }; // Use current timestamp as a temporary unique Id and increment quantity
    const index = this.comboPrices.indexOf(item);
    if (index !== -1) {
      this.comboPrices.splice(index + 1, 0, newItem); // Insert the duplicated item below the current item
      this.refreshGroups(); // Refresh the groups to reflect the duplicated item
    }
  } */
  sortComboPricingModels(group: ComboPricingGroup) {
    group.ComboPricingModels.sort((a, b) => a.QuantityThreshold - b.QuantityThreshold);
  }
  async copyClonePricing() {
    try {
      this.isLoading = true;
      const response = await AdminService.copyCurrentCustomerPricingToCloneCustomer(
        this.customer.PublicId
      );
      await this.getAltPricingValues();
      SetSnackBar('The pricing has been copied.');
    } catch (error) {
      SetSnackBar('An Error has occured when trying to copy the pricing options.');
    }
  }
  async deleteAltPricingItem(item: AltPricingModel) {
    try {
      const response = await AdminService.deleteAltPricingItem(item);
      await this.getAltPricingValues();
      SetSnackBar('Your Item has been deleted.');
    } catch (error) {
      SetSnackBar('Error during deletion of Alt Pricing Item.');
    }
  }
  async fetchPriceQuote(group: ComboPricingGroup, quantity: number) {
    try {
      if (!group.ComboPricingModels || group.ComboPricingModels.length === 0) {
        console.error('ComboPricingModels array is empty or undefined');
        return;
      }

      const customerId = group.ComboPricingModels[0].CustomerId;
      const comboPricingValues = group.ComboPricingModels;

      const priceQuote = await this.generatePriceQuoteForComboPricing(
        group.TrueCost,
        comboPricingValues,
        customerId,
        quantity
      );

      if (priceQuote !== null) {
        const priceEach = priceQuote.Item1;
        const markup = priceQuote.Item2;
        this.$set(this.calculatedPrice, group.GroupKey, { priceEach, markup });
      } else {
        console.error('Failed to generate price quote');
      }
    } catch (error) {
      console.error('Error calculating price:', error);
    }
  }

  async generatePriceQuoteForComboPricing(
    groupTrueCost: number,
    comboPricingValues: ComboPricingModel[],
    customerId: number,
    quantity: number
  ): Promise<PriceQuote | null> {
    try {
      let trueCost = 0;
      if (comboPricingValues.length > 0) {
        trueCost = groupTrueCost;
      }
      const quoteModel = {
        PricingModels: comboPricingValues,
        CustomerId: customerId,
        Quantity: quantity,
        TrueCost: trueCost
      };
      const response = await AdminService.generatePriceQuoteForComboPricing(quoteModel);
      return response.data as PriceQuote;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
  disableEditing(item: ComboPricingModel, field: string) {
    console.log('disabling ' + field);
    this.$set(item, `isEditing${field}`, false);
    this.$forceUpdate();
  }
  async savePrice(group: ComboPricingGroup, subItem: ComboPricingModel) {
    this.disableEditing(subItem, 'Price');
    this.$set(subItem, 'isEditedPrice', true);
    this.$set(subItem, 'EntryState', 2);
    if (group.GroupState === 0) {
      this.$set(group, 'GroupState', 2);
    }
  }

  async saveQuantity(group: ComboPricingGroup, subItem: ComboPricingModel) {
    this.disableEditing(subItem, 'Quantity');
    this.$set(subItem, 'isEditedQuantity', true);
    this.$set(subItem, 'EntryState', 2);
    if (group.GroupState === 0) {
      this.$set(group, 'GroupState', 2);
    }
    this.sortComboPricingModels(group); // Call the sorting method after updating the quantity
  }
  addEditFlagsToItems() {
    this.localComboPricingGroups.forEach(item => {
      this.addEditFlagsToItem(item);
    });
  }

  addEditFlagsToItem(group: ComboPricingGroup) {
    this.$set(group, 'GroupState', 0);
    group.ComboPricingModels.forEach(comboItem => {
      this.$set(comboItem, 'isEditedQuantity', false);
      this.$set(comboItem, 'isEditedPrice', false);
      this.$set(comboItem, 'isClonedEntry', false);
      this.$set(comboItem, 'isEditingPrice', false);
      this.$set(comboItem, 'isEditingQuantity', false);
      this.$set(comboItem, 'EntryState', 0); // default entry state of 0 for a loaded row.
    });
  }

  async getAltPricingValues() {
    try {
      this.isLoading = true;
      const response = await AdminService.getAltPricing(
        this.customer.PublicId,
        this.defaultQuoteQuantity
      );
      this.altPricingItems = response.data.map((alt: any) => new AltPricingModel(alt));
      this.isLoading = false;
    } catch (error) {
      SetSnackBar('An Error has occurred getting the Alt Pricing Data');
    }
  }

  getEntryTypeLabel(entryType: number): string {
    switch (entryType) {
      case 1:
        return 'Markup Percent By Frame';
      case 2:
        return 'Markup Percent By Label Quantity';
      case 3:
        return 'Price Each By Frame';
      case 4:
        return 'Price Each By Label Quantity';
      default:
        return '';
    }
  }
  async getItemCount() {
    if (this.$route.params.CustomerId) {
      const response = await ItemService.GetItemCountForCustomer(
        this.$route.params.CustomerId
      );
      this.itemCount = response.data;
    }
  }

  async impersonate(customerId: string) {
    this.isImpersonating = true;
    const formData: any = new FormData();
    formData.set('userName', this.userName);
    formData.set('customerId', customerId);
    try {
      const response = await this.login(formData);
      const impersonatedCustomer = await this.getImpersonatedCustomer();
      this.isImpersonating = false;
      SetSnackBar('Impersonation successful');
      location.reload();
      return false;
    } catch (err) {
      SetSnackBar('Could not impersonate');
      this.isImpersonating = false;
    }
  }

  async LoadComboPriceValues(customerId: string) {
    try {
      const response = await AdminService.comboPricingGetAllByCustomer(customerId);
      this.localComboPricingGroups = response.data.map(
        (combo: any) => new ComboPricingGroup(combo)
      );
    } catch (error) {
      console.log(error);
    }
  }
  async ReloadComboPrices() {
    await this.LoadComboPriceValues(this.customer.Id);
  }
  async LoadCustomer(id: string) {
    const result = await AdminService.getCustomer(id);
    this.customer = result;
    this.setDataValues();
    await this.LoadComboPriceValues(this.customer.Id);
    this.customer.Users.forEach(async value => {
      try {
        const response = await AuthService.isUserLockedout(value.UserName);
        value.UserLockedOutStatus = response.data.isUserLockedOut ? 1 : 0;
      } catch (error) {
        value.UserLockedOutStatus = -1;
      }
    });
    this.$nextTick(() => {
      this.dataIsDirty = false;
    });
  }

  async refreshCache() {
    this.isRefreshingCache = true;
    try {
      await AdminService.refreshCustomer(this.$route.params.CustomerId);
      this.LoadCustomer(this.$route.params.CustomerId);
      SetSnackBar('Refreshed cache successfully');
    } catch (err) {
      SetSnackBar(
        'Error has occured, please contact techsupport@just1label.com if you are experiencing any issues'
      );
    }
    this.isRefreshingCache = false;
  }

  async refreshEstimateValues() {
    try {
      console.log('refresh');
      this.currentAltPricingItem.QuoteQuantity = this.defaultQuoteQuantity;
      let currentPrice = this.currentAltPricingItem.PriceEach;
      const response = await AdminService.updateAltPricingEstimateValues(
        this.currentAltPricingItem
      );
      this.currentAltPricingItem = new AltPricingModel(response.data);
      this.currentAltPricingItem.PriceEach = currentPrice;
    } catch (error) {
      console.log(error);
    }
  }
  async resendConfirmationEmail(userId: string) {
    try {
      await AuthService.ResendConfirmationEmail(userId);
      SetSnackBar('resent confirmation email');
    } catch (error) {
      SetSnackBar('error resending confirmation email');
    }
  }

  async saveAltPricingItem() {
    this.isLoading = true;
    if (this.currentAltPricingItem.Id) {
      this.saveUpdatedAltPricing();
    } else {
      try {
        const response = await AdminService.addAltPricingItem(this.currentAltPricingItem);
        await this.getAltPricingValues();
        SetSnackBar('The new Alt Pricing Item has been saved.');
        this.isLoading = false;
        this.closeNewAltPricingDialog();
      } catch (error) {
        console.log(error);
      }
    }
  }
  async saveUpdatedAltPricing() {
    try {
      const response = await AdminService.updateAltPricingItem(
        this.currentAltPricingItem
      );
      SetSnackBar('This alt item has been updated.');
      this.closeNewAltPricingDialog();
      await this.getAltPricingValues();
    } catch (error) {
      SetSnackBar('There was an error saving this value.');
      console.log(error);
    }
  }

  async SetBetaAccess(id: string, value: boolean, shouldDisplaySnackbar: boolean = true) {
    try {
      const model: BetaAccessModel = {
        PublicId: id,
        HasBetaAccess: value,
        DisplayName: '',
        UserName: ''
      };
      console.log('setting beta access.');
      const { data } = await AdminService.setBetaAccess(model);
      const index = this.customer!.Users.findIndex(
        (u: any) => u.PublicId == data.PublicId
      );
      if (index) this.customer!.Users[index] = data;
      if (shouldDisplaySnackbar) {
        SetSnackBar('Changed Beta Access Successfully');
      }
    } catch (err) {
      SetSnackBar('Could not set beta access for this user');
    }
  }
  async updateActivityLog() {
    if (this.customer && this.activityDate) {
      this.tableData = await AdminService.getActivityByCustomer(
        this.customer.PublicId,
        this.activityDate
      );
    }
  }

  async updateUserActivityLog() {
    if (this.activeUserId && this.activityDate) {
      this.userActivityData = await AdminService.getActivityByUser(
        this.activeUserId,
        this.userActivityDate
      );
    }
  }

  async setDataValues() {
    if (this.customer) {
      this.optionGroups[0].settings[0].dataValue = this.customer.Options.IsOnCreditHold;
      this.optionGroups[0].settings[1].dataValue = this.customer.Options.AreProofsFree;
      this.optionGroups[0].settings[2].dataValue = this.customer.Options.ShouldChargeCoreFee;
      this.optionGroups[0].settings[3].dataValue = this.customer.Options.ShouldChargeRushFee;
      this.optionGroups[0].settings[4].dataValue = this.customer.Options.ShouldUseItemizedPricing;
      this.optionGroups[1].settings[0].dataValue = this.customer.Options.CanConvertToOutline;
      this.optionGroups[1].settings[1].dataValue = this.customer.Options.ShouldAddPantonesToItem;
      this.optionGroups[2].settings[0].dataValue = this.customer.Options.CanUseAmazonVarData;
      this.optionGroups[2].settings[1].dataValue = this.customer.Options.CanUseVarData;
      this.optionGroups[2].settings[2].dataValue = this.customer.Options.CanAddTransparencyItems;

      const response = await AdminService.getclonePricingCustomerId();
      this.cloneCustomerId = response.data;
      await this.getAltPricingValues();
    }
  }
  closeNewAltPricingDialog() {
    this.currentAltPricingItem = new AltPricingModel();
    this.showAltModelDialog = false;
  }

  copyAltPricing(item: AltPricingModel) {
    console.log(item);
  }

  copyClonePricingClick() {
    Confirm(
      () => {
        this.copyClonePricing();
      },
      'Copy To Clone',
      'Are you sure you want to clone this customers values?'
    );
  }

  deleteAltPricingConfirmation(item: AltPricingModel) {
    Confirm(
      () => {
        this.deleteAltPricingItem(item);
      },
      'Delete Alt Pricing Model Item',
      'Are you sure you want to delete this alt pricing item?.'
    );
    console.log(item);
  }

  editAltPricing(item: AltPricingModel) {
    this.currentAltPricingItem = item;
    this.showAltModelDialog = true;
  }

  editCloneCustomerClick() {
    this.$router.push(`/editcustomer/${this.cloneCustomerId}`);
  }

  enableEditing(item: ComboPricingModel, field: string) {
    this.$set(item, `isEditing${field}`, true);
    this.$forceUpdate();
  }

  generateGroupKey(item: ComboPricingModel) {
    return `${item.DieId}-${item.FinishId}-${item.MaterialId}-${item.ColorId}-${item.EntryType}`;
  }

  openAddNewAltPricingItemDialog() {
    this.currentAltPricingItem = new AltPricingModel();
    this.currentAltPricingItem.CustomerId = this.customer.PublicId;
    this.showAltModelDialog = true;
  }

  openGenComboPricingItemDialog() {
    this.currentComboPriceGenerationModel = new ComboPriceGenerationModel();
    this.currentComboPriceGenerationModel.CustomerId = this.customer.Id;
    this.showGenComboPriceModelDialog = true;
  }

  panelHasChanged(user: AdminUser) {
    if (user && this.userActivityDate) {
      this.activeUserId = user.PublicId;
      this.updateUserActivityLog();
    }
  }

  RemoveCustomer() {
    Confirm(
      async () => {
        if (this.customer) {
          try {
            await AdminService.RemoveCustomer(this.customer.PublicId);
            SetSnackBar(
              'The customer has been removed, you might need to refresh the customer catch.'
            );
          } catch (error) {
            SetSnackBar('You couldnt remove this customer because you lack the power.');
          }
        }
      },
      'Remove Customer',
      'Are you sure you want to remove this customer (FOREVER).'
    );
  }

  saveButtonClick() {
    Confirm(
      () => {
        this.SaveCustomerValues();
      },
      'Save Changes',
      'Are you sure you want to save changes to this customer? (NOTE, you can only remove customers that have no items or orders associated with their account.'
    );
  }

  async SaveCustomerValues() {
    if (this.customer) {
      try {
        await AdminService.saveCustomerData(this.customer);
        SetSnackBar('Customer Settings have been saved.');
        this.dataIsDirty = false;
      } catch (error) {
        SetSnackBar(
          'There was an error saving your changes.  It was probably your fault.'
        );
      }
    }
  }

  async SetCustomerBetaAccess() {
    this.isSettingBetaAccess = true;
    const model: CustomerBetaAccessModel = {
      CustomerId: this.customer!.PublicId,
      HasBetaAccess: this.customer!.Options.HasBetaAccess
    };
    await AdminService.setCustomerBetaAccess(model);
    this.LoadCustomer(this.$route.params.CustomerId);
    SetSnackBar('Changed Beta Access For Customer Successfully');
    this.isSettingBetaAccess = false;
  }
  updateDataValues(groupKey: number, data: any) {
    if (this.customer) {
      switch (groupKey) {
        case 0: {
          switch (data.lesserkey) {
            case 0: {
              this.customer.Options.IsOnCreditHold = data.dataValue;
              break;
            }
            case 1: {
              this.customer.Options.AreProofsFree = data.dataValue;
              break;
            }
            case 2: {
              this.customer.Options.ShouldChargeCoreFee = data.dataValue;
              break;
            }
            case 3: {
              this.customer.Options.ShouldChargeRushFee = data.dataValue;
              break;
            }
            case 4: {
              this.customer.Options.ShouldUseItemizedPricing = data.dataValue;
            }
          }
          break;
        }
        case 1: {
          switch (data.lesserkey) {
            case 0: {
              this.customer.Options.CanConvertToOutline = data.dataValue;
              break;
            }
            case 1: {
              this.customer.Options.ShouldAddPantonesToItem = data.dataValue;
              break;
            }
          }
          break;
        }
        case 2: {
          switch (data.lesserkey) {
            case 0: {
              this.customer.Options.CanUseAmazonVarData = data.dataValue;
              break;
            }
            case 1: {
              this.customer.Options.CanUseVarData = data.dataValue;
              break;
            }
            case 2: {
              this.customer.Options.CanAddTransparencyItems = data.dataValue;
              break;
            }
          }
        }
      }
      this.dataIsDirty = true;
    }
  }

  async loadSalesPeople() {
    try {
      const response = await CustomerService.GetAllSalesPeople();
      this.salesPeople = response.data.map((item: any) => new SalesPerson(item));
    } catch (err) {
      console.error('Error loading sales people:', err);
    }
  }

  /* Mounted */
  mounted() {
    this.LoadCustomer(this.$route.params.CustomerId);
    this.getItemCount();
    this.loadSalesPeople();
    const user = localStorage.getItem('user');
    if (user) {
      this.userName = JSON.parse(user).userName;
    }
  }
  created() {
    this.localComboPricingGroups = [...this.comboPricingGroups];
    this.addEditFlagsToItems();
  }
}
